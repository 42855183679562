import _ from 'lodash';
import React from 'react';
import { WppCard, WppTypography, WppTooltip, WppIconInfo } from 'buildingBlocks';
import { StrategyType } from 'utils/types';
import strategyWizard from 'containers/StrategyWizard/styles';
import { strategyTypeHelpDescription, startegyTypeCardStyle, cardTextStyle, strategyDisplayTooltipContainer } from 'containers/StrategyWizard/steps/StrategyTypeSelection/styles';
import { LegendDspIcons, LegendText } from './Legend';

type NewOrBetaTagProps = {
  isItemSelected: boolean
};

const BetaTag = ({ isItemSelected }: NewOrBetaTagProps) => (
  <div
    className={(isItemSelected ? 'right floated blue' : 'right floated inverted')}
    style={strategyWizard.cardImgWrapper}
  >
    <img
      src="/img/components/StrategyWizard/beta_ribbon.svg"
      style={{ position: 'relative', right: '-9px' }}
      alt="Beta"
    />
  </div>
);

const NewTag = ({ isItemSelected }: NewOrBetaTagProps) => (
  <div
    className={(isItemSelected ? 'right floated blue' : 'right floated inverted')}
    style={strategyWizard.cardImgWrapper}
  >
    <img
      src="/img/components/StrategyWizard/new_badge.svg"
      width="50px"
      alt="New Strategy Type!"
    />
  </div>
);

type StratTypeCardContentProps = {
  item: StrategyType
  isItemSelected?: boolean
  cardContentStyling?: {}
};

const StrategyTypeCardContent = ({ item, isItemSelected, cardContentStyling }: StratTypeCardContentProps) => (
  <div style={cardContentStyling}>
    {item.beta && <BetaTag isItemSelected={isItemSelected} />}
    {item.new && <NewTag isItemSelected={isItemSelected} />}
    <div style={strategyDisplayTooltipContainer}>
      <WppTypography type="m-strong">{item.displayName}</WppTypography>
      <WppTooltip
        config={{ placement: 'top', allowHTML: true }}
        theme="light"
      >
        <WppIconInfo />
        <div slot="tooltip-content" data-testid="tooltip-text">
          <WppTypography tag="span"><a href={_.get(item, 'helpCenterLink')} target="_blank" rel="noopener noreferrer">Learn about</a> this strategy type and how to configure it.</WppTypography>
        </div>
      </WppTooltip>
    </div>
    <LegendText style={cardTextStyle}>
      {item.description}
    </LegendText>
    <LegendDspIcons dspId={item.dsp} />
    <WppTypography style={strategyTypeHelpDescription} type="s-body">{item.help}</WppTypography>
  </div>
);

type EnabledStrategyProps = {
  item: StrategyType,
  itemSelected: boolean,
  onClick: () => void,
};

export const EnabledStrategyTypeCard = ({ item, itemSelected, onClick }: EnabledStrategyProps) => (
  <WppCard
    onClick={onClick}
    value={item.id}
    className={itemSelected ? 'strategy-type-card-selected' : ''}
    style={startegyTypeCardStyle}
  >
    <StrategyTypeCardContent
      item={item}
      isItemSelected={itemSelected}
    />
  </WppCard>
);

type DisabledStrategyProps = {
  item: StrategyType,
};

export const DisabledStrategyTypeCard = ({ item }: DisabledStrategyProps) => (
  <WppCard
    className="disabled"
  >
    <StrategyTypeCardContent
      item={item}
      cardContentStyling={strategyWizard.disabledCardStyle}
    />
  </WppCard>
);
