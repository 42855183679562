import _ from 'lodash';
import React from 'react';
import { Map as ImmutableMap } from 'immutable';
import { WppGrid, WppListItem, WppSelect, WppTypography } from 'buildingBlocks';
import { AWGDimensions } from 'constantsBase';
import { AWG_WEIGHTS_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { getDimensionText } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { MetricConfigObj } from 'containers/StrategyWizard/types';
import { CONVERSION_METRICS } from 'containers/StrategyWizard/constants';
import { SelectChangeEventDetail, WppSelectCustomEvent } from 'utils/types';
import { PIXEL_PICKER_STYLES } from 'containers/StrategyWizard/components/PixelPicker/styles';
import { useWeightsSectionContext } from '../contexts/WeightsSectionProvider';
import { useAWGContext } from '../contexts/AWGProvider';

const { dimensionDropdownStyle } = PIXEL_PICKER_STYLES;

const DimensionsDropdown = () => {
  const { isCampaignOptType } = useAWGContext();
  const dropdownOptions = isCampaignOptType ? [AWGDimensions.lineItem, AWGDimensions.pixel] : AWGDimensions;
  const placeholderDimension = isCampaignOptType ? AWGDimensions.lineItem : AWGDimensions.insertionOrder;
  const {
    sessionMetrics, selectedMetric, selectedDimension,
    setSelectedDimension, setSessionMetrics,
  } = useWeightsSectionContext();

  const handleOnChange = (_e, data) => {
    setSelectedDimension(data.value);
    // reset weighting and isWeighted when changing dimensions
    const updated = sessionMetrics.updateIn([selectedMetric], (metric: ImmutableMap<string, MetricConfigObj>) => (
      metric.merge({
        dimension: data.value,
        isWeighted: false,
        weighting: ImmutableMap(),
      })
    ));
    setSessionMetrics(updated);
  };

  const allDropdownOptions = _.map(dropdownOptions, (ds) => ({ text: getDimensionText(ds), value: ds }));
  const filteredOptions = _.includes(CONVERSION_METRICS, selectedMetric) ? allDropdownOptions : _.reject(allDropdownOptions, ['value', AWGDimensions.pixel]);

  return (
    <WppGrid style={AWG_WEIGHTS_MODAL_STYLES.dimensionWeightGrid}>
      <WppGrid>
        <WppTypography type="s-strong" tag="p">Assign weights to {selectedDimension === AWGDimensions.dsp ? 'objects in the buying platform' : `${getDimensionText(selectedDimension)}s`}.</WppTypography>
      </WppGrid>
      <WppGrid>
        <WppSelect
          name="dimension"
          onWppChange={(event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
            handleOnChange(event, event.detail);
          }}
          placeholder={getDimensionText(placeholderDimension)}
          disabled={_.size(filteredOptions) <= 1}
          value={selectedDimension}
          size="m"
          style={dimensionDropdownStyle}
        >
          {filteredOptions.map((option) => (
            <WppListItem
              value={option.value}
              key={option.value}
            >
              <p slot="label">{option.text}</p>
            </WppListItem>
          ))}
        </WppSelect>
      </WppGrid>
    </WppGrid>
  );
};

export default DimensionsDropdown;
