import React from 'react';
import { Label } from 'buildingBlocks';

type Props = {
  jsonParseError: string,
};

const AdminConfigErrors = ({ jsonParseError }: Props) => (
  <div>
    <Label basic style={{ margin: 'initial', border: 'initial', color: '#9f3a38' }}>
      {jsonParseError}
    </Label>
  </div>
);

export default AdminConfigErrors;
