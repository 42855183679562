import { DSP, STRATEGY_TYPE } from 'constantsBase';
import { ExternalTypeOptimizationLevel, OPTIMIZATION_LEVELS } from '../AttachFlights/constants';

export const STRATEGY_TYPES_PER_ROW = 3;

export const STRATEGY_TYPE_TO_OPTIMIZATION_LEVEL = {
  [STRATEGY_TYPE.ttdBudgetOptimization.id]: OPTIMIZATION_LEVELS[DSP.TTD.id][ExternalTypeOptimizationLevel.HIGHER_ORDER],
  [STRATEGY_TYPE.customBidList.id]: OPTIMIZATION_LEVELS[DSP.TTD.id][ExternalTypeOptimizationLevel.LOWER_ORDER],
  [STRATEGY_TYPE.heliosBidList.id]: OPTIMIZATION_LEVELS[DSP.TTD.id][ExternalTypeOptimizationLevel.LOWER_ORDER],
  [STRATEGY_TYPE.dbmBudgetOptimization.id]: OPTIMIZATION_LEVELS[DSP.DBM.id][ExternalTypeOptimizationLevel.HIGHER_ORDER],
  [STRATEGY_TYPE.dbmCustomSDF.id]: OPTIMIZATION_LEVELS[DSP.DBM.id][ExternalTypeOptimizationLevel.HIGHER_ORDER],
  [STRATEGY_TYPE.heliosCustomScript.id]: OPTIMIZATION_LEVELS[DSP.DBM.id][ExternalTypeOptimizationLevel.LOWER_ORDER],
  [STRATEGY_TYPE.apnBudgetOptimization.id]: OPTIMIZATION_LEVELS[DSP.APN.id][ExternalTypeOptimizationLevel.HIGHER_ORDER],
  [STRATEGY_TYPE.viewCalc.id]: OPTIMIZATION_LEVELS[DSP.APN.id][ExternalTypeOptimizationLevel.LOWER_ORDER],
  [STRATEGY_TYPE.viewCalcVideo.id]: OPTIMIZATION_LEVELS[DSP.APN.id][ExternalTypeOptimizationLevel.LOWER_ORDER],
  [STRATEGY_TYPE.heliosSegmentRecency.id]: OPTIMIZATION_LEVELS[DSP.APN.id][ExternalTypeOptimizationLevel.LOWER_ORDER],
  [STRATEGY_TYPE.predictorALI.id]: OPTIMIZATION_LEVELS[DSP.APN.id][ExternalTypeOptimizationLevel.LOWER_ORDER],
  [STRATEGY_TYPE.helios.id]: OPTIMIZATION_LEVELS[DSP.APN.id][ExternalTypeOptimizationLevel.LOWER_ORDER],
  [STRATEGY_TYPE.customTree.id]: OPTIMIZATION_LEVELS[DSP.APN.id][ExternalTypeOptimizationLevel.LOWER_ORDER],
  [STRATEGY_TYPE.amznBudgetOptimization.id]: OPTIMIZATION_LEVELS[DSP.AMZN.id][ExternalTypeOptimizationLevel.HIGHER_ORDER],
  [STRATEGY_TYPE.wmtBudgetOptimization.id]: OPTIMIZATION_LEVELS[DSP.WALMART.id][ExternalTypeOptimizationLevel.HIGHER_ORDER],
};
