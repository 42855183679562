/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Map as ImmutableMap } from 'immutable';
import { WppGrid, WppInlineMessage, WppInput, WppTable, WppTableBody, WppTableBodyCell, WppTableBodyRow, WppTableHeader, WppTableHeaderCell, WppTableHeaderRow, WppTypography } from 'buildingBlocks';
import { MODAL_ERROR_MSG } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { AWG_WEIGHTS_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { WizardFormValues, WeightObj, WeightRowObj, MetricConfig, MetricConfigObj } from 'containers/StrategyWizard/types';
import { getPlatformOptions, isWeighted } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { GlobalState } from 'reducers';
import { InputChangeEventDetail, WppInputCustomEvent } from 'utils/types';
import { useWeightsModalContentContext } from '../contexts/WeightsModalContentProvider';
import { useWeightsSectionContext } from '../contexts/WeightsSectionProvider';

const { weightsList, inputFieldError, platformWeightGridCont, platformWeightGrid, platformWeightText, platWeightsText } = AWG_WEIGHTS_MODAL_STYLES;

type PlatformWeightRowProps = {
  platform: WeightRowObj
  platformWeightsList: WeightObj
  setPlatformWeightsList: Dispatch<SetStateAction<WeightObj>>
};

const PlatformWeightRow = ({
  platform, platformWeightsList, setPlatformWeightsList,
}: PlatformWeightRowProps) => {
  const { sessionMetrics, selectedMetric, setSessionMetrics } = useWeightsSectionContext();
  const { attemptSave, formErrors, setFormErrors } = useWeightsModalContentContext();
  const showError = attemptSave && formErrors[platform.id];

  const handleOnChange = (event: WppInputCustomEvent<InputChangeEventDetail>) => {
    let value = event.detail.value;
    if (!value || Symbol(value).toString() === 'Symbol(−)') {
      setFormErrors({
        ...formErrors,
        [platform.id]: MODAL_ERROR_MSG,
      });
    } else {
      // eslint-disable-next-line no-param-reassign
      delete formErrors[platform.id];
      const sessionMetricsWeighting = sessionMetrics.getIn([selectedMetric, 'weighting'], {}) as ImmutableMap<string, WeightObj>;
      const weightingObj = sessionMetricsWeighting.toJS();
      if (value === '1' && _.size(weightingObj) && _.has(weightingObj, platform.id)) {
        setSessionMetrics(sessionMetrics.deleteIn([selectedMetric, 'weighting', _.toString(platform.id)]));
      } if (value !== '1') {
        // Allow for only 4 decimal places
        value = value.split('.').map((el, i) => (i ? el.split('').slice(0, 4).join('') : el)).join('.');
        const weight = !_.isNaN(_.toNumber(value)) ? _.toNumber(value) : value;
        setSessionMetrics(sessionMetrics.mergeDeep({
          [selectedMetric]: {
            weighting: { [platform.id]: weight },
          },
        } as unknown as MetricConfig));
      }
    }
    setPlatformWeightsList({
      ...platformWeightsList,
      [platform.id]: {
        ...platformWeightsList[platform.id],
        weight: value,
      },
    });
  };

  return (
    <WppGrid container fullWidth style={platformWeightGridCont}>
      <WppGrid direction="row" style={platformWeightGrid}>
        <WppTable>
          <WppTableHeader>
            <WppTableHeaderRow className="wpp-table-header-row">
              <WppTableHeaderCell style={platformWeightText}>
                <WppTypography type="s-strong" tag="p">Platform</WppTypography>
              </WppTableHeaderCell>
              <WppTableHeaderCell style={platWeightsText}>
                <WppTypography type="s-strong" tag="p">Weights</WppTypography>
              </WppTableHeaderCell>
            </WppTableHeaderRow>
          </WppTableHeader>
          <WppTableBody className="wpp-tbody">
            <WppTableBodyRow className="wpp-table-body-row">
              <WppTableBodyCell className="wpp-table-body-cell" style={platformWeightText}>
                <WppTypography type="s-body" tag="p">{platform.displayName}</WppTypography>
              </WppTableBodyCell>
              <WppTableBodyCell className="wpp-table-body-cell">
                <WppInput
                  id={showError ? 'metricInputError' : ''}
                  type="decimal"
                  size="s"
                  style={{ ...(showError && inputFieldError) }}
                  value={_.toString(platform?.weight)}
                  onWppChange={handleOnChange}
                  messageType={showError ? 'error' : undefined}
                  maskOptions={{
                    decimalPatternOptions: {
                      precision: 4,
                      thousandSeparator: '',
                      decimalSeparator: '.',
                    },
                  }}
                />
                {showError && (
                  <WppInlineMessage
                    size="s"
                    message={showError && (formErrors[platform.id] || '')}
                    type="error"
                    showTooltipFrom={7}
                  />
                )}
              </WppTableBodyCell>
            </WppTableBodyRow>
          </WppTableBody>
        </WppTable>
      </WppGrid>
    </WppGrid>
  );
};

const PlatformWeightsList = () => {
  const { attachFlightsStep: { attachedFlights } } = useSelector<GlobalState>((state) => state.strategyWizard) as WizardFormValues;
  const { sessionMetrics, selectedMetric, setSessionMetrics } = useWeightsSectionContext();
  const [platformWeightsList, setPlatformWeightsList] = useState<WeightObj>({});

  useEffect(() => {
    if (_.size(attachedFlights)) {
      // pass sessionMetrics[selectedMetric].weighting as a JS Object to getPlatformOptions
      const sessionMetricsWeighting = sessionMetrics.getIn([selectedMetric, 'weighting'], {}) as ImmutableMap<string, WeightObj>;
      const flightsToDisplay = getPlatformOptions(attachedFlights, sessionMetricsWeighting.toJS() as Pick<MetricConfigObj, 'weighting'>);
      setPlatformWeightsList(flightsToDisplay);
    }
  }, [sessionMetrics.getIn([selectedMetric, 'weighting'], {})]);

  useEffect(() => {
    if (_.size(platformWeightsList)) {
      setSessionMetrics(sessionMetrics.mergeDeep({
        [selectedMetric]: {
          isWeighted: isWeighted(platformWeightsList),
        },
      } as MetricConfig));
    }
  }, [platformWeightsList]);

  return (
    <WppGrid className="weightsList" style={weightsList}>
      {_.map(platformWeightsList, (platform: WeightRowObj, dspId: string) => (
        <PlatformWeightRow
          key={dspId}
          platform={platform}
          platformWeightsList={platformWeightsList}
          setPlatformWeightsList={setPlatformWeightsList}
        />
      ))}
    </WppGrid>
  );
};

export default PlatformWeightsList;
