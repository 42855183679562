import _ from 'lodash';
import React, { Dispatch, SetStateAction, useState, MutableRefObject } from 'react';
import { useWatch } from 'react-hook-form';
import { WppGrid } from 'buildingBlocks';
import { CYOD_GOAL_TYPES, GOAL_TYPES } from 'constantsBase';
import { DspToPixel } from 'containers/StrategyWizard/components/PixelPicker/types';
import useSideModalHideFreshdesk from 'utils/hooks/useSideModalHideFreshdesk';
import { GoalType } from 'utils/types';
import CYODModal from './CYOD/CYODModal';
import GoalCard from './GoalCard';
import { isCYODGoalTypeValue } from '../utils';
import AWGPage from './AWG/AWGPage';
import AWGProvider from './AWG/contexts/AWGProvider';

type CyodGoalProps = {
  setConfirmedGoal: Dispatch<SetStateAction<string>>
  confirmedGoal: string
  impValueFilters: DspToPixel
  advancedGoals: Array<GoalType>
  cyodRef: any
  customGoals: Array<GoalType>
  awgRef: MutableRefObject<boolean>
};

const NewAdvancedGoalSection = ({
  setConfirmedGoal,
  confirmedGoal,
  impValueFilters,
  advancedGoals,
  cyodRef,
  awgRef,
}: CyodGoalProps) => {
  const [CYODModalOpen, setCYODModalOpen] = useState<boolean>(false);
  const [AWGModalOpen, setAWGModalOpen] = useState<boolean>(false);
  const goalType = useWatch({ name: 'goal.type' });
  const cyodGoalSelected = isCYODGoalTypeValue(goalType);
  const cyodGoalTypeToOmit = cyodGoalSelected ? _.find(CYOD_GOAL_TYPES, (g) => g !== goalType) : GOAL_TYPES.cyodRateOfSuccessEvent.value;
  const goalsToDisplay = _.filter(advancedGoals, (gT) => gT.value !== cyodGoalTypeToOmit);
  // hide freshdesk button when side modal is open
  useSideModalHideFreshdesk(CYODModalOpen || AWGModalOpen);

  return (
    <>
      {_.map(_.sortBy(goalsToDisplay, 'strategyWizardAbbreviation'), (gT) => (
        <WppGrid
          item
          all={6}
          key={gT.value}
        >
          <div style={{ height: '100%' }} className={confirmedGoal && 'cardgoalheight'}>
            <GoalCard
              key={gT.value}
              {...gT}
              selected={(cyodGoalSelected && isCYODGoalTypeValue(gT.value)) || goalType === gT.value}
              confirmed={(cyodGoalSelected && isCYODGoalTypeValue(confirmedGoal)) || confirmedGoal === gT.value}
              setConfirmedGoal={setConfirmedGoal}
              impValueFilters={impValueFilters}
              cyodRef={cyodRef}
              setCYODModalOpen={setCYODModalOpen}
              setAWGModalOpen={setAWGModalOpen}
              awgRef={awgRef}
            />
          </div>
        </WppGrid>
      ))}
      {AWGModalOpen && (
        <AWGProvider>
          <AWGPage awgRef={awgRef} AWGModalOpen={AWGModalOpen} setAWGModalOpen={setAWGModalOpen} />
        </AWGProvider>
      )}
      {CYODModalOpen && (
        <CYODModal
          CYODModalOpen={CYODModalOpen}
          setCYODModalOpen={setCYODModalOpen}
          setConfirmedGoal={setConfirmedGoal}
          cyodRef={cyodRef}
        />
      )}
    </>
  );
};

export default NewAdvancedGoalSection;
