/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { createContext, useContext, useRef, useReducer, useEffect, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { METRIC_SEARCH_OBJS } from 'containers/StrategyWizard/constants';
import { checkIsMetric, getDropzoneContent } from 'containers/StrategyWizard/utils';
import { CHARACTER_LIMIT, FORMULA_BUILDER_ERROR_MESSAGES } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { generateRandomUUID } from 'utils/formattingUtils';
import { usePrevious } from 'utils/hooks/usePrevious';
import {
  addNewItem, addRedoAction, addUndoAction, updateCursorIdx, updateDropItems,
  updateDropzoneActive, updateRedoActions, updateUndoActions, updateCharacterCount,
} from './actions';
import { formulaReducer } from './reducers';
import { DraggableItem, FormulaBuilderAction } from './types';
import { DEFAULT_ERROR_OBJ, validateFormula } from './utils';
import { useAWGContext } from './AWGProvider';

const FormulaContext = createContext(null);

const INITIAL_STATE = {
  dropItems: [],
  dropzoneActive: false,
  cursorIdx: null,
  undoActions: [],
  redoActions: [],
  characterCount: 0,
};

type FormulaProviderProps = {
  children: ReactNode
};

const FormulaProvider = ({ children }: FormulaProviderProps) => {
  const [state, dispatch] = useReducer(formulaReducer, INITIAL_STATE);
  const { setValue, setError, resetField } = useFormContext();
  const { selectedFormula, setSelectedFormula } = useAWGContext();
  const dropItemIds = useRef<Set<string>>(new Set());
  const prevDropzoneActive = usePrevious(state.dropzoneActive);
  const dropzoneRef = useRef<HTMLElement>(null);
  const searchInputRef = useRef<HTMLElement>(null);

  const actions = {
    addDropItem: (newDropItem: DraggableItem) => {
      dropItemIds.current.add(newDropItem.id);
      dispatch(addNewItem(newDropItem));
    },
    handleRemoveItem: (itemId: string) => {
      dropItemIds.current.delete(itemId);
      dispatch(updateDropItems(_.reject(state.dropItems, ['id', itemId])));
    },
    handleReorder: (dragIdx: number, targetIdx: number) => {
      const newCards = _.cloneDeep(state.dropItems);
      const card = newCards[dragIdx];
      newCards.splice(dragIdx, 1);
      newCards.splice(targetIdx, 0, card);
      dispatch(updateDropItems(newCards));
    },
    setDropzoneActive: (dropzoneActive: boolean) => dispatch(updateDropzoneActive(dropzoneActive)),
    insertItemAt: (item: DraggableItem, index: number) => {
      const newCards = _.cloneDeep(state.dropItems);
      newCards.splice(index, 0, item);
      dropItemIds.current.add(item.id);
      dispatch(updateDropItems(newCards));
    },
    setCursorIdx: (cursorIdx: number) => dispatch(updateCursorIdx(cursorIdx)),
    addUndoAction: (formulaAction: FormulaBuilderAction) => dispatch(addUndoAction(formulaAction)),
    addRedoAction: (formulaAction: FormulaBuilderAction) => dispatch(addRedoAction(formulaAction)),
    setUndoActions: (formulaActions: Array<FormulaBuilderAction>) => dispatch(updateUndoActions(formulaActions)),
    setRedoActions: (formulaActions: Array<FormulaBuilderAction>) => dispatch(updateRedoActions(formulaActions)),
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const values = {
    ...state,
    ...actions,
    dropItemIds: dropItemIds.current,
    dropzoneRef,
    searchInputRef,
  };

  useEffect(() => {
    if (_.size(selectedFormula?.formula)) {
      const formattedFormula = _.map(selectedFormula.formula, (elem: string) => ({
        id: generateRandomUUID(),
        content: checkIsMetric(elem) ? getDropzoneContent(elem) : elem,
      }));
      dropItemIds.current = new Set(_.map(formattedFormula, 'id'));
      actions.setCursorIdx(_.size(dropItemIds.current));
      dispatch(updateDropItems(formattedFormula));
    }
  }, [selectedFormula?.id]);

  useEffect(() => {
    const formula = _.map(state.dropItems, ({ content }) => {
      if (_.includes(_.map(METRIC_SEARCH_OBJS, 'text'), content)) {
        return _.get(_.find(METRIC_SEARCH_OBJS, ['text', content]), 'value');
      }
      return content;
    });
    const id = selectedFormula?.id && _.isEqual(selectedFormula?.formula, formula) ? selectedFormula.id : null;
    const metrics = _.uniq(_.filter(formula, checkIsMetric));
    dispatch(updateCharacterCount(_.size(_.join(formula, ''))));
    setValue('customGoal.formula', formula);
    setValue('customGoal.metrics', metrics);
    setSelectedFormula(({ ...selectedFormula, id, formula, metrics }));
  }, [state.dropItems]);

  useEffect(() => {
    let timer;
    if (state.dropzoneActive) {
      resetField('customGoal.formula');
    }
    if (!state.dropzoneActive && !_.isNil(prevDropzoneActive)) {
      timer = setTimeout(() => validateFormula(selectedFormula?.formula, setError), 3000);
    }
    return () => clearTimeout(timer);
  }, [state.dropzoneActive]);

  useEffect(() => {
    if (state.characterCount > CHARACTER_LIMIT) {
      setError('customGoal.formula', { ...DEFAULT_ERROR_OBJ, message: FORMULA_BUILDER_ERROR_MESSAGES.EXCEED_CHARACTER_LIMIT });
    }
  }, [state.characterCount]);

  return (
    <FormulaContext.Provider value={values}>
      {children}
    </FormulaContext.Provider>
  );
};

export const useFormulaContext = () => {
  const context = useContext(FormulaContext);
  if (!context) {
    throw new Error('useFormulaContext must be used within a FormulaProvider');
  }
  return context;
};

export default FormulaProvider;
