import { CSSProperties } from 'react';
import { COPILOT_TYPOGRAPHY, COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';
import strategyWizardStyles from 'containers/StrategyWizard/styles';

const { column } = strategyWizardStyles;
const {
  NEW_DESIGN_SYSTEM: { NEUTRALS, TEALS, REDS },
  WPP,
} = COPILOT_COLORS;
const { SPACING } = COPILOT_LAYOUT;

export const STRATEGY_CONFIRMATION_STYLES: { [key: string]: CSSProperties } = {
  headerSection: {
    ...column,
    boxShadow: 'none',
  },
  header: {
    margin: '0px 0px 16px',
  },
  icon: {
    marginLeft: SPACING[4],
  },
  nameLabel: {
    ...COPILOT_TYPOGRAPHY.HEADING.H5,
    color: NEUTRALS.N600_ABBEY,
  },
  strategyConfirmInfoName: {
    display: 'flex',
    flexDirection: 'column',
  },
  basicStratInfoListStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[4],
  },
  listStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  subHeader: {
    ...COPILOT_TYPOGRAPHY.HEADING.H5,
    color: NEUTRALS.N600_ABBEY,
    padding: 0,
  },
  mainText: {
    ...COPILOT_TYPOGRAPHY.BODY.SMALL,
    color: TEALS.T500_KEPPEL,
  },
  flightInfo: {
    ...COPILOT_TYPOGRAPHY.BODY.SMALL,
    color: NEUTRALS.N600_ABBEY,
  },
  individualTextLine: {
    marginTop: SPACING[16],
    padding: 0,
  },
  editIcon: {
    float: 'right',
    color: NEUTRALS.N600_ABBEY,
    fontSize: 16,
  },
  goalListColumn: {
    padding: 0,
    paddingRight: SPACING[12],
  },
  goalListElement: {
    padding: 0,
  },
  step5Table: {
    ...COPILOT_TYPOGRAPHY.BODY.MEDIUM,
    fontSize: '14px',
    fontWeight: 300,
    color: NEUTRALS.N400_GRANITE,
    lineHeight: '20px',
    margin: '8px 0px 3px 0px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: SPACING[4],
    borderWidth: '1px',
    borderColor: NEUTRALS.N200_LINEN,
  },
  objectName: {
    width: '480px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
  },
  dsp: {
    width: '192px',
    paddingLeft: '0px',
  },
  member: {
    width: '240px',
  },
  groupName: {
    width: '490px',
  },
  allocationRange: {
    width: '147px',
  },
  delivery: {
    width: '240px',
  },
  budgetOpt: {
    width: '172px',
  },
  dailyDelivery: {
    width: '148px',
  },
  lifetimeDelivery: {
    width: '161',
  },
  infoCircle: {
    margin: '4px 8px 0px 0px',
    height: '14px',
  },
  dspObjectTable: {
    width: '1200px',
  },
  errorMsg: {
    color: REDS.R500_WATERMELON,
  },
  strategyInformationTextConatiner: {
    display: 'flex',
    gap: SPACING[40],
  },
  strategyInformationGoalTextContainer: {
    display: 'flex',
    gap: SPACING[40],
  },
  basicStratInfoListFirst: {
    width: '195px',
  },
  basicStratInfoListText: {
    padding: '11px 0 3px',
  },
  basicStratContainer: {
    width: '100%',
    margin: '0 8px',
  },
  confirmHeaderDetailContainer: {
    marginTop: SPACING[24],
  },
  confirmStrategyName: {
    marginLeft: SPACING[4],
  },
  budgetHeaderSection: {
    display: 'flex',
    gap: SPACING[4],
    marginBottom: SPACING[24],
  },
  confirmStepTableStyle: {
    height: SPACING[60],
  },
  dspObjectContainer: {
    marginTop: SPACING[20],
  },
  confirmStepInlineMessage: {
    marginTop: SPACING[8],
  },
  budgetTableSectionStyle: {
    border: `1px solid ${WPP.grey400}`,
    marginBottom: SPACING[12],
  },
  dspObjectTableObjectNameStyle: {
    width: '772px',
  },
  dspObjectTableNameStyle: {
    width: '96px',
  },
  dspObjectTableMemberStyle: {
    width: '105px',
  },
  dspObjectTableAdvertiserStyle: {
    width: '145px',
  },
  adminToolsSection: {
    width: '101%',
    marginBottom: SPACING[32],
    marginTop: SPACING[24],
    marginLeft: '-22px',
  },
  koalaBoxModelBodyConatiner: {
    display: 'flex',
    flexDirection: 'column',
  },
  koalaBoxModelBtnStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

};
