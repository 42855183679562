import React, { CSSProperties } from 'react';
import _ from 'lodash';
import { DSP } from 'constantsBase';
import { legendTextStyle } from 'containers/StrategyWizard/steps/StrategyTypeSelection/styles';
import DspIcon from 'components/DspIcon';
import { WppTypography } from 'buildingBlocks';

type LegendBadgeProps = {
  style?: CSSProperties
  children?: React.ReactNode
  onMouseOver?: () => void
  onMouseOut?: () => void
};

type LegendTextProps = {
  style?: CSSProperties
  children?: React.ReactNode
};

type LegendProps = {
  dspId: number,
};

export const LegendText = ({ style, children }: LegendTextProps) => (
  <div style={{ ...legendTextStyle, ...style }}>{children}</div>
);

export const LegendBadge = ({ style, children, onMouseOver = _.noop, onMouseOut = _.noop }: LegendBadgeProps) => (
  <span
    style={{ ...style }}
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
    // the following fns for a11y purposes
    onFocus={_.noop}
    onBlur={_.noop}
  >
    {children}
  </span>
);

export const LegendDspIcons = ({ dspId }: LegendProps) => (_.isEqual(dspId, DSP.MULTIPLE.id)
  ? (
    <div>
      <DspIcon dspId={DSP.AMZN.id} style={{ marginLeft: '-3px' }} />
      <DspIcon dspId={DSP.DBM.id} />
      <DspIcon dspId={DSP.TTD.id} />
      <DspIcon dspId={DSP.APN.id} />
    </div>
  ) : <DspIcon dspId={dspId} style={{ marginLeft: '-3px' }} />
);

const GenerateSingleDspLegend = (dspId: number) => {
  const dspDisplayName = _.get(DSP.getById(dspId), 'displayName');
  return (
    <span key={dspId}>
      <DspIcon dspId={dspId} style={{ marginRight: 6 }} />
      <WppTypography type="xs-body" style={{ ...legendTextStyle, marginRight: 18 }}>{`Compatible with ${dspDisplayName}`}</WppTypography>
    </span>
  );
};

const dspIdsInOrderByName = [DSP.AMZN.id, DSP.DBM.id, DSP.TTD.id, DSP.APN.id];
const Legend = ({ dspId }: LegendProps) => (_.isEqual(dspId, DSP.MULTIPLE.id)
  ? (
    <div>
      {_.map(dspIdsInOrderByName, (id: number) => GenerateSingleDspLegend(id))}
    </div>
  ) : GenerateSingleDspLegend(dspId)
);

export default Legend;
