import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { GlobalState } from 'reducers';
import { Form, WppGrid, WppTypography, WppModal, WppButton, ValidatedCustomWppInput as NameField } from 'buildingBlocks';
import PermissionWrapper from 'components/PermissionWrapper';
import { STRATEGY_TYPE } from 'constantsBase';
import { WizardSteps, WIZARD_STEPS } from 'containers/StrategyWizard/constants';
import { wppBodyContainer } from 'components/PageTemplate/style';
import { StrategyWizardResponse } from 'containers/StrategyWizard/hooks';
import { StrategyConfirmationForm, WizardFormValues } from 'containers/StrategyWizard/types';
import { getBudgetParentData } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/GroupSettings/utils';
import { BudgetTypes, DEFAULT_GROUP_ID, DEFAULT_PG_GROUP_ID } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { isCrossPlatformStrategyType } from 'containers/StrategyWizard/utils';
import { COPILOT_LAYOUT } from 'globalStyles';
import { pluralizer } from 'utils/formattingUtils';
import { Permission, checkPermissions } from 'utils/featureFlags';
import { createLinkWithQS, isQAGlobalReadOnly } from 'utils/functionHelpers';
import { User, FlightDisplayName, WppInputCustomEvent, InputChangeEventDetail } from 'utils/types';
import { PossibleStates } from 'utils/hooks/useFetcher';
import ConfirmHeader from './confirmComponents/ConfirmHeader';
import StrategyConfirmDetails, { ConfirmDetailsStrategyConfig } from './confirmComponents/ConfirmDetails';
import ConfirmButton from './confirmComponents/ConfirmButton';
import { handleWizardSubmit, isBudgetOptimization } from './utils';
import AdminConfig from './confirmComponents/AdminConfig';
import { updateFormWithAdminBoxValues } from '../StrategyConfiguration/utils';
import { STRATEGY_CONFIRMATION_STYLES } from './styles';
import BudgetIntervals from './confirmComponents/BudgetIntervals';
import DSPObjects from './confirmComponents/DSPObjects';
import BudgetManagement from './confirmComponents/BudgetManagement';
import { configuringLineItemStratCheck } from '../AttachFlights/utils';

const { SPACING } = COPILOT_LAYOUT;

type KoalaBoxProps = {
  numberOfRemovedFlights: number
  optimizationLevel: FlightDisplayName
};

const KoalaBox = ({ numberOfRemovedFlights, optimizationLevel }: KoalaBoxProps) => {
  const [open, setOpen] = useState<boolean>(numberOfRemovedFlights > 0);
  const flightType = pluralizer(optimizationLevel.single, optimizationLevel.multiple)(numberOfRemovedFlights);
  return (
    <WppModal size="s" open={open}>
      <WppTypography type="xl-heading" slot="header">{`Copilot will stop managing ${numberOfRemovedFlights} ${flightType}.`}</WppTypography>
      <div slot="body" style={STRATEGY_CONFIRMATION_STYLES.koalaBoxModelBodyConatiner}>
        <WppTypography type="s-body">They will be deactivated and stop buying immediately until you update them.</WppTypography>
        <WppTypography type="s-body">To resume spending, go to the DSP console, verify the correct buying strategy is selected, and activate the {flightType} to resume buying.</WppTypography>
      </div>
      <div slot="actions" style={STRATEGY_CONFIRMATION_STYLES.koalaBoxModelBtnStyle}>
        <WppButton
          size="s"
          onClick={() => setOpen(false)}
        >
          Confirm
        </WppButton>
      </div>
    </WppModal>
  );
};

type ConfirmProps = {
  strategyState: StrategyWizardResponse
  strategyId?: number
};

const Confirm = (props: ConfirmProps) => {
  const { strategyId, strategyState } = props;
  const user = useSelector<GlobalState>((state) => state.login.user) as User;
  const isQAGlobal = isQAGlobalReadOnly(user);
  const reduxStrategyWizard = useSelector<GlobalState>((state) => state.strategyWizard) as WizardFormValues;
  const {
    attachFlightsStep,
    goalSelectionStep,
    strategyTypeSelectionStep: { strategyType },
    strategyConfigurationStep,
    strategyConfirmationStep: { name, userBidModifierRemovalConsent },
    budgetAllocationState,
  } = reduxStrategyWizard;
  const viewability = _.get(strategyConfigurationStep, 'viewability', { enabled: false });
  const groupSettings = _.get(strategyConfigurationStep, 'groupSettings');
  const {
    member,
    advertiser,
    defaultCurrency,
    attachedFlights,
    optimizationLevel,
    toBeDetached,
    brand,
    selectedOptType,
  } = attachFlightsStep;

  const canSaveStrategy = checkPermissions(user, Permission.saveUpdateStrategy, member);
  const formMethods = useForm<StrategyConfirmationForm>({ mode: 'onChange' });
  const { goal, budget } = goalSelectionStep;
  const customGoal = _.get(goalSelectionStep, 'customGoal');
  const revenueOutcomeType = _.get(goalSelectionStep, 'revenueOutcomeType');
  const hasFlightsAttached = _.size(attachedFlights) > 0;
  const { getValues, reset, control, formState, watch } = formMethods;

  const strategyName = watch('name');
  const [savingInProgress, setSavingInProgress] = useState<boolean>(false);
  const [updateAdminConfig, setUpdateAdminConfig] = useState<boolean>(!!_.get(strategyConfigurationStep, 'minDeliveryFracLever'));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const disabled = !canSaveStrategy || !_.isEmpty(formState.errors) || !strategyName || isQAGlobal;
  const isCrossPlatformOptimization = isCrossPlatformStrategyType(_.get(strategyType, 'id'));
  const budgetAllocationData = _.get(budgetAllocationState, 'data');
  const { requiredDailyValue, interval, remainingSpendDays, flightExtType, flightTimezone } = getBudgetParentData(isCrossPlatformOptimization, goalSelectionStep, budgetAllocationData, attachedFlights);

  const goToStep = (stepId: number) => {
    navigate(createLinkWithQS(`/strategies/wizard${strategyId ? `/${strategyId}` : ''}/${stepId}`));
  };
  const isHighOptLvlStratType = !configuringLineItemStratCheck(selectedOptType);
  const hasNMSPermission = checkPermissions(user, Permission.nmsConsolidatedIo);

  useEffect(() => {
    reset({ name, userBidModifierRemovalConsent });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, userBidModifierRemovalConsent]);

  const getBudgetSettings = (strategyTypeId: number) => {
    if (isBudgetOptimization(strategyTypeId) && hasFlightsAttached) {
      const flight = _.first(attachedFlights);
      const budgetData = _.get(budgetAllocationState, `data[${flight.externalId}].hierarchy.parentSettings`);
      if (!_.size(budgetData)) {
        return [];
      }
      // show dates as-is from the DSP, without any timezone conversion
      const type = _.get(budgetData, 'budgetType');
      const intervalBudget: number = type === BudgetTypes.imps ? _.get(budgetData, 'budgetImps') : _.get(budgetData, 'budgetAmount');
      return [{
        startDate: _.get(budgetData, 'startDate'),
        endDate: _.get(budgetData, 'endDate'),
        budget: intervalBudget ?? 0,
        type,
      }];
    }

    if (strategyTypeId === STRATEGY_TYPE.crossPlatformOptimization.id) {
      const budgetSettings = _.map(goalSelectionStep?.budgetSettings, (o) => {
        // eslint-disable-next-line no-param-reassign
        o.budget = +o.budget;
        return _.pick(o, ['budget', 'startDate', 'endDate', 'type']);
      });
      return budgetSettings;
    }
    return null;
  };

  const strategyTypeId = _.get(strategyType, 'id');
  const strategyTypeSpecificStrategyId = strategyTypeId;
  const strategyTypeSpecificStrategyConfig = strategyConfigurationStep as ConfirmDetailsStrategyConfig;
  const budgetSettings = getBudgetSettings(strategyTypeId);
  const strategyGoals = [goal];
  const filteredGroupSettings = _.omit(groupSettings, [DEFAULT_GROUP_ID, DEFAULT_PG_GROUP_ID]);

  const formValuesWithStrategyGoals = {
    ...strategyConfigurationStep,
    strategyGoals,
    ...(budgetSettings && { budgetSettings }),
    ...(goal.impValueFilters && { impValueFilters: goal.impValueFilters }),
    ...(_.size(budget) && { hasCustomRevenueType: true, revenueOutcomeType }),
  };
  const showConfirmDetails = (strategyId && optimizationLevel)
    || (hasFlightsAttached && (!strategyId || strategyState.kind === PossibleStates.hasData));

  if (showConfirmDetails) {
    return (
      <FormProvider {...formMethods}>
        <Form
          onSubmit={() => {
            setSavingInProgress(true);
            handleWizardSubmit(
              // @ts-ignore
              strategyId,
              {
                ...reduxStrategyWizard,
                strategyConfirmationStep: {
                  name: getValues('name'),
                  userBidModifierRemovalConsent: getValues('userBidModifierRemovalConsent'),
                },
              },
              navigate,
              dispatch,
              updateAdminConfig,
            );
          }}
          loading={savingInProgress}
        >
          <KoalaBox
            numberOfRemovedFlights={_.size(toBeDetached)}
            optimizationLevel={optimizationLevel.displayName}
          />
          <WppGrid container fullWidth item all={24}>
            <WppGrid item all={24}>
              <div style={wppBodyContainer}>
                <div style={STRATEGY_CONFIRMATION_STYLES.strategyConfirmInfoName}>
                  <WppTypography style={STRATEGY_CONFIRMATION_STYLES.header} type="xl-heading">Strategy Information</WppTypography>
                  <WppTypography style={STRATEGY_CONFIRMATION_STYLES.confirmStrategyName} type="s-strong">Strategy Name</WppTypography>
                </div>
                <div style={{ marginTop: SPACING[4] }}>
                  <Controller
                    name="name"
                    rules={{
                      required: { value: true, message: 'Required' },
                      pattern: { value: /\w/, message: 'Name must contain at least one character' },
                      maxLength: { value: 255, message: 'Name has a maximum of 255 characters.' },
                    }}
                    control={control}
                    render={({ field, fieldState }) => (
                      <NameField
                        type="text"
                        placeholder="Enter a Strategy Name"
                        onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) => field.onChange(event.detail.value)}
                        field={field}
                        fieldState={fieldState}
                      />
                    )}
                  />
                </div>
                <div style={STRATEGY_CONFIRMATION_STYLES.confirmHeaderDetailContainer}>
                  <ConfirmHeader
                    advertiser={advertiser}
                    member={member}
                    brand={brand}
                    selectedOptType={selectedOptType}
                    optimizationLevel={optimizationLevel}
                    attachedFlights={attachedFlights}
                    strategyTypeDisplayName={strategyType.displayName}
                  />
                  <StrategyConfirmDetails
                    strategyId={strategyId}
                    strategyType={strategyType}
                    strategyConfig={strategyTypeSpecificStrategyConfig}
                    currencyCode={_.get(defaultCurrency, 'code')}
                    goal={goal}
                    viewability={viewability}
                    budget={budget}
                    customGoal={customGoal}
                  />
                </div>
              </div>
            </WppGrid>
            {(isHighOptLvlStratType && hasFlightsAttached && !!_.size(budgetSettings)) && (
              <BudgetIntervals
                goToStep={() => goToStep(WIZARD_STEPS[WizardSteps.goalSelectionStep].id)}
                budgetSettings={budgetSettings}
                defaultCurrency={_.get(defaultCurrency, 'code')}
                flightExtType={flightExtType}
                flightTimezone={flightTimezone}
              />
            )}
            {(!!_.size(filteredGroupSettings) && hasFlightsAttached) && (
              <BudgetManagement
                goToStep={() => goToStep(WIZARD_STEPS[WizardSteps.strategyConfigurationStep].id)}
                groupSettings={filteredGroupSettings}
                defaultCurrency={defaultCurrency}
                isCrossPlatformOptimization={isCrossPlatformOptimization}
                interval={interval}
                remainingSpendDays={remainingSpendDays}
                requiredDailyValue={requiredDailyValue}
                hasNMSPermission={hasNMSPermission}
              />
            )}
            <DSPObjects
              attachFlightsStep={attachFlightsStep}
              goToStep={() => goToStep(WIZARD_STEPS[WizardSteps.attachFlightsStep].id)}
            />
            <PermissionWrapper permissions={Permission.wizardAdmin}>
              <AdminConfig
                name="adminBox"
                strategyType={strategyType}
                member={member}
                advertiser={advertiser}
                brand={brand}
                defaultCurrency={_.get(defaultCurrency, 'code')}
                formValues={formValuesWithStrategyGoals}
                updateAdminConfig={updateAdminConfig}
                setUpdateAdminConfig={setUpdateAdminConfig}
                onEditorSubmit={(values) => {
                  updateFormWithAdminBoxValues(values, strategyTypeSpecificStrategyId, strategyConfigurationStep, goalSelectionStep, dispatch, _.get(strategyType, 'dsp'));
                }}
              />
            </PermissionWrapper>
          </WppGrid>
          <ConfirmButton
            strategyId={strategyId}
            disabled={disabled}
          />
        </Form>
      </FormProvider>
    );
  }
  return <></>;
};

export default Confirm;
