import React, { MouseEventHandler } from 'react';
import { WppActionButton, WppTypography, WppEmptyNothingFound } from 'buildingBlocks';
import { GOAL_SECTION_STYLES } from '../styles';

type GoalNotFoundProps = {
  resetSearch: MouseEventHandler
};

const GoalNotFound: React.FC<GoalNotFoundProps> = ({ resetSearch }: GoalNotFoundProps): React.ReactElement => (
  <div style={GOAL_SECTION_STYLES.noVisibleGoals}>
    <WppEmptyNothingFound width={120} />
    <WppTypography tag="p" type="s-body">No goals found. Please try again.</WppTypography>
    <WppActionButton onClick={resetSearch} role="button" tabIndex={0}>Search again.</WppActionButton>
  </div>
);

export default GoalNotFound;
