import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { WppCardGroup } from 'buildingBlocks';
import { CardGroupChangeEventDetail, StrategyType } from 'utils/types';
import { isStrategyTypeDisabled } from 'utils/hooks/useStrategyTypeFetcher';
import { StrategyTypeStatus } from '../StrategyType';
import { DisabledStrategyTypeCard, EnabledStrategyTypeCard } from './StrategyTypeCard';

export type Props = {
  onChange: Function
  value: StrategyType
  items: Array<StrategyType>
  strategyTypesToDisable?: Array<StrategyTypeStatus>
};

const StrategyTypeRadio = ({ items, onChange, value, strategyTypesToDisable }: Props) => {
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    if (value) setSelectedCard(value.id);
  }, [value]);

  const onClick = (selected) => {
    if (onChange) {
      onChange(selected);
    }
  };

  const handleSingleCardGroupChange = (event: CustomEvent<CardGroupChangeEventDetail>) => {
    const selectedIdFromEvent = event.detail.value;
    if (selectedIdFromEvent) {
      setSelectedCard(selectedIdFromEvent);
    }
  };

  return (
    <WppCardGroup
      onWppChange={handleSingleCardGroupChange}
      value={selectedCard}
      withRadioOrCheckbox={false}
    >
      {_.map(items, (item) => {
        const itemSelected = item.id === _.get(value, 'id');
        const restrictions = _.get(strategyTypesToDisable, item.id);
        const isDisabled = isStrategyTypeDisabled(restrictions);
        if (isDisabled) {
          return (
            <DisabledStrategyTypeCard
              key={item.id}
              item={item}
            />
          );
        }
        return (
          <EnabledStrategyTypeCard
            key={item.id}
            item={item}
            itemSelected={itemSelected}
            onClick={!itemSelected ? () => onClick(item) : undefined}
          />
        );
      })}
    </WppCardGroup>
  );
};

export default StrategyTypeRadio;
