/* eslint-disable consistent-return */
import _ from 'lodash';
import React, { useState } from 'react';
import { WppActionButton, WppGrid, WppTypography } from 'buildingBlocks';
import { RESULTS_LIMIT } from 'constantsBase';
import { wppBodyContainer } from 'components/PageTemplate/style';
import { AdvertiserMemberInfo, AttachFlightsForm } from 'containers/StrategyWizard/types';
import { FlightCategory } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { Advertiser } from 'utils/copilotAPI';
import { STRATEGY_CONFIRMATION_STYLES } from '../styles';
import InfoCircleIcon from './InfoCircleIcon';
import SingleDSPObjectTable from './SingleDSPObjectTable';

type Props = {
  attachFlightsStep: AttachFlightsForm
  goToStep?: () => void
};

const DSPObjects = ({ attachFlightsStep, goToStep }: Props) => {
  const [advertiserMemberInfo, setAdvertiserMemberInfo] = useState<Array<AdvertiserMemberInfo>>([]);
  const {
    attachedFlights,
    ineligibleFlights,
    toBeDetached,
    attachedToThisStrategy,
    eligibleFlights,
  } = attachFlightsStep;

  // These flight arrays are specific to cross-platform - not guaranteed to exist
  const deactivatedFlights = _.get(attachFlightsStep, 'deactivatedFlights', []);
  const toBeDeactivated = _.get(attachFlightsStep, 'toBeDeactivated', []);
  const reactivatedFlights = _.get(attachFlightsStep, 'reactivatedFlights', []);

  const allFlights = _.uniq(_.concat(attachedFlights, toBeDetached));
  const dspCount = _.uniq(_.map(allFlights, 'dsp')).length;

  useMount(() => {
    const advertiserIds = _.uniq(_.map(allFlights, 'advertiser'));
    async function fetchData(advertisers: Array<number>) {
      const response = await Advertiser.get({
        where: { id: advertisers },
        populate: 'member',
        limit: RESULTS_LIMIT,
      });
      setAdvertiserMemberInfo(_.get(response, 'data'));
    }
    fetchData(advertiserIds);
  });

  return (
    <WppGrid item all={24}>
      <div style={wppBodyContainer}>
        <div style={STRATEGY_CONFIRMATION_STYLES.budgetHeaderSection}>
          <WppTypography type="xl-heading">DSP Objects</WppTypography>
          <WppActionButton onClick={goToStep}>Edit</WppActionButton>
        </div>
        {!!_.size(attachedToThisStrategy) && (
          <SingleDSPObjectTable
            flights={attachedToThisStrategy}
            advertiserMemberInfo={advertiserMemberInfo}
            flightCategory={FlightCategory.attachedToThisStrategy}
            dspCount={dspCount}
          />
        )}
        {!!_.size(deactivatedFlights) && (
          <SingleDSPObjectTable
            flights={deactivatedFlights}
            advertiserMemberInfo={advertiserMemberInfo}
            flightCategory={FlightCategory.deactivatedFlights}
          />
        )}
        {!!_.size(ineligibleFlights) && (
          <SingleDSPObjectTable
            flights={ineligibleFlights}
            advertiserMemberInfo={advertiserMemberInfo}
            flightCategory={FlightCategory.ineligibleFlights}
          />
        )}
        {!!_.size(toBeDeactivated) && (
          <SingleDSPObjectTable
            flights={toBeDeactivated}
            advertiserMemberInfo={advertiserMemberInfo}
            flightCategory={FlightCategory.toBeDeactivated}
          />
        )}
        {!!_.size(toBeDetached) && (
          <SingleDSPObjectTable
            flights={toBeDetached}
            advertiserMemberInfo={advertiserMemberInfo}
            flightCategory={FlightCategory.toBeDetached}
          />
        )}
        {!!_.size(reactivatedFlights) && (
          <SingleDSPObjectTable
            flights={reactivatedFlights}
            advertiserMemberInfo={advertiserMemberInfo}
            flightCategory={FlightCategory.reactivatedFlights}
          />
        )}
        {!!_.size(eligibleFlights) && (
          <SingleDSPObjectTable
            flights={eligibleFlights}
            advertiserMemberInfo={advertiserMemberInfo}
            flightCategory={FlightCategory.eligibleFlights}
          />
        )}
        {/* eslint-disable-next-line no-extra-boolean-cast */}
        {!!!_.size(allFlights) && (
          <div style={STRATEGY_CONFIRMATION_STYLES.step5Table}>
            <InfoCircleIcon />
            There are no objects attached to the strategy.
          </div>
        )}
      </div>
    </WppGrid>
  );
};

export default DSPObjects;
