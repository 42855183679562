import React, { MutableRefObject, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { WppButton, WppGrid, WppIconInfo, WppSideModal, WppTypography } from 'buildingBlocks';
import { MODAL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { CYOD_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import CYODGoalConfiguration from './CYODGoalConfiguration';

type CYODModalProps = {
  CYODModalOpen: boolean
  setCYODModalOpen: (x: any) => void
  setConfirmedGoal: (x: any) => void
  cyodRef: MutableRefObject<boolean>
};

const CYODModal = (props: CYODModalProps) => {
  const { CYODModalOpen, setCYODModalOpen, setConfirmedGoal, cyodRef } = props;
  const { getValues, setValue, clearErrors } = useFormContext<WizardFormGoalSelection>();
  const [modalGoalType, setModalGoalType] = useState<string>(getValues('goal.type'));
  const [modalGoalTarget, setModalGoalTarget] = useState<number | null>(getValues('goal.target') ?? null);
  const [goalTargetError, setGoalTargetError] = useState<string>('');

  const handleModalClose = (e) => {
    e.stopPropagation();
    setCYODModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.stopPropagation();
    // submit button will only be enabled if there are no errors
    // clear any previous target errors on the form
    clearErrors('goal.target');
    setCYODModalOpen(false);
    cyodRef.current = true;
    setValue('goal.type', modalGoalType);
    setValue('goal.target', modalGoalTarget);
    setConfirmedGoal(modalGoalType);
  };

  return (
    <WppSideModal open={CYODModalOpen} onWppSideModalClose={handleModalClose} size="l" disableOutsideClick>
      <WppTypography slot="header" tag="p" type="2xl-heading">Upload External Measurement Configuration</WppTypography>
      <div slot="body" style={MODAL_STYLES.content}>
        <WppTypography type="s-body" style={CYOD_MODAL_STYLES.description}>
          This goal type enables you to optimize to data not available in the DSP and
          <span style={CYOD_MODAL_STYLES.boldedText}> puts you in control of uploading new performance data </span>
          as often as you want. We refer to the performance data you upload as <span style={CYOD_MODAL_STYLES.boldedText}>Success Events</span>.
          Copilot optimizes your campaign based on the data you upload. Make sure to comply with company policies and best
          practices regarding data privacy and ethics when uploading. If you have questions, consult  your manager.
        </WppTypography>
        <div style={CYOD_MODAL_STYLES.noMargin}>
          <CYODGoalConfiguration
            modalGoalType={modalGoalType}
            setModalGoalType={setModalGoalType}
            modalGoalTarget={modalGoalTarget}
            setModalGoalTarget={setModalGoalTarget}
            goalTargetError={goalTargetError}
            setGoalTargetError={setGoalTargetError}
          />
          <WppGrid style={CYOD_MODAL_STYLES.noPadding}>
            <div style={CYOD_MODAL_STYLES.messageContainer}>
              <WppIconInfo style={CYOD_MODAL_STYLES.infoIcon} />
              <div>
                <WppTypography type="m-strong">Data Upload and Pacing</WppTypography>
                <WppTypography type="s-body" tag="p">
                  Once you have created this Strategy, you will be able to upload your data in the Strategy Analytics page.
                  <br />
                  Until data is uploaded, Copilot will not perform goal optimizations, but it will still pace the Strategy.
                </WppTypography>
              </div>
            </div>
          </WppGrid>
        </div>

      </div>
      <div slot="actions" style={CYOD_MODAL_STYLES.btnRow}>
        <WppButton variant="secondary" size="m" onClick={handleModalClose}>Cancel</WppButton>
        <WppButton
          type="submit"
          size="m"
          onClick={handleSubmit}
          disabled={!!goalTargetError}
          style={CYOD_MODAL_STYLES.submitBtn}
        >
          Submit
        </WppButton>
      </div>
    </WppSideModal>
  );
};

export default CYODModal;
