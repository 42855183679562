import _ from 'lodash';
import React from 'react';
import { WppGrid, WppTable, WppTableBody, WppTableBodyCell, WppTableBodyRow, WppTypography } from 'buildingBlocks';
import { OptimizationType } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { configuringCrossPlatformStratCheck } from 'containers/StrategyWizard/steps/AttachFlights/utils';
import { Advertiser, Member, Flight, OptimizationLevelType, Brand, FlightDisplayName } from 'utils/types';
import { STRATEGY_CONFIRMATION_STYLES } from 'containers/StrategyWizard/steps/StrategyConfirmation/styles';

type BasicStratListProps = {
  first: string
  second?: string
};

export const BasicStratInfoList = ({ first, second }: BasicStratListProps) => (
  <WppGrid container fullWidth>
    <WppGrid direction="row" style={STRATEGY_CONFIRMATION_STYLES.basicStratContainer}>
      <WppTable>
        <WppTableBody className="wpp-tbody">
          <WppTableBodyRow>
            <WppTableBodyCell style={STRATEGY_CONFIRMATION_STYLES.basicStratInfoListFirst}>
              <WppTypography type="s-strong" style={STRATEGY_CONFIRMATION_STYLES.basicStratInfoListText}>{first}</WppTypography>
            </WppTableBodyCell>
            <WppTableBodyCell>
              <WppTypography type="s-body" style={STRATEGY_CONFIRMATION_STYLES.basicStratInfoListText}>{second}</WppTypography>
            </WppTableBodyCell>
          </WppTableBodyRow>
        </WppTableBody>
      </WppTable>
    </WppGrid>
  </WppGrid>
);

type BasicStratInfoProps = {
  optimizationLevel: FlightDisplayName
  attachedFlights: Array<Flight>
  strategyTypeDisplayName: string
  selectedOptType: OptimizationType
  member?: string
  advertiser?: string
  brand?:string
};

const renderBasicStratInfo = ({
  member,
  advertiser,
  optimizationLevel,
  brand,
  attachedFlights,
  strategyTypeDisplayName,
  selectedOptType,
}: BasicStratInfoProps) => {
  const multipleFlightsAttached = _.size(attachedFlights) > 1;
  const dspSpecificOptLevel = multipleFlightsAttached ? `Multiple ${optimizationLevel.multiple}` : optimizationLevel.single;
  const optLevel = configuringCrossPlatformStratCheck(selectedOptType) ? 'Cross-Platform' : dspSpecificOptLevel;

  if (advertiser && !brand) {
    return (
      <>
        <BasicStratInfoList first="Member" second={member || '-'} />
        <BasicStratInfoList first="Advertiser" second={advertiser} />
        <BasicStratInfoList first="Strategy Type" second={strategyTypeDisplayName} />
        <BasicStratInfoList first="Optimization Level" second={optLevel} />
      </>
    );
  }

  if (!advertiser && brand) {
    return (
      <>
        <BasicStratInfoList first="Brand" second={brand} />
        <BasicStratInfoList first="Strategy Type" second={strategyTypeDisplayName} />
        <BasicStratInfoList first="Optimization Level" second={optLevel} />
      </>
    );
  }

  return (
    <>
      <BasicStratInfoList first="Brand" second={brand || '-'} />
      <BasicStratInfoList first="Member" second={member || '-'} />
      <BasicStratInfoList first="Advertiser" second={advertiser || '-'} />
      <BasicStratInfoList first="Strategy Type" second={strategyTypeDisplayName} />
      <BasicStratInfoList first="Optimization Level" second={optLevel} />
    </>
  );
};

const BasicStratInfo = ({ member, advertiser, optimizationLevel, brand, attachedFlights, strategyTypeDisplayName, selectedOptType }: BasicStratInfoProps) => (
  <>
    {renderBasicStratInfo({ member, advertiser, optimizationLevel, brand, attachedFlights, strategyTypeDisplayName, selectedOptType })}
  </>
);

type ConfirmHeaderProps = {
  attachedFlights: Array<Flight>
  advertiser: Advertiser
  member: Member
  optimizationLevel: OptimizationLevelType
  strategyTypeDisplayName: string
  selectedOptType: OptimizationType
  brand?: Brand
};

const ConfirmHeader = ({
  advertiser,
  member,
  optimizationLevel,
  attachedFlights,
  brand,
  strategyTypeDisplayName,
  selectedOptType,
}: ConfirmHeaderProps) => (
  <div>
    <BasicStratInfo
      member={_.get(member, 'displayName')}
      advertiser={_.get(advertiser, 'name')}
      optimizationLevel={_.get(optimizationLevel, 'displayName')}
      brand={_.get(brand, 'name')}
      attachedFlights={attachedFlights}
      strategyTypeDisplayName={strategyTypeDisplayName}
      selectedOptType={selectedOptType}
    />
  </div>
);

export default ConfirmHeader;
