/* eslint-disable no-param-reassign */
import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { Map as ImmutableMap } from 'immutable';
import dspIconStyles from 'components/DspIcon/style';
import { DSP } from 'constantsBase';
import { WppInput, WppTableBody, WppTableBodyCell, WppTableBodyRow, WppTypography } from 'buildingBlocks';
import { MODAL_ERROR_MSG } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { AWG_WEIGHTS_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { MetricConfig, MetricConfigObj, WeightConfig, WeightObj, WeightRowObj } from 'containers/StrategyWizard/types';
import { InputChangeEventDetail, WppInputCustomEvent } from 'utils/types';
import { PIXEL_PICKER_STYLES } from 'containers/StrategyWizard/components/PixelPicker/styles';
import { useWeightsModalContentContext } from '../contexts/WeightsModalContentProvider';
import { useWeightsSectionContext } from '../contexts/WeightsSectionProvider';

const { inputFieldError, weightListDisplay, weightListDspCode } = AWG_WEIGHTS_MODAL_STYLES;
const { dspHeader } = PIXEL_PICKER_STYLES;

type WeightRowProps = {
  listItem: WeightRowObj
  flightsToDisplay: ImmutableMap<string, WeightObj>
  setFlightsToDisplay: Dispatch<SetStateAction<ImmutableMap<string, WeightObj>>>
  dspId: string
};

const WeightRow = ({
  listItem, flightsToDisplay, setFlightsToDisplay, dspId,
}: WeightRowProps) => {
  const dspCode = DSP.getById(dspId).code;
  const dspHeaderStyle = { ...dspIconStyles[dspCode], ...dspHeader };
  const { selectedMetric, sessionMetrics, setSessionMetrics } = useWeightsSectionContext();
  const { attemptSave, formErrors, setFormErrors } = useWeightsModalContentContext();
  const showError = attemptSave && formErrors[listItem.id];

  const handleOnChange = (event: WppInputCustomEvent<InputChangeEventDetail>) => {
    let value = event.detail.value;
    if (value === '') {
      setFormErrors({
        ...formErrors,
        [listItem.id]: MODAL_ERROR_MSG,
      });
    } else {
      delete formErrors[listItem.id];
      let updatedSessionMetric;
      // convert sessionMetrics to JS Obj then check weighting[dspId]
      // getIn([selectedMetric, 'weighting', dspId]) returns undefined at times even though it exists
      const selectedMetricConfig = sessionMetrics.getIn([selectedMetric], {}) as ImmutableMap<string, MetricConfigObj>;
      const dspWeightConfig = _.get(selectedMetricConfig.toJS(), `weighting[${dspId}]`, {});
      // set weighting to {} if we change the only weighted option back to 1
      if (value === '1' && (_.size(dspWeightConfig) === 1 && _.has(dspWeightConfig, listItem.id))) {
        updatedSessionMetric = sessionMetrics.deleteIn([selectedMetric, 'weighting', dspId]);
      }
      // only remove listItem.id key if value is '1' and dspWeightConfig has listItem.id
      if (value === '1' && _.size(dspWeightConfig) > 1 && _.has(dspWeightConfig, listItem.id)) {
        updatedSessionMetric = sessionMetrics.deleteIn([selectedMetric, 'weighting', dspId, _.toString(listItem.id)]);
      }
      if (value !== '1') {
        // Allow for only 4 decimal places
        value = value.split('.').map((el, i) => (i ? el.split('').slice(0, 4).join('') : el)).join('.');
        updatedSessionMetric = sessionMetrics.mergeDeep({
          [selectedMetric]: {
            weighting: { [dspId]: { [listItem.id]: _.toNumber(value) as unknown } },
          },
        } as MetricConfig);
      }
      if (updatedSessionMetric) {
        setSessionMetrics(updatedSessionMetric);
      }
    }
    setFlightsToDisplay(flightsToDisplay.mergeDeep({
      [dspId]: {
        [listItem.id]: { weight: value },
      },
    } as WeightConfig));
  };

  return (
    <WppTableBody className="wpp-tbody">
      <WppTableBodyRow className="wpp-table-body-row">
        <WppTableBodyCell className="wpp-table-body-cell" style={weightListDisplay}>
          <WppTypography type="s-body" tag="p">{listItem.id} | {listItem.displayName}</WppTypography>
        </WppTableBodyCell>
        <WppTableBodyCell className="wpp-table-body-cell" style={weightListDspCode}>
          <WppTypography type="s-body" tag="p" style={dspHeaderStyle}>{dspCode}</WppTypography>
        </WppTableBodyCell>
        <WppTableBodyCell className="wpp-table-body-cell">
          <WppInput
            id={showError ? 'metricInputError' : ''}
            type="number"
            style={{ width: '100%', ...(showError && inputFieldError) }}
            value={_.toString(listItem.weight)}
            size="s"
            onWppChange={handleOnChange}
          />
        </WppTableBodyCell>
      </WppTableBodyRow>
    </WppTableBody>
  );
};

type WeightsListProps = {
  weightsList: WeightObj
  flightsToDisplay: ImmutableMap<string, WeightObj>
  setFlightsToDisplay: Dispatch<SetStateAction<ImmutableMap<string, WeightObj>>>
  dspId: string
};

const WeightsList = ({ weightsList, flightsToDisplay, setFlightsToDisplay, dspId }: WeightsListProps) => (
  <>
    {_.map(weightsList, (listItem: WeightRowObj) => (
      <WeightRow
        dspId={dspId}
        key={listItem.id}
        listItem={listItem}
        flightsToDisplay={flightsToDisplay}
        setFlightsToDisplay={setFlightsToDisplay}
      />
    ))}
  </>
);

export default WeightsList;
