import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { CustomFormError } from 'containers/StrategyWizard/types';
import { WeightsModalContentProviderProps } from './types';
import { updateAttemptSave, updateFormErrors } from './actions';
import { modalContentReducer } from './reducers';

const WeightsModalContentContext = createContext(null);

const INITIAL_STATE = {
  attemptSave: false,
  formErrors: {},
};

const WeightsModalContentProvider = ({ children, sessionMetrics }: WeightsModalContentProviderProps) => {
  const [state, dispatch] = useReducer(modalContentReducer, INITIAL_STATE);

  useEffect(() => {
    dispatch(updateAttemptSave(false));
  }, [sessionMetrics]);

  const actions = {
    setAttemptSave: (attemptSave: boolean) => dispatch(updateAttemptSave(attemptSave)),
    setFormErrors: (formErrors: CustomFormError) => dispatch(updateFormErrors(formErrors)),
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <WeightsModalContentContext.Provider value={{ ...state, ...actions }}>
      {children}
    </WeightsModalContentContext.Provider>
  );
};

export const useWeightsModalContentContext = () => {
  const context = useContext(WeightsModalContentContext);
  if (!context) {
    throw new Error('useWeightsModalContentContext must be used within a WeightsModalContentProvider');
  }
  return context;
};

export default WeightsModalContentProvider;
