import { CSSProperties } from 'react';
import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

export const STRATEGY_CONFIGURATION_STYLES: { [key: string]: CSSProperties } = {
  configureContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginBottom: SPACING[4],
  },
  subHeader: {
    width: '620px',
  },
  container: {
    padding: 0,
    position: 'relative',
    marginBottom: SPACING[32],
  },
  moduleHeaderHelpStyle: {
    textDecoration: 'underline',
    bottom: '5px',
  },
  moduleHeaderDividerpStyle: {
    marginTop: SPACING[24],
    marginBottom: SPACING[24],
  },
};
