import { required, applyAllValidators } from 'utils/formValidators';
import { validate as validateCustomTree } from 'containers/StrategyWizard/ConfigurationByStrategyType/CustomTree/validate';
import validateHelios from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/validate';
import validateSegRec from 'containers/StrategyWizard/ConfigurationByStrategyType/SegmentRecency/validate';
import validateCustomBidList from 'containers/StrategyWizard/ConfigurationByStrategyType/CustomBidList/validate';
import validateBudgetOptimization from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/validate';
import validateCustomSDF from 'containers/StrategyWizard/ConfigurationByStrategyType/CustomSDF/validate';
import { STRATEGY_TYPE } from 'constantsBase';

export const validateFirstPage = (values) => applyAllValidators(values, { advertiser: required, member: required, optimizationLevel: required });
export const validateSecondPage = (values) => applyAllValidators(values, { strategyType: required });

export const getValidatorPerStrategyType = (strategyTypeId: number) => {
  switch (strategyTypeId) {
    case STRATEGY_TYPE.customTree.id:
      return validateCustomTree;
    case STRATEGY_TYPE.helios.id:
      return validateHelios;
    case STRATEGY_TYPE.heliosSegmentRecency.id:
      return validateSegRec;
    case STRATEGY_TYPE.customBidList.id:
      return validateCustomBidList;
    case STRATEGY_TYPE.apnBudgetOptimization.id:
    case STRATEGY_TYPE.ttdBudgetOptimization.id:
    case STRATEGY_TYPE.wmtBudgetOptimization.id:
    case STRATEGY_TYPE.dbmBudgetOptimization.id:
    case STRATEGY_TYPE.amznBudgetOptimization.id:
    case STRATEGY_TYPE.crossPlatformOptimization.id:
      return validateBudgetOptimization;
    case STRATEGY_TYPE.dbmCustomSDF.id:
      return validateCustomSDF;
    default:
      throw new Error(`There is no validator for strategy type id ${strategyTypeId}`);
  }
};

export const checkDupes = (dupesNames) => (formValues: {}, fieldName: string) => {
  const currentSegmentName = formValues[fieldName];
  if (dupesNames[currentSegmentName] && dupesNames[currentSegmentName].length > 0) {
    return { [fieldName]: { message: `Duplicated names "${currentSegmentName}"` } };
  }

  return {};
};

export const checkSegments = (formValues: {}, fieldName: string) => {
  const segments = formValues[fieldName];
  if (!segments || segments.length === 0) {
    // Field array use a different way to set error, they put everything under meta._error instead of meta.error
    // because meta.error is used for the children error.
    /* eslint-disable-next-line no-underscore-dangle */
    return { [fieldName]: { message: 'Required' } };
  }

  return {};
};
