import React from 'react';
import { Form, WppGrid, WppDivider } from 'buildingBlocks';
import { StrategyType, User, Member, Brand } from 'utils/types';
import NavFooter from 'containers/StrategyWizard/components/NavFooter';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { wppBodyContainer } from 'components/PageTemplate/style';
import ModuleHeader from './Header';

const { divider } = strategyWizardStyles;

type Props = {
  strategyType: StrategyType
  strategyId: number
  user: User
  member: Member
  hasBudgetAllocationError: boolean
  brand?: Brand
  disabled?: boolean
  loading?: boolean
  onSubmit?: () => void
  onBackClick?: () => void
  children?: React.ReactNode
};

const ModuleForm = (props: Props) => {
  const { onSubmit, onBackClick, strategyType, strategyId, loading, disabled, hasBudgetAllocationError } = props;

  return (
    <Form
      noValidate
      loading={loading}
    >
      <div style={wppBodyContainer}>
        <WppGrid container fullWidth item all={24}>
          <WppGrid container item all={24}>
            <ModuleHeader {...strategyType} />
          </WppGrid>
          {hasBudgetAllocationError ? null : (<WppDivider style={divider} />)}
          <WppGrid container item all={24}>
            {props.children}
          </WppGrid>
        </WppGrid>
      </div>
      <NavFooter
        backButtonText="Back"
        onBackClick={onBackClick}
        showBackButton
        nextButtonText={strategyId ? 'Update' : 'Next'}
        nextButtonDisabled={disabled}
        onNextClick={onSubmit}
        strategyId={strategyId}
      />
    </Form>
  );
};

export default ModuleForm;
