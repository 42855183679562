import _ from 'lodash';
import { GOAL_TYPES, GoalSuccessEvent, FLIGHT_EXTERNAL_TYPE, RevenueType, IMG_ICONS_16_BASE_PATH } from 'constantsBase';
import { OptimizationDirection } from 'containers/StrategyWizard/types';
import { COPILOT_COLORS } from 'globalStyles';
import { Permission } from 'utils/featureFlags';
import { GoalType } from 'utils/types';

const {
  NEW_DESIGN_SYSTEM: { BLUES, REDS, PINKS, YELLOWS, GREENS, TEALS, NEUTRALS },
} = COPILOT_COLORS;

export const GOAL_TYPES_FOR_STRATEGY_WIZARD = _.omitBy(GOAL_TYPES, (_v, k) => _.includes([GOAL_TYPES.ivrImps.value, GOAL_TYPES.exposureTime.value], k)) as { [key: string]: GoalType };

export const SUCCESS_EVENT_COLOR_MAPPING = {
  [GoalSuccessEvent.audioListens]: REDS.R700_VALENTINE,
  [GoalSuccessEvent.clicks]: NEUTRALS.N800_MATTER,
  [GoalSuccessEvent.conversions]: BLUES.B500_WAVE,
  [GoalSuccessEvent.custom]: TEALS.T500_KEPPEL,
  [GoalSuccessEvent.impressions]: PINKS.P500_MAX,
  [GoalSuccessEvent.impValue]: YELLOWS.Y500_GOLD,
  [GoalSuccessEvent.impViews]: TEALS.T300_DATA,
  [GoalSuccessEvent.videoViews]: GREENS.G700_SLIME,
  [GoalSuccessEvent.cost]: NEUTRALS.N800_MATTER,
};

const {
  cpc,
  ctr,
  conversionRate,
  cpa,
  cpcv,
  completionRate,
  ivrMeasured,
  cpm,
  vcpm,
  vcpcv,
  engagementScore,
  conversionRevenue,
  impValuePerCost,
  impValuePerImp,
  cpvYoutube,
  vcrYoutube,
  cyodCostPerSuccessEvent,
  cyodRateOfSuccessEvent,
  awgCreateYourOwn,
  impactOutcome,
  ncsNbCpa,
  ncsNbCvr,
  ncsNbEngagementScore,
  ncsNbRoas,
} = GOAL_TYPES_FOR_STRATEGY_WIZARD;

export const FLIGHT_EXTERNAL_ID_TO_GOAL_TYPES = {
  [FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id]: {
    ctr,
    cpc,
    conversionRate,
    completionRate,
    cpa,
    cpcv,
    vcpcv,
    cpm,
    vcpm,
    awgCreateYourOwn,
    impactOutcome,
    engagementScore,
    conversionRevenue,
    cyodCostPerSuccessEvent,
    cyodRateOfSuccessEvent,
  },
  [FLIGHT_EXTERNAL_TYPE.apnLineItem.id]: {
    ctr,
    cpa,
    cpc,
    conversionRate,
    cpcv,
    completionRate,
    ivrMeasured,
    vcpm,
    conversionRevenue,
    engagementScore,
  },
  [FLIGHT_EXTERNAL_TYPE.ttdCampaign.id]: {
    cpc,
    ctr,
    conversionRate,
    cpa,
    completionRate,
    cpcv,
    vcpcv,
    cpm,
    vcpm,
    conversionRevenue,
    engagementScore,
    cyodCostPerSuccessEvent,
    cyodRateOfSuccessEvent,
    ncsNbCpa,
    ncsNbCvr,
    ncsNbEngagementScore,
    ncsNbRoas,
    awgCreateYourOwn,
    impactOutcome,
  },
  [FLIGHT_EXTERNAL_TYPE.ttdMegagon.id]: {
    ctr,
    cpa,
    cpc,
    conversionRate,
    cpcv,
    completionRate,
    ivrMeasured,
    vcpm,
  },
  [FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id]: {
    ctr,
    cpc,
    conversionRate,
    cpa,
    completionRate,
    cpcv,
    vcpcv,
    cpm,
    vcpm,
    conversionRevenue,
    engagementScore,
    impValuePerCost,
    impValuePerImp,
    cpvYoutube,
    vcrYoutube,
    cyodCostPerSuccessEvent,
    cyodRateOfSuccessEvent,
    awgCreateYourOwn,
    impactOutcome,
  },
  [FLIGHT_EXTERNAL_TYPE.dbmLineItem.id]: {
    cpa,
    cpc,
    conversionRate,
    ctr,
    cpcv,
    completionRate,
    cpm,
    vcpcv,
  },
  [FLIGHT_EXTERNAL_TYPE.amznCampaign.id]: {
    cpa,
    cpc,
    ctr,
    conversionRate,
    cpcv,
    completionRate,
    vcpm,
    cpm,
    vcpcv,
    conversionRevenue,
    engagementScore,
    awgCreateYourOwn,
    cyodCostPerSuccessEvent,
    cyodRateOfSuccessEvent,
  },
  [FLIGHT_EXTERNAL_TYPE.wmtCampaign.id]: {
    cpc,
    ctr,
    conversionRate,
    cpa,
    completionRate,
    cpcv,
    vcpcv,
    cpm,
    vcpm,
    conversionRevenue,
    engagementScore,
    impValuePerCost,
    impValuePerImp,
    cyodCostPerSuccessEvent,
    cyodRateOfSuccessEvent,
    awgCreateYourOwn,
    impactOutcome,
  },
  [FLIGHT_EXTERNAL_TYPE.wmtMegagon.id]: {
    ctr,
    cpa,
    cpc,
    conversionRate,
    cpcv,
    completionRate,
    ivrMeasured,
    vcpm,
  },
};

export const CROSS_PLATFORM_OPT_GOAL_TYPES = {
  cpa,
  cpc,
  conversionRate,
  cpcv,
  completionRate,
  conversionRevenue,
  cpm,
  ctr,
  engagementScore,
  vcpcv,
  vcpm,
  awgCreateYourOwn,
  impactOutcome,
  cyodCostPerSuccessEvent,
  cyodRateOfSuccessEvent,
};

export const CONVERSION_BASED_GOALS = [
  GOAL_TYPES.cpa.value,
  GOAL_TYPES.conversionRate.value,
  GOAL_TYPES.conversionRevenue.value,
  GOAL_TYPES.engagementScore.value,
  GOAL_TYPES.ncsNbCpa.value,
  GOAL_TYPES.ncsNbCvr.value,
  GOAL_TYPES.ncsNbEngagementScore.value,
  GOAL_TYPES.ncsNbRoas.value,
];

export const REVENUE_TYPE_GOAL_TYPES = [
  GOAL_TYPES.netCpc.value,
  GOAL_TYPES.netCpcv.value,
  GOAL_TYPES.netCpm.value,
  GOAL_TYPES.margin.value,
];

export const REVENUE_TYPE_OPTIONS = [
  { value: RevenueType.cpc, text: 'CPC' },
  { value: RevenueType.cpcv, text: 'CPCV' },
  { value: RevenueType.cpm, text: 'CPM' },
];

export const GOAL_TYPES_TO_PERMISSIONS = {
  [GOAL_TYPES.conversionRevenue.value]: {
    [FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id]: [Permission.xndrBudgetOptCustomGoals],
    [FLIGHT_EXTERNAL_TYPE.ttdCampaign.id]: [Permission.ttdBudgetOptCustomGoals],
    [FLIGHT_EXTERNAL_TYPE.amznCampaign.id]: [Permission.amznBudgetOptConvGoals],
  },
  [GOAL_TYPES.engagementScore.value]: {
    [FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id]: [Permission.xndrBudgetOptCustomGoals],
    [FLIGHT_EXTERNAL_TYPE.ttdCampaign.id]: [Permission.ttdBudgetOptCustomGoals],
    [FLIGHT_EXTERNAL_TYPE.amznCampaign.id]: [Permission.amznBudgetOptConvGoals],
  },
  [GOAL_TYPES.vcpm.value]: {
    [FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id]: [Permission.vCpmXndrBudgetOpt],
    [FLIGHT_EXTERNAL_TYPE.ttdCampaign.id]: [Permission.vCpmTtdBudgetOpt],
    [FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id]: [Permission.vCpmDv360BudgetOpt],
  },
  [GOAL_TYPES.cpvYoutube.value]: {
    [FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id]: [Permission.dbmYoutubeGoals],
  },
  [GOAL_TYPES.vcrYoutube.value]: {
    [FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id]: [Permission.dbmYoutubeGoals],
  },
  [GOAL_TYPES.impValuePerImp.value]: {
    [FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id]: [Permission.dbmImpValuePerImp],
  },
  [GOAL_TYPES.impValuePerCost.value]: {
    [FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id]: [Permission.dbmImpValuePerCost],
  },
  [GOAL_TYPES.cyodCostPerSuccessEvent.value]: {
    [FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id]: [Permission.cyodGoalType],
    [FLIGHT_EXTERNAL_TYPE.ttdCampaign.id]: [Permission.cyodGoalType],
    [FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id]: [Permission.cyodGoalType],
    [FLIGHT_EXTERNAL_TYPE.amznCampaign.id]: [Permission.amznBudgetOptConvGoals],
  },
  [GOAL_TYPES.cyodRateOfSuccessEvent.value]: {
    [FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id]: [Permission.cyodGoalType],
    [FLIGHT_EXTERNAL_TYPE.ttdCampaign.id]: [Permission.cyodGoalType],
    [FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id]: [Permission.cyodGoalType],
    [FLIGHT_EXTERNAL_TYPE.amznCampaign.id]: [Permission.amznBudgetOptConvGoals],
  },
  [GOAL_TYPES.cpa.value]: {
    [FLIGHT_EXTERNAL_TYPE.amznCampaign.id]: [Permission.amznBudgetOptConvGoals],
  },
  [GOAL_TYPES.conversionRate.value]: {
    [FLIGHT_EXTERNAL_TYPE.amznCampaign.id]: [Permission.amznBudgetOptConvGoals],
  },
  [GOAL_TYPES.awgCreateYourOwn.value]: {
    [FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id]: [Permission.awgGoalType],
    [FLIGHT_EXTERNAL_TYPE.ttdCampaign.id]: [Permission.awgGoalType],
    [FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id]: [Permission.awgGoalType],
    [FLIGHT_EXTERNAL_TYPE.amznCampaign.id]: [Permission.amznBudgetOptConvGoals],
  },
  [GOAL_TYPES.impactOutcome.value]: {
    [FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id]: [Permission.impactOutcomeGoalType],
    [FLIGHT_EXTERNAL_TYPE.ttdCampaign.id]: [Permission.impactOutcomeGoalType],
    [FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id]: [Permission.impactOutcomeGoalType],
  },
  [GOAL_TYPES.ncsNbCpa.value]: {
    [FLIGHT_EXTERNAL_TYPE.ttdCampaign.id]: [Permission.ncsNewBuyerGoalType],
  },
  [GOAL_TYPES.ncsNbCvr.value]: {
    [FLIGHT_EXTERNAL_TYPE.ttdCampaign.id]: [Permission.ncsNewBuyerGoalType],
  },
  [GOAL_TYPES.ncsNbEngagementScore.value]: {
    [FLIGHT_EXTERNAL_TYPE.ttdCampaign.id]: [Permission.ncsNewBuyerGoalType],
  },
  [GOAL_TYPES.ncsNbRoas.value]: {
    [FLIGHT_EXTERNAL_TYPE.ttdCampaign.id]: [Permission.ncsNewBuyerGoalType],
  },
};

export enum BudgetStatus {
  GREATER_THAN_DELIVERY = 'GreaterThanDelivery',
  LESS_THAN_DELIVERY = 'LessThanDelivery',
}

export enum ErrorElement {
  BUDGET = 'Budget',
  ERROR_MESSAGE = 'ErrorMessage',
}

export enum GoalTypeSearchCriteria {
  standard = 'standard',
  advanced = 'advanced',
}

export const NEW_GOAL_SRC = '/img/components/StrategyWizard/new-goal.svg';

export const CYOD_GOAL_DESCRIPTIONS = {
  [GOAL_TYPES.cyodCostPerSuccessEvent.value]: 'My goal is calculated as the Revenue divided by the Success Events, i.e., a cost-based goal and I want to minimize it.',
  [GOAL_TYPES.cyodRateOfSuccessEvent.value]: 'My goal is calculated as the Success Events divided by total impressions, i.e. a rate-based goal and I want to maximise it.',
};

export const ARROW_DOWN_RIGHT_SRC = '/img/components/StrategyWizard/right-and-down.png';
export const ARROW_UP_RIGHT_SRC = '/img/components/StrategyWizard/right-and-up.png';
export const CYOD_UPLOAD_ICON_SRC = '/img/components/StrategyWizard/cyod-upload.svg';

export const BLUE_FORMULA_ICON_SRC = `${IMG_ICONS_16_BASE_PATH}/blue-formula.svg`;
export const GREEN_FORMULA_ICON_SRC = `${IMG_ICONS_16_BASE_PATH}/green-formula.svg`;
export const GREY_FORMULA_ICON_SRC = `${IMG_ICONS_16_BASE_PATH}/grey-formula.svg`;
export const INFO_ICON_SRC = `${IMG_ICONS_16_BASE_PATH}/infocircle.svg`;
export const REVENUE_SEARCH_ICON = `${IMG_ICONS_16_BASE_PATH}/revenue-search-icon.svg`;
export const ARROW_DOWN = `${IMG_ICONS_16_BASE_PATH}/arrow-down.svg`;
export const ARROW_RIGHT = `${IMG_ICONS_16_BASE_PATH}/arrow-right.svg`;

export const DSP_HEADER_TOP = 31;

export type RevTypeOption = {
  value: string,
  text: string,
};

export type ChangeHandler = (value: string) => void;
export const TEAL_INFO_CIRCLE_SRC = `${IMG_ICONS_16_BASE_PATH}/infocircle-teal.svg`;
export const RED_INFO_CIRCLE_SRC = `${IMG_ICONS_16_BASE_PATH}/red-info-circle.svg`;
export const GOAL_ICON_SRC = `${IMG_ICONS_16_BASE_PATH}/goal-icon.svg`;
export const BULLSEYE_SRC = `${IMG_ICONS_16_BASE_PATH}/bullseye.svg`;
export const BLACK_INFO_CIRCLE_SRC = `${IMG_ICONS_16_BASE_PATH}/black-info-circle.svg`;
export const ANGLE_LEFT = `${IMG_ICONS_16_BASE_PATH}/angle-left.svg`;
export const DROP_ZONE_IMG = `${IMG_ICONS_16_BASE_PATH}/drop-zone-image.svg`;
export const CURSOR_POINTER = `${IMG_ICONS_16_BASE_PATH}/cursor-pointer.svg`;
export const BLACK_CIRCLE_X = `${IMG_ICONS_16_BASE_PATH}/black-circle-x.svg`;
export const CURVED_LEFT_ARROW = `${IMG_ICONS_16_BASE_PATH}/curved-left-arrow.svg`;
export const DISABLED_CURVED_LEFT_ARROW = `${IMG_ICONS_16_BASE_PATH}/disabled-curved-left-arrow.svg`;
export const CURVED_RIGHT_ARROW = `${IMG_ICONS_16_BASE_PATH}/curved-right-arrow.svg`;
export const DISABLED_CURVED_RIGHT_ARROW = `${IMG_ICONS_16_BASE_PATH}/disabled-curved-right-arrow.svg`;
export const DELETE_ICON = `${IMG_ICONS_16_BASE_PATH}/delete-icon.svg`;
export const DISABLED_DELETE_ICON = `${IMG_ICONS_16_BASE_PATH}/disabled-delete-icon.svg`;
export const IMPORT_FILE_IMG = `${IMG_ICONS_16_BASE_PATH}/import-file.svg`;
export const MODAL_CLOSE_IMG = `${IMG_ICONS_16_BASE_PATH}/close.svg`;

export const MODAL_ERROR_MSG = 'Enter a minimum value of 0.';
export const AWG_FIELDS_TO_EXCLUDE = ['customGoal', 'metricsConfig'];

export const OPTIMIZATION_DIRECTION_TEXT = {
  [OptimizationDirection.up]: { displayName: 'Maximize Goal Value', desc: 'Copilot will aim to increase the goal value, useful for rate-based goals.' },
  [OptimizationDirection.down]: { displayName: 'Minimize Goal Value', desc: 'Copilot will aim to decrease the goal value, useful for cost-based goals.' },
};

export enum DragDropTypes {
  operator = 'operator',
  metric = 'metric',
  droppedOperator = 'dropped-operator',
  droppedMetric = 'dropped-metric',
}

export enum GrabFormulaFilters {
  users = 'users',
  copilot = 'copilot',
}

export const GRAB_FORMULA_GOALS = [GOAL_TYPES.cpa.value, GOAL_TYPES.conversionRate.value, GOAL_TYPES.conversionRevenue.value, GOAL_TYPES.engagementScore.value,
  GOAL_TYPES.completionRate.value, GOAL_TYPES.cpc.value, GOAL_TYPES.cpcv.value, GOAL_TYPES.ctr.value, GOAL_TYPES.cpm.value, GOAL_TYPES.vcpm.value,
  GOAL_TYPES.ivrImps.value, GOAL_TYPES.ivrMeasured.value, GOAL_TYPES.vcpcv.value, GOAL_TYPES.cpvYoutube.value, GOAL_TYPES.vcrYoutube.value,
  GOAL_TYPES.margin.value, GOAL_TYPES.impactOutcome.value, GOAL_TYPES.cyodCostPerSuccessEvent.value, GOAL_TYPES.cyodRateOfSuccessEvent.value];

export const ALLOWED_DRAG_DROP_TYPES = [DragDropTypes.operator, DragDropTypes.metric, DragDropTypes.droppedMetric, DragDropTypes.droppedOperator];

export const FORMULA_BUILDER_ERROR_MESSAGES = {
  START_WITH_OPERATOR: "Your formula can't start with a math operator. Please start with a number or metric.",
  END_WITH_OPERATOR: "Your formula can't end with a math operator. Make sure to end it with a number or metric.",
  DIVISION_BY_ZERO: "Division by zero isn't possible. Please adjust your formula.",
  CONSECUTIVE_OPERATORS: 'Looks like you have consecutive math operators. Fix that by placing numbers or metrics between them.',
  OPERATOR_AFTER_OPEN_PARENTHESIS: "Your formula has a math operator right after '('. That's not allowed. Try putting a number or metric first.",
  OPERATOR_BEFORE_CLOSING_PARENTHESIS: "Your formula has a math operator right before ')'. That's not allowed. Try putting a number or metric first.",
  EMPTY_PARENTHESES: "An empty set of parentheses '()' isn't allowed in your formula. Make sure to fill them with appropriate content or remove them.",
  UNBALANCED_PARENTHESES: 'Your formula has unbalanced parentheses. Make sure each opening parenthesis has a closing one.',
  CONSECUTIVE_METRICS: 'It seems you have consecutive metrics. They need to be separated by an operator.',
  CONSECUTIVE_NUMBERS: "Consecutive numbers aren't allowed. Please insert an operator between them.",
  NOT_ENOUGH_METRICS: 'For your formula to work, it needs at least 2 metrics. Please add another one.',
  START_WITH_CLOSING_PARENTHESIS: "The first element in your formula can't be an operator. It should be a number or metric.",
  EXCEED_CHARACTER_LIMIT: 'Your formula is too long. Please shorten it to 255 characters or less.',
  MISSING_OPERATOR: 'Your formula must have a math operator between metrics, numbers, and parentheses.',
};

export const CHARACTER_LIMIT = 255;

export const FORMULA_STARTING_ELEMENTS_PER_PAGE = 10;
export const GRAB_FORMULA_PER_PAGE_OPTIONS = [10, 25, 50, 100];

export const GRAB_FORMULA_INIT_STATE = {
  limit: FORMULA_STARTING_ELEMENTS_PER_PAGE,
  startingPage: 1,
  skip: 0,
};

export const GOAL_BUILDER_CONFIRMATION_TEXT = 'The information entered in the Formula Builder will be cleared. This cannot be undone.';

export const EXTERNAL_MEASUREMENT = 'External Measurement';
