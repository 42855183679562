import _ from 'lodash';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { WppActionButton, WppCard, WppIconEdit, WppIconTrash, WppTag, WppTypography } from 'buildingBlocks';
import { AWGDimensions } from 'constantsBase';
import { AWG_WEIGHTED_CARDS } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { getDimensionText } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { MetricConfig, MetricConfigObj } from 'containers/StrategyWizard/types';
import { getDropzoneContent } from 'containers/StrategyWizard/utils';
import { useWeightsSectionContext } from '../contexts/WeightsSectionProvider';
import { useAWGContext } from '../contexts/AWGProvider';
import WeightsDeleteConfirmModal from './WeightsDeleteConfirmModal';

const { card, content, contentInfo, contentDesc, icons, grid } = AWG_WEIGHTED_CARDS;

type WeightedMetricProps = {
  metric: MetricConfigObj
};

const WeightedMetric = ({ metric }: WeightedMetricProps) => {
  const { isCampaignOptType, formulaMetrics, setFormulaMetrics } = useAWGContext();
  const { handleEditWeightedMetric } = useWeightsSectionContext();
  const { setValue } = useFormContext();
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleWeightDelete = () => {
    const updatedMetricsConfig = {
      ...formulaMetrics,
      [metric.name]: {
        ...formulaMetrics[metric.name],
        dimension: isCampaignOptType ? AWGDimensions.lineItem : AWGDimensions.insertionOrder,
        isWeighted: false,
        weighting: {},
      },
    };
    setFormulaMetrics(updatedMetricsConfig);
    setValue('metricsConfig', updatedMetricsConfig);
  };

  const confirmDelete = () => {
    setShowModal(true);
  };

  return (
    <>
      <WppCard style={card} className="weightMetricsCard">
        <div style={content}>
          <div style={contentInfo}>
            <WppTypography type="m-strong" tag="p">{getDropzoneContent(metric.name)}</WppTypography>
            <div style={contentDesc}>
              <WppTypography type="xs-body" tag="p">Applied Dimension <WppTag label={`${_.capitalize(getDimensionText(metric.dimension))}s`} variant="neutral" /></WppTypography>
            </div>
          </div>
          <div style={icons}>
            <WppActionButton type="button" variant="secondary" onClick={() => handleEditWeightedMetric(metric)}>
              <WppIconEdit color="var(--wpp-grey-color-800)" />
            </WppActionButton>
            <WppActionButton type="button" variant="secondary" onClick={confirmDelete}>
              <WppIconTrash />
            </WppActionButton>
          </div>
        </div>
      </WppCard>
      <WeightsDeleteConfirmModal
        metricToDelete={metric.name}
        showModal={showModal}
        setShowModal={setShowModal}
        handleWeightDelete={handleWeightDelete}
      />
    </>
  );
};

type WeightedMetricsProps = {
  weightedMetrics: MetricConfig
};

const WeightedMetrics = ({ weightedMetrics }: WeightedMetricsProps) => {
  const numOfWeightedMetrics = _.size(weightedMetrics);
  const numOfRows = numOfWeightedMetrics ? _.ceil(numOfWeightedMetrics / 2) : 1;
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ ...grid, gridTemplateRows: `repeat(${numOfRows}, 1fr)` }}>
        {_.map(_.sortBy(weightedMetrics, 'name'), (metricConfig, metricName: string) => (
          <WeightedMetric key={metricName} metric={metricConfig} />
        ))}
      </div>
    </div>
  );
};

export default WeightedMetrics;
