import { GoalSuccessEvent } from 'constantsBase';
import { ReactNode } from 'react';

export type GoalSectionProviderProps = {
  children: ReactNode
};

export enum GoalSectionActionTypes {
  UPDATE_REVENUE_TYPE_ENABLED = 'UPDATE_REVENUE_TYPE_ENABLED',
  UPDATE_REV_TYPE_SEARCH_STR = 'UPDATE_REV_TYPE_SEARCH_STR',
  UPDATE_SUCCESS_EVENT_FILTER = 'UPDATE_SUCCESS_EVENT_FILTER',
}

export type GoalSectionState = {
  revenueTypeEnabled: boolean
  revTypeSearchStr: string
  successEventFilter: GoalSuccessEvent | string
};

export type GoalSectionAction = { type: GoalSectionActionTypes.UPDATE_REVENUE_TYPE_ENABLED, payload: boolean }
| { type: GoalSectionActionTypes.UPDATE_REV_TYPE_SEARCH_STR, payload: string }
| { type: GoalSectionActionTypes.UPDATE_SUCCESS_EVENT_FILTER, payload: GoalSuccessEvent | 'all' };
