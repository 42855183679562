import { DSP, ALGORITHM_TYPE } from 'constantsBase';

export const MODEL_TYPES = {
  advertiser: { text: 'Advertiser', value: 'advertiser' },
  insertionOrder: { text: 'Insertion Order', value: 'insertionOrder' },
  lineItem: { text: 'Line Item', value: 'lineItem' },
  seat: { text: 'Seat', value: 'seat' },
  order: { text: 'Order', value: 'order' },
};

export const TREE_TYPES = {
  vcpm: {
    text: 'Bid vCPM',
    value: 'vcpm',
    modelOutput: 'bid',
    message: `Bids only on placements where predicted viewability is above Min.
              Threshold and scales the Target vCPM by this prediction.`,
  },
  dynamicMultiplier: {
    text: 'Modifier: Dynamic Multiplier',
    value: 'dynamicMultiplier',
    modelOutput: 'bidModifier',
    message: `MUST be used in conjunction with Xandr Optimization (CPC, CPA).
              Only allows bids on placements where predicted viewability is above Min. Threshold,
              and multiplies Xandr suggested bid by how much predicted viewability exceeds the Min. Threshold.`,
  },
  filterOnThreshold: {
    text: 'Modifier: Filter on Threshold',
    value: 'filterOnThreshold',
    modelOutput: 'bidModifier',
    message: `MUST be used in conjunction with Xandr Optimization (CPC, CPA).
              Only allows bids on placements where predicted viewability is above Min. Threshold.`,
  },
};

export const DSP_TO_TARGET_PLUS_ALGO_ID = {
  [DSP.APN.id]: ALGORITHM_TYPE.xndrTargetingPlus.id,
  [DSP.DBM.id]: ALGORITHM_TYPE.dbmTargetingPlus.id,
  [DSP.TTD.id]: ALGORITHM_TYPE.ttdTargetingPlus.id,
  [DSP.WALMART.id]: ALGORITHM_TYPE.wmtTargetingPlus.id,
};

export const EDITOR_INPUT_DEFAULT_VALUE = 'Enter Code Here';
