import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { WppModal, WppTypography, WppButton } from 'buildingBlocks';
import { CONFIRMATION_MODAL } from 'containers/StrategyWizard/steps/GoalSelection/styles';

const { modalButtonStyle } = CONFIRMATION_MODAL;

type WeightsDeleteModalProps = {
  metricToDelete: string
  setShowModal: Dispatch<SetStateAction<boolean>>
  showModal: boolean
  handleWeightDelete: () => void
};

const WeightsDeleteConfirmModal = ({ metricToDelete, setShowModal, showModal, handleWeightDelete }: WeightsDeleteModalProps) => {
  const handleDelete = () => {
    handleWeightDelete();
    setShowModal(false);
  };

  return (
    <WppModal open={showModal}>
      <WppTypography slot="header" tag="p" type="xl-heading">Removing Weights</WppTypography>
      <div slot="body">
        <WppTypography tag="p" type="s-body">{`Are you sure you want to remove weights from ${_.startCase(metricToDelete)}?`}</WppTypography>
        <WppTypography tag="p" type="s-body">This action cannot be undone.</WppTypography>
      </div>
      <div slot="actions" style={modalButtonStyle}>
        <WppButton variant="secondary" size="s" onClick={() => setShowModal(false)}>Cancel</WppButton>
        <WppButton variant="destructive" size="s" onClick={handleDelete}>Remove Weights</WppButton>
      </div>
    </WppModal>
  );
};

export default WeightsDeleteConfirmModal;
