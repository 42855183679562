import _ from 'lodash';
import React, { CSSProperties, Dispatch, SetStateAction } from 'react';
import { Map as ImmutableMap } from 'immutable';
import { AWGDimensions, DSP } from 'constantsBase';
import { WeightObj } from 'containers/StrategyWizard/types';
import { getDimensionText } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import WeightsList from './WeightsList';
import { useWeightsSectionContext } from '../contexts/WeightsSectionProvider';

type DspAccordionProps = {
  dspId: string
  flights: WeightObj
  headerStyles: CSSProperties
  flightsToDisplay: ImmutableMap<string, WeightObj>
  setFlightsToDisplay: Dispatch<SetStateAction<ImmutableMap<string, WeightObj>>>
};

const DspAccordion = (props: DspAccordionProps) => {
  const { dspId, flights, flightsToDisplay, setFlightsToDisplay } = props;
  const { selectedDimension } = useWeightsSectionContext();
  const dspCode = DSP.getById(dspId).code;
  const noFlightsFoundMsgText = selectedDimension === AWGDimensions.pixel && _.isString(flights)
    ? <span> Error loading {dspCode} Pixels</span>
    : <span>No {getDimensionText(selectedDimension)}s found.</span>;
  return (
    <>
      {
        _.size(flights)
          ? (
            <WeightsList
              dspId={dspId}
              weightsList={flights}
              flightsToDisplay={flightsToDisplay}
              setFlightsToDisplay={setFlightsToDisplay}
            />
          )
          : noFlightsFoundMsgText
      }
    </>
  );
};

export default DspAccordion;
