// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.audioListens {
  background-color: rgba(133, 163, 255, 0.1333333333);
}
.audioListens::part(label) {
  color: #0A2FFF;
}

.clicks {
  background-color: rgba(122, 182, 255, 0.1333333333);
}
.clicks::part(label) {
  color: #0068AD;
}

.conversions {
  background-color: rgba(69, 228, 182, 0.1333333333);
}
.conversions::part(label) {
  color: #077E5A;
}

.custom {
  background-color: rgba(108, 224, 123, 0.1333333333);
}
.custom::part(label) {
  color: #12871C;
}

.cost {
  background-color: rgba(236, 199, 6, 0.1333333333);
}
.cost::part(label) {
  color: #A36F05;
}

.impressions {
  background-color: rgba(255, 158, 102, 0.1333333333);
}
.impressions::part(label) {
  color: #CC4B00;
}

.impValue {
  background-color: rgba(255, 122, 145, 0.1333333333);
}
.impValue::part(label) {
  color: #D11534;
}

.impViews {
  background-color: rgba(237, 120, 198, 0.1333333333);
}
.impViews::part(label) {
  color: #B41880;
}

.videoViews {
  background-color: rgba(180, 130, 243, 0.1333333333);
}
.videoViews::part(label) {
  color: #832EEA;
}`, "",{"version":3,"sources":["webpack://./src/containers/StrategyWizard/steps/GoalSelection/GoalTag/style.scss"],"names":[],"mappings":"AAOE;EANE,mDAOsB;AAN1B;AAAI;EACE,cAKW;AAHjB;;AAME;EAVE,mDAWsB;AAH1B;AAPI;EACE,cASW;AAAjB;;AAGE;EAdE,kDAesB;AAA1B;AAdI;EACE,cAaW;AAGjB;;AAAE;EAlBE,mDAmBsB;AAG1B;AArBI;EACE,cAiBW;AAMjB;;AAHE;EAtBE,iDAuBsB;AAM1B;AA5BI;EACE,cAqBW;AASjB;;AANE;EA1BE,mDA2BsB;AAS1B;AAnCI;EACE,cAyBW;AAYjB;;AATE;EA9BE,mDA+BsB;AAY1B;AA1CI;EACE,cA6BW;AAejB;;AAZE;EAlCE,mDAmCsB;AAe1B;AAjDI;EACE,cAiCW;AAkBjB;;AAfE;EAtCE,mDAuCsB;AAkB1B;AAxDI;EACE,cAqCW;AAqBjB","sourcesContent":["@mixin tag($color, $bg-color){\n    background-color: $bg-color;\n    &::part(label){\n      color: $color;\n    }\n  }\n  \n  .audioListens{\n    @include tag(#0A2FFF, #85A3FF22);\n  }\n  \n  .clicks{\n    @include tag(#0068AD, #7AB6FF22);\n  }\n  \n  .conversions{\n    @include tag(#077E5A, #45E4B622);\n  }\n  \n  .custom{\n    @include tag(#12871C, #6CE07B22);\n  }\n  \n  .cost{\n    @include tag(#A36F05, #ECC70622);\n  }\n  \n  .impressions{\n    @include tag(#CC4B00, #FF9E6622);\n  }\n  \n  .impValue{\n    @include tag(#D11534, #FF7A9122);\n  }\n  \n  .impViews{\n    @include tag(#B41880, #ED78C622);\n  }\n  \n  .videoViews{\n    @include tag(#832EEA, #B482F322);\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
