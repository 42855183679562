import React from 'react';
import { STRATEGY_CONFIRMATION_STYLES } from '../styles';

const InfoCircleIcon = () => (
  <span>
    <img alt="info circle" src="/img/icons/16x16/infocircle3.png" style={STRATEGY_CONFIRMATION_STYLES.infoCircle} />
  </span>
);

export default InfoCircleIcon;
