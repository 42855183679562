/* eslint-disable react/jsx-no-target-blank */
import _ from 'lodash';
import React, { Component, ReactNode, CSSProperties } from 'react';
import Markdown from 'react-markdown-renderer';
import { TooltipThemeTypes } from '@wppopen/components-library';
import { TOOLTIPS } from 'constantsBase';
import { WppActionButton, WppIconInfo, WppTooltip, WppTypography, WppIconHelp } from 'buildingBlocks';
import { COPILOT_LAYOUT, COPILOT_COLORS } from 'globalStyles';
import { toSlug } from 'utils/formattingUtils';

const { SPACING } = COPILOT_LAYOUT;

type Props = {
  content?: string | ReactNode
  group?: string
  name?: string
  showHelpIcon?: boolean
  tooltipstyling?: CSSProperties
  theme?: TooltipThemeTypes
};

const customGroupToWikiMap = {
  Helios: 'Helios',
  'Helios TTD': 'Helios',
};

const createTooltipData = () => (
  _.mapValues(TOOLTIPS, ({ link, content }: { link: string, content: string }) => ({
    content,
    link: `https://copilotsupport.freshdesk.com/support/solutions/articles/${link}`,
  }))
);

export class TooltipComponent extends Component<Props> {
  getWikiName = (group) => _.get(customGroupToWikiMap, group, group);

  getTipKey = () => {
    if (!this.props.name) {
      return undefined;
    }
    const slugName = toSlug(this.props.name);
    return (
      this.props.group
        ? toSlug(`${this.getWikiName(this.props.group)}_${slugName}`)
        : slugName
    );
  };

  renderContent = (key) => {
    const tooltips = createTooltipData();
    if (key && tooltips[key]) {
      const { content, link } = tooltips[key];

      // We open links in new tabs when they are links to our own sites
      return (
        <>
          <Markdown markdown={content} />
          { link
          && (
            <WppTypography>
              <a
                href={link}
                target="_blank"
              >
                <WppActionButton>Read More</WppActionButton>
              </a>
            </WppTypography>
          )}
        </>
      );
    }
    return (<WppTypography style={{ ...(this.props.theme === 'dark' && { color: COPILOT_COLORS.WPP.white0 }) }}>{this.props.content}</WppTypography>);
  };

  render() {
    const key = this.getTipKey();

    return (
      <WppTooltip
        config={{ placement: 'right', allowHTML: true }}
        theme={this.props.theme ? this.props.theme : 'light'}
        style={{ ...this.props.tooltipstyling, marginTop: SPACING[2] }}
      >
        {this.props.showHelpIcon ? <WppIconHelp /> : <WppIconInfo />}
        <div style={{ width: '220px' }} slot="tooltip-content" data-testid="tooltip-text">
          {this.renderContent(key)}
        </div>
      </WppTooltip>
    );
  }
}

export default TooltipComponent;
