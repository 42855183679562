/* eslint-disable no-param-reassign */
import _ from 'lodash';
import moment from 'moment';
import React, { CSSProperties } from 'react';
import { DSP, GoalSuccessEvent, GOAL_TYPES, CYOD_GOAL_TYPES, BusinessModels, AWGDimensions } from 'constantsBase';
import { shouldCreateChildKeys } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/GroupSettings/utils';
import { CROSS_PLATFORM_REV_TYPE_LINK, WIKI_LINK_REV_TYPE } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { BudgetAllocationData, BudgetHierarchy } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { loadDspPixels } from 'containers/StrategyWizard/ConfigurationByStrategyType/utils';
import { NON_SKIPPABLE, TRUE_VIEW, YOUTUBE_SPECIFIC_GOALS } from 'containers/StrategyWizard/constants';
import { BudgetSetting, MetricConfig, MetricConfigObj, RevenueTypeOutcomes, RevenueEditForm, RevenueTypeConfig, PixelsConfig, OptimizationDirection } from 'containers/StrategyWizard/types';
import { hasMultipleExternalTypesAttached, isHigherOrderExternalType } from 'containers/StrategyWizard/utils';
import { COPILOT_COLORS } from 'globalStyles';
import { Goal, StrategyGoals } from 'utils/copilotAPI';
import { checkPermissions, Permission } from 'utils/featureFlags';
import { pluralizer, toSnakeCase } from 'utils/formattingUtils';
import { Flight, GoalType, Member, OptimizationLevelType, User } from 'utils/types';
import {
  FLIGHT_EXTERNAL_ID_TO_GOAL_TYPES, GOAL_TYPES_TO_PERMISSIONS, CROSS_PLATFORM_OPT_GOAL_TYPES, ErrorElement,
  BudgetStatus, GoalTypeSearchCriteria, CONVERSION_BASED_GOALS, GrabFormulaFilters, GRAB_FORMULA_GOALS, REVENUE_TYPE_GOAL_TYPES,
} from './constants';
import { BUDGET_INTERVALS } from './styles';
import { FormulaBuilderAction } from './GoalSection/AWG/contexts/types';

import { FormulaStateType } from './types';

const { NEW_DESIGN_SYSTEM: { YELLOWS, REDS } } = COPILOT_COLORS;
const { greaterThanDeliveryError, lessThanDeliveryError } = BUDGET_INTERVALS;

type SearchCriteria = {
  goalType: GoalTypeSearchCriteria
  revenueTypeEnabled: boolean
  flightExtType: number
  user: User
  isCrossPlatformOptimization: boolean
  isCampaignOptimization: boolean
  hasAmznFlights: boolean
  successEvent?: GoalSuccessEvent | 'all'
  textSearch?: string
};

const getRevTypeSupportedGoals = (isCrossPlatformOptimization: boolean, isCampaignOptimization: boolean) => {
  const revTypeSupportedGoals = isCrossPlatformOptimization || isCampaignOptimization ? {
    [GOAL_TYPES.awgCreateYourOwn.value]: GOAL_TYPES.awgCreateYourOwn,
    [GOAL_TYPES.impactOutcome.value]: GOAL_TYPES.impactOutcome,
  } : {} as { [goalValue: string]: GoalType };
  return { ...revTypeSupportedGoals, [GOAL_TYPES.margin.value]: GOAL_TYPES.margin };
};

const hasAllTrueViewAndZeroNonSkippableLIs = (data) => {
  const currentDate = moment.utc();
  const active = _.filter(data, (d) => d.isEnabled && !(moment(d.endDate).isBefore(currentDate)));
  if (_.isEmpty(active)) {
    return false;
  }
  const validTrueview = _.every(active, (a) => a.lineItemType === TRUE_VIEW && a.trueviewVideoAdFormats !== NON_SKIPPABLE);

  return validTrueview;
};

export const isCYODGoalTypeValue = (value: string) => _.includes(CYOD_GOAL_TYPES, value);
export const isAWGGoalType = (value: string) => _.isEqual(GOAL_TYPES.awgCreateYourOwn.value, value);

export const populateBaseGoalTypes = (
  user: User,
  flightExtType: number,
  dspSpecificChildrenState: BudgetAllocationData,
  optimizationLevel: OptimizationLevelType,
  member: Pick<Member, 'externalId' | 'businessModel'>,
  isCrossPlatformOptimization: boolean,
  hasAmznFlights: boolean = false,
) => {
  if (isCrossPlatformOptimization) {
    // conditionally omit goal types based on amazon.
    const goalTypes = _.omit(CROSS_PLATFORM_OPT_GOAL_TYPES, hasAmznFlights
      ? [GOAL_TYPES.awgCreateYourOwn.value, GOAL_TYPES.impactOutcome.value]
      : []);
    return _.pickBy(_.keyBy(goalTypes, 'value'), (gT: GoalType) => {
      const permissionsNeeded = _.includes(CONVERSION_BASED_GOALS, gT.value)
        ? Permission.crossPlatformOptConvGoals
        : _.get(GOAL_TYPES_TO_PERMISSIONS, [gT.value, flightExtType]);
      if (_.isEmpty(permissionsNeeded)) {
        return true;
      }
      return checkPermissions(user, permissionsNeeded);
    });
  }

  const goalTypesByFlightType = FLIGHT_EXTERNAL_ID_TO_GOAL_TYPES[flightExtType];

  const hasAllTrueViewLIs = hasAllTrueViewAndZeroNonSkippableLIs(dspSpecificChildrenState);
  let goalTypes = goalTypesByFlightType;

  if (isHigherOrderExternalType(_.head(_.get(optimizationLevel, 'externalTypeIds')))) {
    goalTypes = _.filter(_.values(goalTypesByFlightType), (gT: GoalType) => {
      if (_.includes(YOUTUBE_SPECIFIC_GOALS, gT.value)) {
        return hasAllTrueViewLIs;
      }
      return true;
    });
  }

  // filter out CYOD goal types for unbundled clients
  if (_.get(member, 'businessModel') === BusinessModels.unbundled) {
    goalTypes = _.filter(goalTypes, (gT) => !isCYODGoalTypeValue(gT.value));
  }

  return _.pickBy(_.keyBy(goalTypes, 'value'), (gT: GoalType) => {
    const permissionsNeeded = _.get(GOAL_TYPES_TO_PERMISSIONS, [gT.value, flightExtType]);
    if (_.isEmpty(permissionsNeeded)) {
      return true;
    }
    return checkPermissions(user, permissionsNeeded); // pass in member!
  });
};

// search by revenueType OR text (goalType (standard or custom) AND successEvent) OR textSearch
export const filterGoals = (baseGoalTypes: { [key: string]: GoalType }, { goalType, successEvent, textSearch, revenueTypeEnabled, flightExtType, user, isCrossPlatformOptimization, isCampaignOptimization, hasAmznFlights }: SearchCriteria) => {
  let filteredGoals = _.omit(baseGoalTypes, REVENUE_TYPE_GOAL_TYPES);
  if (goalType === GoalTypeSearchCriteria.advanced && hasAmznFlights) {
    return _.pick(filteredGoals, [GOAL_TYPES.awgCreateYourOwn.value, GOAL_TYPES.engagementScore.value]);
  }
  if (revenueTypeEnabled) {
    const revTypeSupportedGoals = getRevTypeSupportedGoals(isCrossPlatformOptimization, isCampaignOptimization);
    filteredGoals = _.pickBy(_.keyBy(revTypeSupportedGoals, 'value'), (gT: GoalType) => {
      const permissionsNeeded = _.get(GOAL_TYPES_TO_PERMISSIONS, [gT.value, flightExtType]);
      if (_.isEmpty(permissionsNeeded)) {
        return true;
      }
      return checkPermissions(user, permissionsNeeded);
    });
  }
  filteredGoals = _.pickBy(filteredGoals, (g: GoalType) => {
    const goalTypeMatches = (goalType === GoalTypeSearchCriteria.advanced ? g.isCustom : !g.isCustom);

    // if successEvent is defined, then search by that filter & the goal type filter
    if (successEvent) {
      const successEventMatches = (successEvent === 'all') ? true : (g.successEvent === successEvent);
      return goalTypeMatches && successEventMatches;
    }

    // otherwise, search by text from searchbar & goal type filter
    if (!_.isEmpty(textSearch)) {
      const textMatches = (
        // search by abbreviation AND long text
        _.includes(_.toLower(g.strategyWizardAbbreviation), _.toLower(textSearch))
        || _.includes(_.toLower(g.strategyWizardLongText), _.toLower(textSearch))
      );
      return textMatches;
    }

    return goalTypeMatches;
  });
  return filteredGoals;
};

export const GOAL_TYPE_TO_UNIT = {
  [GOAL_TYPES.cpc.value]: pluralizer('Click', 'Clicks'),
  [GOAL_TYPES.cpcv.value]: pluralizer('Video Complete', 'Video Completes'),
  [GOAL_TYPES.cpm.value]: pluralizer('Impression', 'Impressions'),
};

export const getRevenueTypePluralizer = (primaryGoalType: string) => _.get(GOAL_TYPE_TO_UNIT, primaryGoalType, _.noop);

export const getRevTypeTooltipText = (parentDisplayName: string, goalType: string, isCrossPlatform) => {
  const unit = getRevenueTypePluralizer(goalType)(2);
  const readMore = <a href={isCrossPlatform ? CROSS_PLATFORM_REV_TYPE_LINK : WIKI_LINK_REV_TYPE} target="_blank" rel="noopener noreferrer">Read More.</a>;
  if (isCrossPlatform) {
    return (
      <p>
        {
          `
          By default, Copilot inherits revenue from the buying platform. 
          With Copilot's Revenue Types you can define how Copilot should calculate your strategy's revenue, based on agreed outcomes with your client.
          `
        }
        {readMore}
      </p>
    );
  }
  if (_.includes(_.keys(GOAL_TYPE_TO_UNIT), goalType)) {
    return (
      <p>
        {
          `
            Copilot will pace the ${parentDisplayName} towards a set budget of ${unit}. The budget is calculated by the
            ${parentDisplayName} budget amount / Revenue Value.
          `
        }
        {readMore}
      </p>
    );
  }
  return (
    <p>
      {
        `
          Copilot will pace the ${parentDisplayName} towards a set budget of events (Clicks or Video Completes)
          instead of impressions or currency.
        `
      }
      {readMore}
    </p>
  );
};

export const getRevTypeToolTipContent = (isImpsBudgetType: boolean, hasAmznFlights: boolean) => {
  const platformText = 'Platform Reported: Copilot takes the revenue the buying platform reports and does not transform it.';
  if (isImpsBudgetType) {
    return `${platformText} You cannot customize this option when the budget type of one or more Insertion Orders is Impressions.`;
  }
  if (hasAmznFlights) {
    return `${platformText} You cannot customize this option on Amazon objects.`;
  }
  return `
    Choose how Revenue is calculated.
    ${platformText} Choose this option if you are not sure.
    Customize: Copilot calculates the Revenue based on the billable outcomes and billable values you define.
  `;
};

export type CrossPlatformBudgetSettingsValidationType = {
  rangeOverlap: boolean;
  missingBudgetValue: boolean;
  missingBudgetSettings: boolean;
  budgetLessThanDelivery: boolean;
  budgetEqualToZero: boolean;
};

export const validateCrossPlatformBudgetSettings = (budgetSettings: Array<BudgetSetting>): CrossPlatformBudgetSettingsValidationType => {
  const validation: CrossPlatformBudgetSettingsValidationType = {
    rangeOverlap: false,
    missingBudgetValue: false,
    missingBudgetSettings: false,
    budgetLessThanDelivery: false,
    budgetEqualToZero: false,
  };

  if (budgetSettings) {
    // 1) Budget Settings Overlap Test
    const validBudgetSettings = _.filter(budgetSettings, (item) => item.startDate && item.endDate && moment(item.startDate).isValid() && moment(item.endDate).isValid());

    if (validBudgetSettings.length > 1) {
      const fromTo = _.map(validBudgetSettings, (item) => [moment(item.startDate), moment(item.endDate)]);
      fromTo.sort((a, b) => a[0].toDate().getTime() - b[0].toDate().getTime());

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < fromTo.length - 1; i++) {
        // overlap test => a.start <= b.end && b.start <= a.end;
        if (fromTo[i + 1][0].isSameOrBefore(fromTo[i][1], 'day') && fromTo[i][1].isSameOrAfter(fromTo[i + 1][0], 'day')) {
          validation.rangeOverlap = true;
          break;
        }
      }
    }

    // 2) Check to see if all budget settings have a value >= 1
    if (_.some(budgetSettings, (o) => !o.budget || _.toNumber(o.budget) < 1)) {
      validation.missingBudgetValue = true;
    }
  }

  // 3) Are there Budget Settings?
  if (!budgetSettings || !budgetSettings.length) {
    validation.missingBudgetSettings = true;
  }

  // 4) Are there budget intervals where the Budget is less than the Delivery?
  if (_.some(budgetSettings, (o) => {
    if (_.isNaN(+o.budget)) return false;
    return o.delivery && o.active && o.budget && (_.toNumber(o.budget) < o.delivery);
  })) {
    validation.budgetLessThanDelivery = true;
  }

  // 5) Budget = 0?
  if (_.some(budgetSettings, (o) => +o.budget === 0 || _.toString(o.budget) === '.')) {
    validation.budgetEqualToZero = true;
  }

  return validation;
};

export const invalidCrossPlatformBudgetSettings = (crossPlatformBudgetSettingsValidation: CrossPlatformBudgetSettingsValidationType) => {
  if (crossPlatformBudgetSettingsValidation.rangeOverlap
    || crossPlatformBudgetSettingsValidation.missingBudgetValue
    || crossPlatformBudgetSettingsValidation.missingBudgetSettings
    || crossPlatformBudgetSettingsValidation.budgetLessThanDelivery
    || crossPlatformBudgetSettingsValidation.budgetEqualToZero) {
    return true;
  }
  return false;
};

export const numberValidate = (evt) => {
  const validNonNumericKey = (
    evt.keyCode === 8 // backspace
    || evt.keyCode === 37 // arrowLeft
    || evt.keyCode === 39 // arrowRight
    || evt.keyCode === 46 // delete
    || evt.keyCode === 110 // period (numeric keypad)
    || evt.keyCode === 190 // period
  );
  if ((validNonNumericKey && !evt.shiftKey) || !Number.isNaN(+evt.key)) {
    return true;
  }
  evt.preventDefault();
  return false;
};

export const removeAdditionalDots = (str) => {
  const firstIndex = str.indexOf('.');
  return str.replace(/\./g, (v, i) => (i === firstIndex ? v : ''));
};

export const getErrorColor = (type: ErrorElement, errorType: BudgetStatus): CSSProperties => {
  if (errorType === BudgetStatus.GREATER_THAN_DELIVERY) {
    return { ...greaterThanDeliveryError, borderColor: type === ErrorElement.BUDGET ? YELLOWS.Y500_GOLD : YELLOWS.Y700_METAL };
  }
  return { ...lessThanDeliveryError, borderColor: type === ErrorElement.BUDGET ? REDS.R500_WATERMELON : REDS.R700_VALENTINE };
};

export const getBudgetCellStyleClass = (endDateActive: boolean, budgetDeliveryStatus: BudgetStatus) => {
  if (!endDateActive) {
    return 'crossPlatformBudgetInactive';
  }
  if (budgetDeliveryStatus === BudgetStatus.GREATER_THAN_DELIVERY) {
    return 'crossPlatformBudgetGreaterThanDelivery';
  }
  if (budgetDeliveryStatus === BudgetStatus.LESS_THAN_DELIVERY) {
    return 'crossPlatformBudgetLessThanDelivery';
  }
  return 'crossPlatformBudget';
};

export const getRevenueOutcomes = (
  validFlights: Array<Flight>,
) => {
  const flightsToDisplay = _.reduce(validFlights, (accumulator, parentFlight) => {
    const externalId = parentFlight.externalId;
    const dsp = parentFlight.dsp;
    const flightObj = { externalType: parentFlight.externalType, name: parentFlight.name, outcome: null, revenueValue: null };
    if (!accumulator[dsp]) {
      accumulator[dsp] = {};
    }
    accumulator[dsp][externalId] = flightObj;
    return accumulator;
  }, {});
  return flightsToDisplay;
};

export const getRevenueOutcomesToDisplay = (
  searchStr: string,
  flightsToPopulate: Array<Flight>,
) => {
  let outcomesToDisplay = getRevenueOutcomes(flightsToPopulate);
  const searchValLowercase = !_.isNumber(searchStr) ? searchStr.toLowerCase() : searchStr;
  if (searchStr) {
    const matchingFlights = _.filter(flightsToPopulate, (flight) => (flight.externalId.includes(searchValLowercase) || flight.name.toLowerCase().includes(searchValLowercase)));
    outcomesToDisplay = getRevenueOutcomes(matchingFlights);
  }
  return outcomesToDisplay;
};

export const revenueObjBulkUpdate = (
  updateType: string,
  selectedObjects: Array<string>,
  outcomesToDisplay: RevenueTypeOutcomes,
  bulkEditValues: RevenueEditForm,
) => (_.reduce(selectedObjects, (tempOutcomes, outcomeObj) => {
  const dsp = _.toPath(_.findKey(tempOutcomes, (value) => _.has(value, outcomeObj)));
  if (dsp) {
    _.set(tempOutcomes, [...dsp, outcomeObj, 'outcome'], updateType === 'update' ? bulkEditValues.outcome : null);
    _.set(tempOutcomes, [...dsp, outcomeObj, 'revenueValue'], updateType === 'update' ? bulkEditValues.value : null);
  }
  return tempOutcomes;
}, { ...outcomesToDisplay })
);

export const getBudgetKeyWithPrefix = (key: string) => `externalId-${key}`;

// single row wise update
export const revenueObjUpdate = (
  extId: string,
  updatedValue: string | number,
  outcomesToDisplay: RevenueTypeOutcomes,
  clearErrors: Function,
) => {
  const tempOutcomes = { ...outcomesToDisplay };
  const dsp = _.findKey(tempOutcomes, (value) => _.has(value, extId));
  const budgetFormKey = !_.isNaN(_.toNumber(updatedValue)) ? 'revenueValue' : 'outcome';
  clearErrors(`budget.${getBudgetKeyWithPrefix(extId)}.${budgetFormKey}`);
  _.set(tempOutcomes, [dsp, extId, budgetFormKey], updatedValue);
  return tempOutcomes;
};

export const getCollapsedRevTypeInfo = (
  outcomes: RevenueTypeOutcomes,
) => {
  const outcomeObjs = _.values(outcomes);
  const outcomeValues: Array<any> = _.map(outcomeObjs, 'outcome');
  const revenueValues: Array<any> = _.map(outcomeObjs, 'revenueValue');
  const outcomesSimilarity = _.every(outcomeValues, (element, index: number, array) => element === array[index - 1] || index === 0);
  const valuesSimilarity = _.every(revenueValues, (element, index: number, array) => element === array[index - 1] || index === 0);
  const minVal = _.min(revenueValues);
  const maxVal = _.max(revenueValues);
  const valueRangeFromMinMax = minVal === maxVal ? minVal : `${minVal}-${maxVal}`;
  const outcomeValue = outcomesSimilarity ? _.toUpper(outcomeValues[0]) : 'Multiple';
  const finalValueRange = valuesSimilarity ? _.toNumber(revenueValues[0]) : valueRangeFromMinMax;
  return { outcomeValue, finalValueRange };
};

export const hasSingleOutcomeAndValue = (revTypeConfig: RevenueTypeConfig) => {
  const outcomeValues = _.compact(_.uniq(_.map(revTypeConfig, 'outcome')));
  // sometimes revenueValue is a number, cast as string to keep check consistent
  const revenueValues = _.compact(_.uniq(_.map(revTypeConfig, (config) => _.toString(config.revenueValue))));
  // ensure that we do this check after all values of revTypeConfig are populated
  // @ts-ignore TS doesnt like comparision of string or number to boolean
  const nonNullValues = _.every(revTypeConfig, (config) => (config.outcome && config.revenueValue));
  return nonNullValues && _.size(outcomeValues) === 1 && _.size(revenueValues) === 1;
};

export const hasMissingValue = (revTypeConfig: RevenueTypeConfig) => _.some(revTypeConfig, (val) => _.some(val, (v) => _.isNil(v)));

export const getPixelData = (
  pixelsforAwgWeights: PixelsConfig,
  config: Pick<MetricConfigObj, 'weighting'>,
) => _.reduce(pixelsforAwgWeights, (flightsToDisplay, pixels, dsp) => {
  const pixelObjData = !_.isString(pixels) ? _.reduce(pixels, (acc, childObj) => {
    acc[childObj.id] = { displayName: childObj.name, id: childObj.id, weight: _.get(config, `${dsp}.${childObj.id}`) ?? '1' };
    return acc;
  }, {}) : '';
  flightsToDisplay[dsp] = pixelObjData;
  return flightsToDisplay;
}, {});

// to check if dsp is amazon or not
export const isAmazonDsp = (dspId: string | number) => DSP.AMZN.id === _.toNumber(dspId);

export const updateAmazonEvents = (availablePixelsData: PixelsConfig, config: Pick<MetricConfigObj, 'weighting'>) => _.reduce(availablePixelsData, (result, value, dspId) => {
  result[dspId] = (isAmazonDsp(dspId) ? _.map(_.keys(config[dspId]), (key: string) => ({ id: key })) : value);
  return result;
}, {});

const getDataforConversions = async (
  validFlights: Array<Flight>,
  config: Pick<MetricConfigObj, 'weighting'>,
  availablePixelsData: PixelsConfig,
  setAvailablePixelsData: (x: any) => void,
  setLoading: (x: boolean) => void,
  setHasPixelError: (x: boolean) => void,
) => {
  let conversionIdObj;
  setLoading(true);
  try {
    if (_.isEmpty(availablePixelsData)) {
      const availablePixels = await loadDspPixels(validFlights);
      // for amazon we are setting the events back in pixel array
      const availablePixelsDataArray: PixelsConfig = updateAmazonEvents(availablePixels, config);
      setAvailablePixelsData(availablePixelsDataArray);
      conversionIdObj = getPixelData(availablePixelsDataArray, config);
      const hasAllPixelErrors = _.every(conversionIdObj, (value) => _.isString(value));
      setHasPixelError(hasAllPixelErrors);
    } else {
      // for amazon we are setting the events back in pixel array
      const availablePixelsDataArray: PixelsConfig = updateAmazonEvents(availablePixelsData, config);
      conversionIdObj = getPixelData(availablePixelsDataArray, config);
    }
    return conversionIdObj;
  } finally {
    setLoading(false);
  }
};

export const getModalOptions = async (
  selectedDimension: string,
  budgetAllocationData: { [flightExtId: string]: BudgetAllocationData },
  validFlights: Array<Flight>,
  config: Pick<MetricConfigObj, 'weighting'>,
  availablePixelsData: PixelsConfig,
  setAvailablePixelsData: (x: any) => void,
  setLoading: (x: boolean) => void,
  setHasPixelError: (x: boolean) => void,
) => {
  let options = {};
  options = _.reduce(validFlights, (flightsToDisplay, parentFlight: Flight) => {
    const dspId = parentFlight.dsp;
    if (selectedDimension === AWGDimensions.insertionOrder) {
      const flightObj = { id: parentFlight.externalId, displayName: parentFlight.name, weight: _.get(config, `${dspId}.${parentFlight.externalId}`) ?? '1' };
      flightsToDisplay[dspId] = { ..._.get(flightsToDisplay, _.toString(dspId), {}), [parentFlight.externalId]: flightObj };
    }
    if (selectedDimension === AWGDimensions.lineItem) {
      const origToClone = _.values(_.get(budgetAllocationData, `${parentFlight.externalId}.origToClone`, {}) as { [key: string]: string }) as Array<string>;
      const { childExtIdToSettings, invalidData } = _.get(budgetAllocationData, `${parentFlight.externalId}.hierarchy`, {}) as BudgetHierarchy;
      const childData = { ...childExtIdToSettings, ...invalidData };
      const tempFlights = _.reduce(childData, (acc, childObj, extId) => {
        // create key as long as flight isn't a clone
        if (shouldCreateChildKeys(origToClone, extId, childData)) {
          acc[extId] = { displayName: childObj.name, id: extId, weight: _.get(config, `${dspId}.${extId}`) ?? '1' };
        }
        return acc;
      }, {});
      flightsToDisplay[dspId] = { ..._.get(flightsToDisplay, _.toString(dspId), {}), ...tempFlights };
    }
    return flightsToDisplay;
  }, {});

  if (selectedDimension === AWGDimensions.pixel) {
    options = await getDataforConversions(validFlights, config, availablePixelsData, setAvailablePixelsData, setLoading, setHasPixelError);
  }
  return options;
};

export const getPlatformOptions = (
  attachedFlights: Array<Flight>,
  config: Pick<MetricConfigObj, 'weighting'>,
) => _.reduce(attachedFlights, (flightsToDisplay, parentFlight: Flight) => {
  const dspId = parentFlight.dsp;
  const { code, displayName } = DSP.getById(dspId);
  const flightObj = { id: parentFlight.dsp, code, displayName, weight: _.get(config, dspId) ?? '1' };
  flightsToDisplay[dspId] = { ..._.get(flightsToDisplay, dspId, {}), ...flightObj };
  return flightsToDisplay;
}, {});

export const getFormulaMetrics = (
  metrics: Array<string>,
  isCampaignOptType: boolean,
  metricsConfig: MetricConfig,
  getFromConfig: boolean,
) => _.reduce(metrics, (formulaMetrics, metric) => {
  if (_.size(metricsConfig) && metric in metricsConfig && getFromConfig) {
    formulaMetrics[metric] = metricsConfig[metric];
  } else {
    formulaMetrics[metric] = {
      name: metric,
      dimension: isCampaignOptType ? AWGDimensions.lineItem : AWGDimensions.insertionOrder,
      isWeighted: false,
      weighting: {},
    };
  }
  return formulaMetrics;
}, {});

// recursively checks weightOptions to see if a weight !== 1
export const isWeighted = (weightOptions) => _.some(weightOptions, (obj: { weight?: string | number }) => {
  if (_.isObject(obj)) {
    if (_.has(obj, 'weight')) {
      return +obj.weight !== 1 && obj.weight !== '' && Symbol(obj.weight).toString() !== 'Symbol(−)';
    }
    return isWeighted(obj);
  }
  return false;
});

export const getDimensionText = (dimension: AWGDimensions) => {
  switch (dimension) {
    case (AWGDimensions.dsp):
      return 'Platform';
    case (AWGDimensions.lineItem):
      return 'Line Item';
    case (AWGDimensions.pixel):
      return 'Conversion ID';
    case (AWGDimensions.insertionOrder):
    default:
      return 'Campaign';
  }
};

/**
 * Finds the first key in config where outcome has data and strips the prefix from the key
 * @param config budget object that contains all revenue type related info
 * @returns externalId of flight
 */
export const getFlightExtIdFromBudgetConfig = (config: RevenueTypeConfig) => _.chain(config)
  .findKey('outcome')
  .split('-')
  .last()
  .value();

export const isAWGOrImpact = (goalType: string) => _.includes([GOAL_TYPES.awgCreateYourOwn.value, GOAL_TYPES.impactOutcome.value], goalType);

export const swapActionObjIndexes = (actionObj: FormulaBuilderAction) => {
  const tempContentIdx = _.clone(actionObj.contentIdx);
  _.set(actionObj, 'contentIdx', actionObj.cursorIdx);
  _.set(actionObj, 'cursorIdx', tempContentIdx);
};

export const getSystemGoals = async (dataConfig: FormulaStateType) => {
  let strategyGoals = GRAB_FORMULA_GOALS.map((goal: string) => ({
    longText: GOAL_TYPES[goal].strategyWizardLongText,
    direction: GOAL_TYPES[goal].lowerIsBetter ? OptimizationDirection.down : OptimizationDirection.up,
    name: GOAL_TYPES[goal].value,
    equation: GOAL_TYPES[goal].equation,
    displayName: GOAL_TYPES[goal].strategyWizardAbbreviation || GOAL_TYPES[goal].shortText,
  }));

  // Sorting the goals alphabetically by their 'value' property
  strategyGoals.sort((a, b) => a.displayName.localeCompare(b.displayName));

  // Check if there's a search value provided in dataConfig
  if (dataConfig.searchVal) {
    // Filter the goals based on the search value
    strategyGoals = _.filter(strategyGoals, (goal) => _.some([goal.name, goal.displayName, goal.longText], (text) => _.includes(_.toLower(text), _.toLower(dataConfig.searchVal))));
  }

  // Apply pagination using offset
  const { limit, skip } = dataConfig;
  const paginatedGoals = _.slice(strategyGoals, skip, skip + limit);
  const strategyGoalCount = _.size(strategyGoals);
  return { data: { strategyGoals: paginatedGoals, strategyGoalCount } };
};

export const getFormulaData = async (limit: number, offSet: number, searchVal: string, filterValue: GrabFormulaFilters) => {
  const skip = _.isEmpty(searchVal) ? offSet : 0;
  const dataConfig = { limit, skip, sortOrder: 'DESC', searchVal };
  const strategyGoalData = filterValue === GrabFormulaFilters.users ? await StrategyGoals.getValidCustomFormulas(dataConfig) : await getSystemGoals(dataConfig);
  return _.get(strategyGoalData, 'data');
};

export const getGoalIdByName = async (goalName: string) => {
  const snakeCasedGoal = toSnakeCase(goalName);
  const { data: goalRes } = await Goal.get({ name: snakeCasedGoal });
  const goalId = _.chain(goalRes as Array<GoalType>)
    .head()
    .get('id')
    .value();
  return goalId;
};

export const getParentObjectDisplayName = (attachedFlights, optimizationLevel) => {
  if (_.size(attachedFlights) === 1) {
    return optimizationLevel?.displayName?.single;
  }
  if (hasMultipleExternalTypesAttached(attachedFlights)) {
    return 'Objects';
  }
  return optimizationLevel?.displayName?.multiple;
};
