import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { WppButton } from 'buildingBlocks';
import { WIZARD_STEPS, WizardSteps } from 'containers/StrategyWizard/constants';
import { COPILOT_LAYOUT } from 'globalStyles';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { getStepUrl } from 'containers/StrategyWizard/utils';
import { GlobalState } from 'reducers';
import { createLinkWithQS } from 'utils/functionHelpers';
import { Flight } from 'utils/types';
import { STRATEGY_WIZARD_BUTTON_TAG } from 'cssTagConstants';

type ConfirmButtonProps = {
  disabled: boolean
  strategyId: number
};

const { SPACING } = COPILOT_LAYOUT;

const getButtonText = (strategyId: number) => (strategyId ? 'Update Strategy' : 'Create Strategy');

const ConfirmButton = ({ disabled, strategyId }: ConfirmButtonProps) => {
  const buttonText = getButtonText(strategyId);
  const attachedFlights = useSelector<GlobalState>((state) => state.strategyWizard.attachFlightsStep.attachedFlights) as Array<Flight>;
  const backBtnStepId = (strategyId && _.isEmpty(attachedFlights)) ? WIZARD_STEPS[WizardSteps.attachFlightsStep].id : WIZARD_STEPS[WizardSteps.strategyConfigurationStep].id;
  return (
    <div style={{ ...strategyWizardStyles.navButtonContainerStyle, gap: SPACING[12] }}>
      <Link to={createLinkWithQS(getStepUrl(strategyId, backBtnStepId))}>
        <WppButton
          variant="secondary"
          id={strategyId ? STRATEGY_WIZARD_BUTTON_TAG.backButtonForEditStrategy : STRATEGY_WIZARD_BUTTON_TAG.backButtonForNewStrategy}
        >
          Back
        </WppButton>
      </Link>
      <WppButton
        type="submit"
        id={!strategyId ? STRATEGY_WIZARD_BUTTON_TAG.createStrategy : STRATEGY_WIZARD_BUTTON_TAG.updateStrategy}
        disabled={disabled}
        style={{ pointerEvents: disabled ? 'none' : 'all' }}
      >
        {buttonText}
      </WppButton>
    </div>
  );
};

export default ConfirmButton;
