/* eslint-disable no-param-reassign */
import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { Map as ImmutableMap } from 'immutable';
import dspIconStyles from 'components/DspIcon/style';
import { AWGDimensions, DSP } from 'constantsBase';
import { WppInlineMessage, WppInput, WppTableBody, WppTableBodyCell, WppTableBodyRow, WppTypography } from 'buildingBlocks';
import { MODAL_ERROR_MSG } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { AWG_WEIGHTS_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { MetricConfig, MetricConfigObj, WeightConfig, WeightObj, WeightRowObj } from 'containers/StrategyWizard/types';
import { InputChangeEventDetail, WppInputCustomEvent } from 'utils/types';
import { isAmazonDsp } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { PIXEL_PICKER_STYLES } from 'containers/StrategyWizard/components/PixelPicker/styles';
import { useWeightsModalContentContext } from '../contexts/WeightsModalContentProvider';
import { useWeightsSectionContext } from '../contexts/WeightsSectionProvider';

const { inputFieldError, weightListDisplay, weightListDspCode } = AWG_WEIGHTS_MODAL_STYLES;
const { dspHeader } = PIXEL_PICKER_STYLES;

type WeightRowProps = {
  listItem: WeightRowObj
  flightsToDisplay: ImmutableMap<string, WeightObj>
  setFlightsToDisplay: Dispatch<SetStateAction<ImmutableMap<string, WeightObj>>>
  dspId: string
};

const WeightRow = ({
  listItem, flightsToDisplay, setFlightsToDisplay, dspId,
}: WeightRowProps) => {
  const dspCode = DSP.getById(dspId).code;
  const amazonDsp = isAmazonDsp(dspId);
  const dspHeaderStyle = { ...dspIconStyles[dspCode], ...dspHeader };
  const { selectedMetric, sessionMetrics, setSessionMetrics, selectedDimension } = useWeightsSectionContext();
  const { attemptSave, formErrors, setFormErrors } = useWeightsModalContentContext();
  const showError = attemptSave && formErrors[listItem.id];
  const isAmazonPixels = (amazonDsp && selectedDimension === AWGDimensions.pixel);

  const handleOnChange = (event: WppInputCustomEvent<InputChangeEventDetail>) => {
    let value = event.detail.value;
    if (!value || Symbol(value).toString() === 'Symbol(−)') {
      setFormErrors({
        ...formErrors,
        [listItem.id]: MODAL_ERROR_MSG,
      });
    } else {
      delete formErrors[listItem.id];
      let updatedSessionMetric;
      // convert sessionMetrics to JS Obj then check weighting[dspId]
      // getIn([selectedMetric, 'weighting', dspId]) returns undefined at times even though it exists
      const selectedMetricConfig = sessionMetrics.getIn([selectedMetric], {}) as ImmutableMap<string, MetricConfigObj>;
      const dspWeightConfig = _.get(selectedMetricConfig.toJS(), `weighting[${dspId}]`, {});
      // set weighting to {} if we change the only weighted option back to 1
      if (value === '1' && (_.size(dspWeightConfig) === 1 && _.has(dspWeightConfig, listItem.id))) {
        updatedSessionMetric = sessionMetrics.deleteIn([selectedMetric, 'weighting', dspId]);
      }
      // only remove listItem.id key if value is '1' and dspWeightConfig has listItem.id
      if (value === '1' && _.size(dspWeightConfig) > 1 && _.has(dspWeightConfig, listItem.id)) {
        updatedSessionMetric = sessionMetrics.deleteIn([selectedMetric, 'weighting', dspId, _.toString(listItem.id)]);
      }
      if (value !== '1') {
        // Allow for only 4 decimal places
        value = value.split('.').map((el, i) => (i ? el.split('').slice(0, 4).join('') : el)).join('.');
        const weight = !_.isNaN(_.toNumber(value)) ? _.toNumber(value) : value;
        updatedSessionMetric = sessionMetrics.mergeDeep({
          [selectedMetric]: {
            weighting: { [dspId]: { [listItem.id]: weight as unknown } },
          },
        } as MetricConfig);
      }
      if (updatedSessionMetric) {
        setSessionMetrics(updatedSessionMetric);
      }
    }
    setFlightsToDisplay(flightsToDisplay.mergeDeep({
      [dspId]: {
        [listItem.id]: { weight: value },
      },
    } as WeightConfig));
  };
  return (
    <WppTableBody className="wpp-tbody">
      <WppTableBodyRow className="wpp-table-body-row">
        <WppTableBodyCell className="wpp-table-body-cell" style={weightListDisplay}>
          <WppTypography type="s-body" tag="p">{!isAmazonPixels && `${listItem.id} | `}{listItem.displayName}</WppTypography>
        </WppTableBodyCell>
        <WppTableBodyCell className="wpp-table-body-cell" style={weightListDspCode}>
          <WppTypography type="s-body" tag="p" style={dspHeaderStyle}>{dspCode}</WppTypography>
        </WppTableBodyCell>
        <WppTableBodyCell className="wpp-table-body-cell">
          <WppInput
            id={showError ? 'metricInputError' : ''}
            type="decimal"
            style={{ ...(showError && inputFieldError) }}
            value={_.toString(listItem.weight)}
            size="s"
            onWppChange={handleOnChange}
            messageType={showError ? 'error' : undefined}
            maskOptions={{
              decimalPatternOptions: {
                precision: 4,
                thousandSeparator: '',
                decimalSeparator: '.',
              },
            }}
          />
          {showError && (
            <WppInlineMessage
              size="s"
              message={showError && (formErrors[listItem.id] || '')}
              type="error"
              showTooltipFrom={10}
            />
          )}
        </WppTableBodyCell>
      </WppTableBodyRow>

    </WppTableBody>
  );
};

type WeightsListProps = {
  weightsList: WeightObj
  flightsToDisplay: ImmutableMap<string, WeightObj>
  setFlightsToDisplay: Dispatch<SetStateAction<ImmutableMap<string, WeightObj>>>
  dspId: string
  selectedConversionEvents: Array<string>
};

const WeightsList = ({ weightsList, flightsToDisplay, setFlightsToDisplay, dspId, selectedConversionEvents }: WeightsListProps) => {
  const { selectedDimension, selectedMetric, sessionMetrics } = useWeightsSectionContext();
  const amazonDsp = isAmazonDsp(dspId);

  // when dsp is amazon and dimension is pixel then create row for conversion events user selected
  if (amazonDsp && selectedDimension === AWGDimensions.pixel) {
    const sessionWeightsList = sessionMetrics.getIn([selectedMetric, 'weighting'], {}).toJS()[dspId];
    const weights = sessionWeightsList || weightsList;
    return (
      <>
        {_.map(selectedConversionEvents, (event: string) => {
          let weight: string | number = '1';
          // if value is already set by user previously then set session weights value otherwise default value to 1 or set recently set value
          if (event in weights) {
            weight = (sessionWeightsList ? sessionWeightsList[event] : weightsList[event].weight);
          }
          return (
            <WeightRow
              dspId={dspId}
              key={event}
              listItem={{ displayName: event, weight, id: event }}
              flightsToDisplay={flightsToDisplay}
              setFlightsToDisplay={setFlightsToDisplay}
            />
          );
        })}
      </>
    );
  }
  return (
    <>
      {_.map(weightsList, (listItem: WeightRowObj) => (
        <WeightRow
          dspId={dspId}
          key={listItem.id}
          listItem={listItem}
          flightsToDisplay={flightsToDisplay}
          setFlightsToDisplay={setFlightsToDisplay}
        />
      ))}
    </>
  );
};

export default WeightsList;
