import _ from 'lodash';
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { WppAccordion, WppButton, WppGrid, WppTypography } from 'buildingBlocks';
import { wppBodyContainer } from 'components/PageTemplate/style';
import { STRATEGY_TYPE } from 'constantsBase';
import { ADVERTISER_EXTERNAL_ID_KEY, MEMBER_EXTERNAL_ID_KEY } from 'containers/StrategyWizard/ConfigurationByStrategyType/constants';
import { getStrategyTypeMapperById, getStrategyTypeExcludedFieldsById } from 'containers/StrategyWizard/ConfigurationByStrategyType/utils';
import { STRATEGY_CONFIRMATION_STYLES } from 'containers/StrategyWizard/steps/StrategyConfirmation/styles';
import { StrategyConfigurationStep, WizardFormValues } from 'containers/StrategyWizard/types';
import { hasExternalCustomValueMetric } from 'containers/StrategyWizard/utils';
import { COPILOT_LAYOUT } from 'globalStyles';
import { remap } from 'utils/airflow/nameTranslator';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { Member, Advertiser, StrategyType, Brand, Flight } from 'utils/types';
import { BulkCreateWizardFormValues } from 'containers/BulkCreateStrategyWizard/types';
import AdminConfigErrors from './AdminConfigErrors';
import { EDITOR_INPUT_DEFAULT_VALUE } from '../constants';
import './adminConfig.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/keymap/vim';

const crossPlatformStratId = STRATEGY_TYPE.crossPlatformOptimization.id;

const EXCLUDED_FIELDS = [
  'scheduled',
  // These fields are not changeable
  ADVERTISER_EXTERNAL_ID_KEY,
  MEMBER_EXTERNAL_ID_KEY,
  // These are complicated to support and won't be updated via the admin box anyway
  'impValueFilters',
  'segments',
  'segmentGroups',
  'strategyGoals',
  // This will be part of strategyGoals object
  'viewability',
  // Specific to cross-platform
  'dbClientEventRevenueValue',
  'brandId',
  'revenueOutcomeType',
];

const EDITOR_OPTIONS = {
  mode: 'application/json',
  lineNumbers: true,
  lineWrapping: true,
  indentWithTabs: true,
  indentUnit: 2,
  keyMap: 'default',
  height: 'auto',
};

const mapFormValuesToDbStyle = (
  formValues: StrategyConfigurationStep,
  strategyTypeId: number,
  member: Member,
  advertiser: Advertiser,
  brand: Brand,
  defaultCurrency: string,
  attachedFlights: Array<Flight>,
  updateAdminConfig: boolean,
  hasExternalCustomValue: boolean,
) => {
  const mapper = getStrategyTypeMapperById(strategyTypeId);
  // only exclude budget settings when strat is not cross-platform
  const excludedFields = [...EXCLUDED_FIELDS, ...getStrategyTypeExcludedFieldsById(strategyTypeId), ...(strategyTypeId !== crossPlatformStratId ? ['budgetSettings'] : [])];
  const config = mapper.createConfig(formValues, advertiser, member, brand, defaultCurrency, attachedFlights, updateAdminConfig, false, hasExternalCustomValue);
  const filtered = _.omit(config, excludedFields);
  return remap(mapper, filtered);
};

type AdminConfigProps = {
  strategyType: StrategyType
  name: string
  formValues: StrategyConfigurationStep
  onEditorSubmit: (editorInput: string) => void
  member: Member
  advertiser: Advertiser
  defaultCurrency: string
  updateAdminConfig: boolean
  setUpdateAdminConfig: Dispatch<SetStateAction<boolean>>
  strategyWizardFormValues: BulkCreateWizardFormValues | WizardFormValues
  brand?: Brand
};

const AdminConfig = (props: AdminConfigProps) => {
  const {
    strategyType,
    name,
    formValues,
    onEditorSubmit,
    member,
    advertiser,
    defaultCurrency,
    updateAdminConfig,
    setUpdateAdminConfig,
    strategyWizardFormValues,
    brand,
  } = props;
  const [editorInput, updateEditorInput] = useState<string>(EDITOR_INPUT_DEFAULT_VALUE);
  const [jsonParseError, setJsonParseError] = useState<null | string>(null);
  const [editorInstance, setEditorInstance] = useState();
  const {
    attachFlightsStep: { attachedFlights },
    goalSelectionStep,
  } = strategyWizardFormValues;

  useMount(() => {
    const goalType = _.get(goalSelectionStep, 'goal.type');
    const hasExternalCustomValue = hasExternalCustomValueMetric(goalType, goalSelectionStep);
    const mappedFormValues = mapFormValuesToDbStyle(formValues, strategyType.id, member, advertiser, brand, defaultCurrency, attachedFlights, updateAdminConfig, hasExternalCustomValue);
    updateEditorInput(JSON.stringify(mappedFormValues, null, 2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    if (editorInput === '') {
      updateEditorInput(EDITOR_INPUT_DEFAULT_VALUE);
    }
  }, [editorInput]);

  // this hack is due to a bug where CodeMirror does not render properly inside a collapsible
  // https://github.com/scniro/react-codemirror2/issues/83
  if (editorInstance) {
    setTimeout(() => {
      // @ts-ignore
      editorInstance.refresh();
    }, 200);
  }

  const getFormattedEditorValue = () => {
    try {
      return editorInput && editorInput !== EDITOR_INPUT_DEFAULT_VALUE
        ? JSON.stringify(JSON.parse(editorInput), null, 2)
        : editorInput;
    } catch {
      return editorInput;
    }
  };

  const handleOverrideClick = () => {
    try {
      setUpdateAdminConfig(true);
      const editorInputValue = getFormattedEditorValue();
      updateEditorInput(editorInputValue);
      onEditorSubmit(editorInputValue);
      setJsonParseError(null);
    } catch (error) {
      setJsonParseError(`Invalid JSON: ${error}`);
    }
  };

  return (
    <WppGrid item all={24}>
      <div style={wppBodyContainer}>
        <WppAccordion withDivider={false}>
          <WppTypography type="xl-heading" slot="header">Admin Tools</WppTypography>
          <div
            id={name}
            style={STRATEGY_CONFIRMATION_STYLES.adminToolsSection}
            className="admin-toolCode-mirror"
          >
            <CodeMirror
              options={EDITOR_OPTIONS}
              onBeforeChange={(_editor: any, _data: any, values: string) => updateEditorInput(values)}
              value={editorInput}
              // react throws a warning if you don't create a new function here
              editorDidMount={(editor) => setEditorInstance(editor)}
            />
            {jsonParseError && <AdminConfigErrors jsonParseError={jsonParseError} />}
            <WppButton
              variant="secondary"
              style={{ marginTop: COPILOT_LAYOUT.SPACING[12] }}
              onClick={handleOverrideClick}
            >
              Override Form Values
            </WppButton>
          </div>
        </WppAccordion>
      </div>
    </WppGrid>
  );
};

export default AdminConfig;
