import _ from 'lodash';
import React, { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { Label, WppGrid, WppRadio, WppTypography, WppIconInfo } from 'buildingBlocks';
import { CYOD_GOAL_TYPE_OBJS, GOAL_TYPES, GOAL_VALUE_TYPE } from 'constantsBase';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { CYOD_GOAL_DESCRIPTIONS } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { CYOD_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { GoalType } from 'utils/types';

const {
  baseCardStyles, selectedCardStyles, radio, radioText, cyodModCardDiv, cyodGridGoalConfig,
  errorMessage, goalConfigCardText, goalConfigCardIconText, goalConfigCardIconShortText,
} = CYOD_MODAL_STYLES;

type CYODGoalConfigurationProps = {
  goal: GoalType
  selected: boolean
  setModalGoalType: (x: any) => void
  setModalGoalTarget: (x: any) => void
};

const CYODGoalConfiguration = (props: CYODGoalConfigurationProps) => {
  const { goal, selected, setModalGoalType, setModalGoalTarget } = props;
  const goalType = useWatch<WizardFormGoalSelection>({ name: 'goal.type' }) as string;
  const goalTarget = useWatch<WizardFormGoalSelection>({ name: 'goal.target' }) as number;
  const cardStyles = { ...baseCardStyles, ...(selected && selectedCardStyles) };

  const handleClick = (e) => {
    e.stopPropagation();
    setModalGoalType(goal.value);
    setModalGoalTarget(!_.isEqual(goalType, goal.value) || _.isNil(goalTarget) ? null : goalTarget);
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className={selected ? 'cyod-goal-card-selected' : ''}
      onClick={handleClick}
      style={cyodModCardDiv}
    >
      <div style={cardStyles}>
        <div style={radioText}>
          <div>
            <WppTypography type="m-strong">{goal.text}</WppTypography>
            <WppTypography type="xs-body" tag="p" style={goalConfigCardText}>{_.get(CYOD_GOAL_DESCRIPTIONS, goal.value)}</WppTypography>
            <div style={goalConfigCardIconText}><WppIconInfo /><WppTypography type="xs-midi" tag="p" style={goalConfigCardIconShortText}>{goal.shortText}</WppTypography></div>
          </div>
          <WppRadio
            className="opt-lvl"
            style={radio}
            checked={selected}
          />
        </div>
      </div>
    </div>
  );
};

type CYODGoalTypeFieldProps = {
  modalGoalType: string
  setModalGoalType: (x: any) => void
  modalGoalTarget: number
  setModalGoalTarget: (x: any) => void
  goalTargetError: string
  setGoalTargetError: (x: any) => void
};

const CYODGoalTypeField = (props: CYODGoalTypeFieldProps) => {
  const { modalGoalType, setModalGoalType, modalGoalTarget, setModalGoalTarget, goalTargetError, setGoalTargetError } = props;

  const valueType = _.get(GOAL_TYPES, `${modalGoalType}.valueType`);

  useEffect(() => {
    if (_.isNil(modalGoalTarget)) {
      setGoalTargetError('');
      return;
    }
    if (modalGoalTarget < 0) {
      setGoalTargetError('Goal target must be at least 0');
      return;
    }
    if (valueType === GOAL_VALUE_TYPE.PERCENTAGE && modalGoalTarget > 1) {
      setGoalTargetError('Goal target must be at most 100');
      return;
    }
    setGoalTargetError('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalGoalTarget]);

  return (
    <WppGrid>
      <WppGrid item all={1}>
        <div style={cyodGridGoalConfig}>
          {_.map(CYOD_GOAL_TYPE_OBJS, (goal) => (
            <CYODGoalConfiguration
              key={goal.value}
              goal={goal}
              selected={_.isEqual(modalGoalType, goal.value)}
              setModalGoalType={setModalGoalType}
              setModalGoalTarget={setModalGoalTarget}
            />
          ))}
        </div>
        {goalTargetError && <Label basic style={errorMessage}>{goalTargetError}</Label>}
      </WppGrid>
    </WppGrid>
  );
};

export default CYODGoalTypeField;
