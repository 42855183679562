import { CSSProperties } from 'react';
import { color } from 'd3';
import { COPILOT_LAYOUT, COPILOT_TYPOGRAPHY, COPILOT_COLORS } from 'globalStyles';

const {
  grey,
  NEW_DESIGN_SYSTEM: { NEUTRALS, METTLES, REDS, YELLOWS },
  WPP,
} = COPILOT_COLORS;
const { SPACING } = COPILOT_LAYOUT;

const borderColor = color(METTLES.M900_TROUT);
borderColor.opacity = 0.15;

const strategyWizard: { [key: string]: CSSProperties | any } = {
  container: {
    backgroundColor: NEUTRALS.N0_WHITE,
  },
  base: {
    backgroundColor: NEUTRALS.N0_WHITE,
    border: `1px solid ${borderColor}`,
    borderOpacity: 0.15,
  },
  a: {
    border: 'initial',
    padding: '5px',
    boxShadow: 'initial',
    color: 'inherit',
    background: 'initial',
    fontWeight: 'initial',
    margin: 'initial',
  },
  column: {
    padding: SPACING[24],
  },
  row: {
    boxShadow: 'none',
  },
  divider: {
    margin: '0px 24px',
  },
  flight: {
    ERROR: {
      color: REDS.R700_VALENTINE,
    },
    WARNING: {
      color: YELLOWS.Y500_GOLD,
    },
    default: {
      whiteSpace: 'pre',
    },
    table: {
      marginTop: SPACING[16],
    },
    accordion: {
      marginTop: '40px',
    },
    accordionTable: {
      borderSpacing: '8px',
    },
  },
  navButtonContainerStyle: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: grey,
    padding: 0,
    paddingTop: SPACING[16],
    marginBottom: SPACING[32],
    width: '100%',
    gap: SPACING[12],
  },
  backArrowStyle: {
    color: NEUTRALS.N800_MATTER,
    fontSize: 16,
  },
  backButtonStyle: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    width: '0px',
    marginRight: '10px',
    textTransform: 'capitalize',
    backgroundColor: 'transparent',
    color: NEUTRALS.N800_MATTER,
    padding: `${SPACING[4]}px ${SPACING[48]}px ${SPACING[4]}px ${SPACING[8]}px`,
    boxShadow: 'none',
    border: 'none',
  },
  nextButtonStyle: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    textTransform: 'capitalize',
    color: NEUTRALS.N0_WHITE,
    padding: '4px 0',
    marginLeft: '25px',
  },
  customTreeBtn: {
    width: '100%',
    marginBottom: SPACING[12],
  },
  strategyWizardStyles: {
    marginTop: SPACING[12],
  },
  configureCustomStyle: {
    textAlign: 'center',
    marginTop: SPACING[12],
    marginBottom: SPACING[12],
  },
  dividerStyle: {
    width: '100%',
    marginTop: SPACING[24],
    marginBottom: SPACING[24],
  },
  containerStyle: {
    padding: SPACING[4],
    margin: 0,
  },
  fileUploadCustom: {
    textAlign: 'center',
    marginBottom: SPACING[12],
  },
  leafNameStyle: {
    bottom: SPACING[4],
  },
  customSDFInlineMessageContainer: {
    width: '90%',
    margin: '15px 0px',
    display: 'flex',
    gap: '5px',
    background: WPP.grey300,
    padding: '5px 12px 8px 12px',
    borderRadius: '6px',
  },
  customSDFInlineMessageTextStyle: {
    textDecoration: 'underline',
    textDecorationColor: WPP.blue500,
    bottom: '5px',
  },
  customSDFHelpLinkText: {
    color: WPP.blue500,
  },
  customSDFSupportTextStyle: {
    textDecoration: 'underline',
    bottom: SPACING[4],
  },
  toBeDetachedModalStyle: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    float: 'right',
    borderRadius: 4,
    backgroundColor: NEUTRALS.N0_WHITE,
    border: `1px solid ${NEUTRALS.N300_ALTO}`,
    color: NEUTRALS.N800_MATTER,
    padding: '5px 24.5px',
    textTransform: 'none',
  },
  flightRowIcon: {
    fontSize: 7,
    position: 'absolute',
  },
  flightRowContainer: {
    backgroundColor: 'var(--wpp-grey-color-100)',
    borderRadius: 'var(--wpp-border-radius-m)',
    padding: '12px 16px',
  },
  flightRowColor: {
    height: SPACING[24],
    width: SPACING[4],
    borderRadius: 'var(--wpp-border-radius-xs)',
    backgroundColor: NEUTRALS.N400_GRANITE,
  },
  flightRow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 'var(--wpp-border-radius-xs)',
    gap: SPACING[12],
  },
  flightRowIconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  flightRowTrashIcon: {
    color: WPP.grey800,
  },
  flightRowInfoIcon: {
    color: WPP.grey800,
    margin: '0 10px',
  },
  modalFlightName: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    fontWeight: 500,
    width: '100%',
    wordBreak: 'break-all',
  },
  cancelPopupActionContainer: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'flex-end',
  },
  progressTitle: {
    cursor: 'default',
    fontSize: 12,
    width: '115px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  toolTip: {
    width: '350px',
    height: '150px',
    padding: '40px, 16px, 16px, 16px',
  },
  optTypeCancelButton: {
    marginRight: '10px',
  },
  stepperLabel: {
    overflow: 'initial',
    margin: '17px 0px 0px 0px',
  },
  subStepperLabel: {
    overflow: 'initial',
    margin: '0px',
  },
  breadCrumBackLink: {
    marginLeft: SPACING[4],
    lineHeight: '15px',
  },
  breadCrumContainer: {
    display: 'flex',
    marginBottom: SPACING[8],
  },
  flightRowDividerStyle: {
    marginTop: '5px',
    marginBottom: '5px',
  },
  flightRowHeader: {
    marginBottom: SPACING[12],
  },
  flightRowRenderStringStyle: {
    display: 'flex',
    position: 'absolute',
  },
  flightRowTooltip: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
  },
  flightRowDetailsTypoStyle: {
    width: '483px',
  },
  flightRowBodyStyle: {
    display: 'table',
    margin: 'initial',
    padding: 'initial',
    paddingLeft: SPACING[8],
    tableLayout: 'fixed',
    wordBreak: 'break-word',
    borderSpacing: SPACING[8],
    position: 'relative',
  },
  flightRowBorderStyle: {
    display: 'table-cell',
    padding: '3px',
    height: SPACING[24],
    borderRadius: 4,
    position: 'relative',
    left: '-18px',
    borderBottomStyle: 'hidden',
  },
  flightRowIconStyle: {
    display: 'flex',
    textAlign: 'right',
    alignItems: 'center',
    position: 'absolute',
    bottom: '0px',
    marginLeft: SPACING[20],
  },
  customVerticleDivider: {
    width: '1px',
    borderLeft: '1px solid',
    margin: SPACING[24],
    transform: 'rotate(180deg)',
    color: 'var(--wpp-grey-color-300)',
  },
  treeTextLeafNameParent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flightBodyRowStyle: {
    border: 'none',
  },
  flightRowTableBodyStyle: {
    height: SPACING[20],
  },
  viewabilityContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  viewabilityHeader: {
    display: 'flex',
    gap: SPACING[4],
  },
  customTreeModelActionBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: SPACING[12],
  },
  customTreeModelContainer: {
    marginBottom: SPACING[12],
    display: 'flex',
    flexDirection: 'column',
  },
  customTreeTextSuccess: {
    color: 'var(--wpp-success-color-500)',
  },
  stepperDescription: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    cursor: 'default',
    height: '18px',
  },
  stepperLableStyle: {
    cursor: 'default',
  },
  stepperDescriptionHide: {
    display: 'none',
  },
};

export default strategyWizard;
