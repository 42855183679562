import _ from 'lodash';
import { OPERATOR_SVGS } from 'containers/StrategyWizard/constants';
import { FORMULA_BUILDER_ERROR_MESSAGES } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { checkIsMetric, checkIsNumber } from 'containers/StrategyWizard/utils';
import { MutableRefObject } from 'react';

const MATH_OPERATORS = _.keys(OPERATOR_SVGS);

const checkIsMathOperator = (elem: string) => _.includes(MATH_OPERATORS, elem);

export const DEFAULT_ERROR_OBJ = { type: 'custom' };

const customGoalFormulaField = 'customGoal.formula';

const setValidatingFalse = (validating) => {
  if (validating) {
    // eslint-disable-next-line no-param-reassign
    validating.current = false;
  }
};

export const validateFormula = (formula: Array<string>, setError: Function, validating: MutableRefObject<boolean> = null) => {
  let metricCount = 0;
  let parenthesisBalance = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < formula?.length; i++) {
    const current = formula[i];
    const next = formula[i + 1];

    if (checkIsMathOperator(current)) {
      if (i === 0) {
        setError(customGoalFormulaField, { ...DEFAULT_ERROR_OBJ, message: FORMULA_BUILDER_ERROR_MESSAGES.START_WITH_OPERATOR });
        return;
      }
      if (i === formula.length - 1) {
        setError(customGoalFormulaField, { ...DEFAULT_ERROR_OBJ, message: FORMULA_BUILDER_ERROR_MESSAGES.END_WITH_OPERATOR });
        return;
      }
      if (current === '/' && checkIsNumber(next) && _.toNumber(next) === 0) {
        setError(customGoalFormulaField, { ...DEFAULT_ERROR_OBJ, message: FORMULA_BUILDER_ERROR_MESSAGES.DIVISION_BY_ZERO });
        return;
      }
      if (checkIsMathOperator(next)) {
        setError(customGoalFormulaField, { ...DEFAULT_ERROR_OBJ, message: FORMULA_BUILDER_ERROR_MESSAGES.CONSECUTIVE_OPERATORS });
        return;
      }
    }

    if (current === '(') {
      // must have math operator before (
      if (i !== 0 && !checkIsMathOperator(formula[i - 1]) && formula[i - 1] !== '(') {
        setError(customGoalFormulaField, { ...DEFAULT_ERROR_OBJ, message: FORMULA_BUILDER_ERROR_MESSAGES.MISSING_OPERATOR });
        return;
      }
      if (checkIsMathOperator(next)) {
        setError(customGoalFormulaField, { ...DEFAULT_ERROR_OBJ, message: FORMULA_BUILDER_ERROR_MESSAGES.OPERATOR_AFTER_OPEN_PARENTHESIS });
        return;
      }
      if (next === ')') {
        setError(customGoalFormulaField, { ...DEFAULT_ERROR_OBJ, message: FORMULA_BUILDER_ERROR_MESSAGES.EMPTY_PARENTHESES });
        return;
      }
      parenthesisBalance += 1;
    }

    if (current === ')') {
      if (i === 0) {
        setError(customGoalFormulaField, { ...DEFAULT_ERROR_OBJ, message: FORMULA_BUILDER_ERROR_MESSAGES.START_WITH_CLOSING_PARENTHESIS });
        return;
      }
      // must have math operator after )
      if (next && !checkIsMathOperator(next) && next !== ')') {
        setError(customGoalFormulaField, { ...DEFAULT_ERROR_OBJ, message: FORMULA_BUILDER_ERROR_MESSAGES.MISSING_OPERATOR });
        return;
      }
      // must not have math operator before )
      if (i !== 0 && checkIsMathOperator(formula[i - 1])) {
        setError(customGoalFormulaField, { ...DEFAULT_ERROR_OBJ, message: FORMULA_BUILDER_ERROR_MESSAGES.OPERATOR_BEFORE_CLOSING_PARENTHESIS });
        return;
      }
      parenthesisBalance -= 1;
    }

    if (checkIsMetric(current)) {
      metricCount += 1;
      if (checkIsMetric(next)) {
        setError(customGoalFormulaField, { ...DEFAULT_ERROR_OBJ, message: FORMULA_BUILDER_ERROR_MESSAGES.CONSECUTIVE_METRICS });
        return;
      }
    }

    if (checkIsNumber(current) && checkIsNumber(next)) {
      setError(customGoalFormulaField, { ...DEFAULT_ERROR_OBJ, message: FORMULA_BUILDER_ERROR_MESSAGES.CONSECUTIVE_NUMBERS });
      return;
    }
    // closing parenthesis without matching opening parenthesis
    if (parenthesisBalance < 0) {
      setError(customGoalFormulaField, { ...DEFAULT_ERROR_OBJ, message: FORMULA_BUILDER_ERROR_MESSAGES.UNBALANCED_PARENTHESES });
      return;
    }
  }
  // opening parenthesis without matching closing parenthesis
  if (parenthesisBalance !== 0) {
    setError(customGoalFormulaField, { ...DEFAULT_ERROR_OBJ, message: FORMULA_BUILDER_ERROR_MESSAGES.UNBALANCED_PARENTHESES });
    setValidatingFalse(validating);
    return;
  }

  if (metricCount < 2) {
    setError(customGoalFormulaField, { ...DEFAULT_ERROR_OBJ, message: FORMULA_BUILDER_ERROR_MESSAGES.NOT_ENOUGH_METRICS });
  }
  setValidatingFalse(validating);
};
