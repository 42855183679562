/* eslint-disable no-param-reassign */
import _ from 'lodash';
import React, { Dispatch, SetStateAction, MutableRefObject } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Image, WppButton, WppCard, WppTypography } from 'buildingBlocks';
import { GOAL_TYPES } from 'constantsBase';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { DspToPixel } from 'containers/StrategyWizard/components/PixelPicker/types';
import { COPILOT_LAYOUT } from 'globalStyles';
import { GoalType } from 'utils/types';
import { Configuration } from './GoalConfiguration';
import { ARROW_DOWN_RIGHT_SRC, ARROW_UP_RIGHT_SRC, NEW_GOAL_SRC } from '../constants';
import { GOAL_SECTION_STYLES } from '../styles';
import { isCYODGoalTypeValue, isAWGGoalType } from '../utils';
import GoalTag from '../GoalTag';
import { getFormulaText } from './AWG/ConfirmationModal';

const { SPACING } = COPILOT_LAYOUT;

const { cardContent, newText, cardDescription, descHeader, newadvancedgoal } = GOAL_SECTION_STYLES;

type GoalCardProps = GoalType & {
  selected?: boolean
  confirmed?: boolean
  setConfirmedGoal: Dispatch<SetStateAction<string>>
  impValueFilters: DspToPixel
  cyodRef: MutableRefObject<boolean>
  setAWGModalOpen: (x: any) => void
  awgRef: MutableRefObject<boolean>
  setCYODModalOpen?: (x: any) => void
};

const GoalCard = ({
  strategyWizardAbbreviation,
  strategyWizardLongText,
  value,
  valueType,
  label,
  lowerIsBetter = false,
  successEvent,
  selected = false,
  confirmed = false,
  setConfirmedGoal,
  impValueFilters,
  cyodRef,
  setCYODModalOpen,
  setAWGModalOpen,
  awgRef,
}: GoalCardProps) => {
  const { control, setValue } = useFormContext<WizardFormGoalSelection>();

  const customGoal = useWatch({ name: 'customGoal', control });
  const metricsConfig = useWatch({ name: 'metricsConfig', control });

  const isCyodGoalType = isCYODGoalTypeValue(value);
  const isImpactGoal = _.isEqual(value, GOAL_TYPES.impactOutcome.value);
  const isAWGGoal = isAWGGoalType(value);
  const isAwgOrImpact = isImpactGoal || isAWGGoal;
  const showConfigureButton = (isCyodGoalType || isAwgOrImpact) && confirmed;
  const showAwgFormula = isAwgOrImpact && customGoal && metricsConfig && confirmed;
  const isNewGoal = GOAL_TYPES[value]?.isNewAdvanced;
  const displayCustomGoalName = isAWGGoal && metricsConfig && !_.isEqual(customGoal?.value, GOAL_TYPES.impactOutcome.value);

  const handleConfirmClick = () => {
    if (setCYODModalOpen && isCyodGoalType) {
      setCYODModalOpen(true);
    }
    if (isAwgOrImpact) {
      setAWGModalOpen(true);
    }
    setConfirmedGoal(value);
  };

  const handleClick = () => {
    // reinitialize the cyodRef
    cyodRef.current = null;
    awgRef.current = null;
    setValue('goal.type', value);
  };

  const handleImageSrc = () => {
    if (isAwgOrImpact) return ARROW_UP_RIGHT_SRC;
    if (isCyodGoalType) return ARROW_UP_RIGHT_SRC;
    if (lowerIsBetter) return ARROW_DOWN_RIGHT_SRC;
    return ARROW_UP_RIGHT_SRC;
  };

  return (
    <WppCard
      onClick={handleClick}
      value={value}
      className={confirmed && 'modalconfiguregoal'}
      style={{ height: '100%' }}
      interactive={!confirmed}
    >
      <div style={confirmed ? { height: '0%' } : cardContent} onClick={showConfigureButton ? undefined : handleConfirmClick}>
        {(isNewGoal && !confirmed && !selected) && (
          <div style={newadvancedgoal}>
            <Image src={NEW_GOAL_SRC} style={{ marginRight: SPACING[2] }} />
            <WppTypography type="m-strong" style={newText}>NEW</WppTypography>
          </div>
        )}
        <WppTypography type="m-strong" tag="span" style={{ marginRight: '22px' }}>{strategyWizardAbbreviation}</WppTypography>
        <div style={cardDescription}>
          <Image src={handleImageSrc()} style={{ marginRight: SPACING[8] }} />
          <WppTypography type="xs-body" tag="p">{displayCustomGoalName ? _.get(customGoal, 'name') : strategyWizardLongText}</WppTypography>
        </div>
        {showAwgFormula && (
          <div style={{ marginTop: SPACING[16] }}>
            <WppTypography type="s-midi" tag="span">Formula</WppTypography>
            <WppTypography type="m-strong" tag="span" style={descHeader}>{getFormulaText(customGoal?.formula, metricsConfig)}</WppTypography>
          </div>
        )}
        <GoalTag tag={successEvent} />
        {confirmed && (
          <Configuration
            control={control}
            valueType={valueType}
            label={label}
            value={value}
            impValueFilters={impValueFilters}
            showAwgFormula={showAwgFormula}
            isImpactGoal={isImpactGoal}
          />
        )}
        {showConfigureButton && selected && (!confirmed || showConfigureButton) && (
          <WppButton
            className="configureButton"
            variant="secondary"
            size="s"
            onClick={handleConfirmClick}
          >
            {showConfigureButton && 'Configure'}
          </WppButton>
        )}
      </div>
    </WppCard>
  );
};

export default GoalCard;
