import { ReactNode } from 'react';
import { Map as ImmutableMap } from 'immutable';
import { AWGDimensions } from 'constantsBase';
import { CustomFormError, FormulaType, MetricConfig, MetricConfigObj } from 'containers/StrategyWizard/types';

// Types for AWGProvider
export enum AWGActionTypes {
  UPDATE_SELECTED_FORMULA = 'UPDATE_SELECTED_FORMULA',
  UPDATE_FORMULA_METRICS = 'UPDATE_FORMULA_METRICS',
}

export type AWGProviderProps = {
  children: ReactNode
};

export type AWGState = {
  formulaMetrics: MetricConfig
  selectedFormula: FormulaType
};

export type AWGAction = {
  type: AWGActionTypes.UPDATE_FORMULA_METRICS
  payload: MetricConfig
}
| {
  type: AWGActionTypes.UPDATE_SELECTED_FORMULA
  payload: Partial<FormulaType> | FormulaType
};

// Types for WeightsSectionProvider
export enum WeightsSectionActionTypes {
  UPDATE_SELECTED_METRIC = 'UPDATE_SELECTED_METRIC',
  UPDATE_SELECTED_DIMENSION = 'UPDATE_SELECTED_DIMENSION',
  UPDATE_SESSION_METRICS = 'UPDATE_SESSION_METRICS',
  UPDATE_AWG_WEIGHTS_MODAL_OPEN = 'UPDATE_AWG_WEIGHTS_MODAL_OPEN',
  UPDATE_WEIGHTED_METRIC_SELECTED = 'UPDATE_WEIGHTED_METRIC_SELECTED',
  UPDATE_WEIGHTED_DIMENSION_SELECTED = 'UPDATE_WEIGHTED_DIMENSION_SELECTED',
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  EDIT_WEIGHTED_METRIC = 'EDIT_WEIGHTED_METRIC',
  UPDATE_SELECTED_EVENTS = 'UPDATE_SELECTED_EVENTS',
}

export type WeightsSectionProviderProps = {
  children: ReactNode
  isCampaignOptType: boolean
};

export type WeightsSectionState = {
  awgWeightsModalOpen: boolean
  weightedMetricSelected: string
  weightedDimensionSelected: AWGDimensions
  selectedMetric: string
  selectedDimension: AWGDimensions
  sessionMetrics: ImmutableMap<string, MetricConfigObj>
};

export type WeightsSectionAction = {
  type: WeightsSectionActionTypes.UPDATE_SELECTED_METRIC
  payload: string
}
| {
  type: WeightsSectionActionTypes.UPDATE_SELECTED_DIMENSION
  payload: AWGDimensions
}
| {
  type: WeightsSectionActionTypes.UPDATE_SESSION_METRICS
  payload: ImmutableMap<string, MetricConfigObj>
}
| {
  type: WeightsSectionActionTypes.UPDATE_AWG_WEIGHTS_MODAL_OPEN
  payload: boolean
}
| {
  type: WeightsSectionActionTypes.UPDATE_WEIGHTED_METRIC_SELECTED
  payload: string
}
| {
  type: WeightsSectionActionTypes.UPDATE_WEIGHTED_DIMENSION_SELECTED
  payload: AWGDimensions
}
| { type: WeightsSectionActionTypes.OPEN_MODAL }
| {
  type: WeightsSectionActionTypes.CLOSE_MODAL
  payload: {
    sessionMetrics: ImmutableMap<string, MetricConfigObj>
    selectedDimension: AWGDimensions
  }
}
| {
  type: WeightsSectionActionTypes.EDIT_WEIGHTED_METRIC
  payload: {
    name: string
    dimension: AWGDimensions
  }
}
| {
  type: WeightsSectionActionTypes.UPDATE_SELECTED_EVENTS
  payload: Object
};

// Types for WeightsModalContentProvider
export enum ModalContentActionTypes {
  UPDATE_ATTEMPT_SAVE = 'UPDATE_ATTEMPT_SAVE',
  UPDATE_FORM_ERRORS = 'UPDATE_FORM_ERRORS',
}

export type WeightsModalContentProviderProps = {
  children: ReactNode
  sessionMetrics: ImmutableMap<string, MetricConfigObj>
};

export type ModalContentState = {
  attemptSave: boolean
  formErrors: CustomFormError
};

export type ModalContentAction = {
  type: string
  payload: boolean | CustomFormError
};

// Types for FormulaProvider
export enum FormulaActionTypes {
  UPDATE_DROP_ITEMS = 'UPDATE_DROP_ITEMS',
  ADD_NEW_ITEM = 'ADD_NEW_ITEM',
  UPDATE_DROPZONE_ACTIVE = 'UPDATE_DROPZONE_ACTIVE',
  UPDATE_CURSOR_IDX = 'UPDATE_CURSOR_IDX',
  ADD_UNDO_ACTION = 'ADD_UNDO_ACTION',
  ADD_REDO_ACTION = 'ADD_REDO_ACTION',
  UPDATE_UNDO_ACTIONS = 'UPDATE_UNDO_ACTIONS',
  UPDATE_REDO_ACTIONS = 'UPDATE_REDO_ACTIONS',
  UPDATE_CHARACTER_COUNT = 'UPDATE_CHARACTER_COUNT',
}

export enum ActionTypes {
  add = 'add',
  delete = 'delete',
  swap = 'swap',
}

export type FormulaBuilderAction = {
  action: ActionTypes
  contentId: string
  contentIdx: number
  content: string
  cursorIdx: number
};

export type FormulaState = {
  dropItems: Array<DraggableItem>
  dropzoneActive: boolean
  cursorIdx: number
  undoActions: Array<FormulaBuilderAction>
  redoActions: Array<FormulaBuilderAction>
  characterCount: number
};

export type DraggableItem = {
  id: string
  content: string
  index?: number
};

export type FormulaAction = {
  type: FormulaActionTypes.ADD_NEW_ITEM
  payload: DraggableItem
}
| {
  type: FormulaActionTypes.UPDATE_DROP_ITEMS
  payload: Array<DraggableItem>
}
| {
  type: FormulaActionTypes.UPDATE_DROPZONE_ACTIVE,
  payload: boolean
}
| {
  type: FormulaActionTypes.UPDATE_CURSOR_IDX
  payload: number
}
| {
  type: FormulaActionTypes.ADD_UNDO_ACTION
  payload: FormulaBuilderAction
}
| {
  type: FormulaActionTypes.ADD_REDO_ACTION
  payload: FormulaBuilderAction
}
| {
  type: FormulaActionTypes.UPDATE_UNDO_ACTIONS
  payload: Array<FormulaBuilderAction>
}
| {
  type: FormulaActionTypes.UPDATE_REDO_ACTIONS
  payload: Array<FormulaBuilderAction>
}
| {
  type: FormulaActionTypes.UPDATE_CHARACTER_COUNT
  payload: number
};
