import React from 'react';
import { getChildDisplayNameByDsp } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { BasicStratInfoList } from './ConfirmHeader';

type Props = {
  dsp: number
  intelligentChildObjects?: boolean,
};

const BudgetOptimization = ({ intelligentChildObjects, dsp }: Props) => {
  const displayName = getChildDisplayNameByDsp(dsp);
  return (
    <BasicStratInfoList
      first={`Intelligent ${displayName}`}
      second={intelligentChildObjects ? 'ON' : 'OFF'}
    />
  );
};

export default BudgetOptimization;
