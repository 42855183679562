import _ from 'lodash';
import React from 'react';
import numeral from 'numeral';
import { WppActionButton, WppGrid, WppInlineMessage, WppTable, WppTableBody, WppTableBodyCell, WppTableBodyRow, WppTableHeader, WppTableHeaderCell, WppTableHeaderRow, WppTypography } from 'buildingBlocks';
import { MetricsFormattingConstants } from 'constantsBase';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { wppBodyContainer } from 'components/PageTemplate/style';
import { BudgetSetting } from 'containers/StrategyWizard/types';
import { BudgetTypes } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { displayDate } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { formatNumber } from 'utils/formattingUtils';
import { STRATEGY_CONFIRMATION_STYLES } from '../styles';
import { displayDescription } from '../utils';

const { column } = strategyWizardStyles;

type Props = {
  goToStep: () => void,
  budgetSettings: Array<BudgetSetting>
  defaultCurrency: string
  flightExtType: number
  flightTimezone: string
};

const BudgetIntervals = ({
  goToStep,
  budgetSettings,
  defaultCurrency,
  flightExtType,
  flightTimezone,
}: Props) => {
  // if there was an issue getting the budgetSettings an empty array would be returned
  const failedToGetBudgetSettings = _.isEmpty(budgetSettings);

  return (
    <WppGrid item all={24}>
      <div style={wppBodyContainer}>
        <div style={STRATEGY_CONFIRMATION_STYLES.budgetHeaderSection}>
          <WppTypography type="xl-heading">Budget Intervals</WppTypography>
          <WppActionButton onClick={goToStep}>Edit</WppActionButton>
        </div>
        {failedToGetBudgetSettings
          ? (
            <p style={STRATEGY_CONFIRMATION_STYLES.errorMsg}>Error fetching Budget Intervals</p>
          ) : (
            <>
              <WppTable className="confirm-wpp-table" style={{ ...STRATEGY_CONFIRMATION_STYLES.budgetTableSectionStyle, width: '367px' }}>
                <WppTableHeader>
                  <WppTableHeaderRow key={column.value}>
                    <WppTableHeaderCell style={STRATEGY_CONFIRMATION_STYLES.date}>
                      <WppTypography type="s-strong" tag="p">Start Date</WppTypography>
                    </WppTableHeaderCell>
                    <WppTableHeaderCell style={STRATEGY_CONFIRMATION_STYLES.date}>
                      <WppTypography type="s-strong" tag="p">End Date</WppTypography>
                    </WppTableHeaderCell>
                    <WppTableHeaderCell style={STRATEGY_CONFIRMATION_STYLES.budgetIntervalBudgetCell}>
                      <WppTypography type="s-strong" tag="p">Budget</WppTypography>
                    </WppTableHeaderCell>
                  </WppTableHeaderRow>
                </WppTableHeader>
                <WppTableBody>
                  {_.map(budgetSettings, ({ startDate, endDate, budget, type }: BudgetSetting, i: number) => {
                    const budgetTypeInImps = _.isEqual(type, BudgetTypes.imps);
                    const budgetLabel = budgetTypeInImps ? _.upperCase(BudgetTypes.imps) : defaultCurrency;
                    const intervalBudget = budgetTypeInImps ? formatNumber(budget) : numeral(budget).format(MetricsFormattingConstants.TWO_DECIMALS);
                    return (
                      <WppTableBodyRow key={i + 1} style={STRATEGY_CONFIRMATION_STYLES.confirmStepTableStyle}>
                        <WppTableBodyCell>
                          <WppTypography type="s-body" tag="p">{displayDate(startDate, flightExtType, flightTimezone)}</WppTypography>
                        </WppTableBodyCell>
                        <WppTableBodyCell>
                          <WppTypography type="s-body" tag="p">{displayDate(endDate, flightExtType, flightTimezone)}</WppTypography>
                        </WppTableBodyCell>
                        <WppTableBodyCell>
                          <WppTypography type="s-body" tag="p">{`${intervalBudget} ${budgetLabel}`}</WppTypography>
                        </WppTableBodyCell>
                      </WppTableBodyRow>
                    );
                  })}
                </WppTableBody>
              </WppTable>
              <WppInlineMessage
                size="s"
                message={displayDescription(budgetSettings.length, 'Interval')}
                type="information"
                style={STRATEGY_CONFIRMATION_STYLES.confirmStepInlineMessage}
              />
            </>
          )}
      </div>
    </WppGrid>
  );
};

export default BudgetIntervals;
