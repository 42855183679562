import _ from 'lodash';
import { FLIGHT_EXTERNAL_TYPE } from 'constantsBase';
import { AMZNCampaign, APNInsertionOrder, DBMInsertionOrder, TTDCampaign, WMTCampaign } from 'utils/copilotAPI';
import { toCamelCase } from 'utils/formattingUtils';
import useFetcher, { PossibleStates, State } from 'utils/hooks/useFetcher';

export const budgetIntervalsFetcher = (
  flightExtType: number,
  flightExtId: string,
): State => {
  const fetchFlight = async (): Promise<State> => {
    let resource;
    switch (flightExtType) {
      case FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id:
        resource = APNInsertionOrder;
        break;
      case FLIGHT_EXTERNAL_TYPE.ttdCampaign.id:
        resource = TTDCampaign;
        break;
      case FLIGHT_EXTERNAL_TYPE.wmtCampaign.id:
        resource = WMTCampaign;
        break;
      case FLIGHT_EXTERNAL_TYPE.amznCampaign.id:
        resource = AMZNCampaign;
        break;
      case FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id:
      default:
        resource = DBMInsertionOrder;
    }
    const response = await resource.get({ externalId: flightExtId });
    // @ts-ignore
    const formattedBudgetIntervals = _.map(_.get(_.first(response.data), 'normBudgetIntervals'), (b) => _.transform(b, (result, value, key: string) => {
      // eslint-disable-next-line no-param-reassign
      result[toCamelCase(key)] = value;
    }, {}));
    return {
      kind: PossibleStates.hasData,
      data: formattedBudgetIntervals,
    };
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useFetcher(fetchFlight, [flightExtType, flightExtId]);
};
