import _ from 'lodash';
import { SetStateAction } from 'react';
import { Dispatch } from 'redux';
import numeral from 'numeral';
import { NavigateFunction } from 'react-router';
import {
  saveStrategyFlightSucceeded,
  saveStrategyFlightFailed,
  saveStrategyFailed,
} from 'containers/StrategiesList/actions';
import { FLIGHT_DETACH_STATUS } from 'containers/StrategyWizard/constants';
import {
  getStrategyTypeMapperById,
  getStrategyTypeTasksById,
  transformBudgetSettings,
  transformStrategyGoals,
} from 'containers/StrategyWizard/ConfigurationByStrategyType/utils';
import { FlightCategory } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { STRATEGY_TYPE, StrategySource, MetricsFormattingConstants, CYOD_GOAL_TYPES, GOAL_TYPES } from 'constantsBase';
import { StrategyConfigurationStep, WizardFormValues } from 'containers/StrategyWizard/types';
import { createStrategy, isCrossPlatformStrategyType } from 'containers/StrategyWizard/utils';
import { COPILOT_COLORS } from 'globalStyles';
import { remap } from 'utils/airflow/nameTranslator';
import { pluralizer } from 'utils/formattingUtils';
import { Strategy } from 'utils/copilotAPI';
import { Advertiser, Brand, Currency, Flight, Member, Strategy as CopilotStrategy, StrategyConfig } from 'utils/types';
import { RevenueTypeOutcomeOptions } from '../GoalSelection/constants';
import { isCYODGoalTypeValue } from '../GoalSelection/utils';

const {
  NEW_DESIGN_SYSTEM: {
    NEUTRALS,
    REDS,
    GREENS,
  },
} = COPILOT_COLORS;

const getPrevAttachedFlights = (
  allAttachedFlights: Array<Flight>,
  newlyAttachedFlights: Array<Flight>,
) => (_.differenceWith(allAttachedFlights, newlyAttachedFlights, _.isEqual));

const getConfigToSave = (
  strategyTypeId: number,
  strategyConfigurationValues: StrategyConfigurationStep | StrategyConfig,
  member: Member,
  advertiser: Advertiser,
  brand: Brand,
  defaultCurrency: Currency,
  attachedFlights: Array<Flight>,
  updateAdminConfig: boolean,
  hasRevenueType: boolean,
) => {
  const mapper = getStrategyTypeMapperById(strategyTypeId);
  const config = mapper.createConfig(strategyConfigurationValues, advertiser, member, brand, defaultCurrency?.code, attachedFlights, updateAdminConfig, hasRevenueType);
  const configToSave = _.pick(remap(mapper, config), getStrategyTypeTasksById(strategyTypeId));
  return configToSave;
};

const getSavedStrategy = async (strategyToSave, flights, strategyId) => {
  const params = {
    strategy: { ...strategyToSave, id: strategyId, strategySource: StrategySource.webApp },
    ...flights,
  };
  const saved = await Strategy.strategyConfiguration(params);
  return saved.data;
};

enum SaveStrategyStatus {
  success = 'success',
  flightError = 'flightError',
  error = 'error',
}

type SaveStrategyResponse =
  | { kind: SaveStrategyStatus.success; action: Function; data: CopilotStrategy }
  | { kind: SaveStrategyStatus.flightError; action: Function }
  | { kind: SaveStrategyStatus.error; action: Function };

const saveStrategy = async (
  strategyToSave,
  flights,
  strategyId,
): Promise<SaveStrategyResponse> => {
  try {
    const saved = await getSavedStrategy(strategyToSave, flights, strategyId);
    const { id, name } = saved.strategy;
    if (!_.isEmpty(saved.detachResponse) && saved.detachResponse === FLIGHT_DETACH_STATUS.FAILED) {
      return { kind: SaveStrategyStatus.flightError, action: _.partial(saveStrategyFlightFailed, id, name) };
    }
    return { kind: SaveStrategyStatus.success, action: _.partial(saveStrategyFlightSucceeded, id, name), data: saved };
  } catch (error) {
    return { kind: SaveStrategyStatus.error, action: _.partial(saveStrategyFailed, strategyId, strategyToSave.name) };
  }
};

// update form values with revenue, pixel and strategy goals info
export const modifyFormValues = (strategyConfigurationValues, goalSelectionStep, strategyType) => {
  const impValueFilters = _.get(goalSelectionStep, 'goal.impValueFilters');
  const cyodEnabled = isCYODGoalTypeValue(goalSelectionStep.goal.type);
  const budgetSettings = transformBudgetSettings(_.get(goalSelectionStep, 'budgetSettings'));
  const strategyGoals = transformStrategyGoals(strategyConfigurationValues, goalSelectionStep, strategyType.id);
  const modifiedFormValues = { ...strategyConfigurationValues, strategyGoals, impValueFilters, budgetSettings, cyodEnabled };

  return modifiedFormValues;
};

/**
 * handles saving of the strategy. regarding the flights we want to send to the API:
 *  - addedFlights: these are any new flights added from the flight candidate dropdown or bulk uploader
 *  - removedFlightIds: the ids of the flights that had previously been attached but got removed by the user
 *  - attachedFlights: the flights that were already attached, including expired and ineligible flights.
 *
 * @param strategyId strategy id
 * @param wizardForm the final values of the wizard form
 */
export const handleWizardSubmit = async (
  strategyId: number,
  wizardForm: WizardFormValues,
  navigate: NavigateFunction,
  dispatch: Dispatch<SetStateAction<any>>,
  updateAdminConfig: boolean,
) => {
  const {
    attachFlightsStep: {
      defaultCurrency,
      attachedFlights,
      eligibleFlights,
      toBeDetached,
    },
    goalSelectionStep,
    strategyTypeSelectionStep: { strategyType },
    strategyConfigurationStep: strategyConfigurationValues,
  } = wizardForm;
  const member = _.get(wizardForm.attachFlightsStep, 'member');
  const advertiser = _.get(wizardForm.attachFlightsStep, 'advertiser');
  const brand = _.get(wizardForm.attachFlightsStep, 'brand');
  const reactivatedFlights = _.get(wizardForm.attachFlightsStep, 'reactivatedFlights', []);
  const toBeDeactivated = _.get(wizardForm.attachFlightsStep, 'toBeDeactivated', []);
  const hasRevenueType = !!_.size(_.get(goalSelectionStep, 'budget'));
  const strategyTypeId = strategyType.id;
  const isCrossPlatformStrat = isCrossPlatformStrategyType(strategyTypeId);
  const flightsToRemove = isCrossPlatformStrat ? toBeDeactivated : toBeDetached;

  const flightsToSave = {
    addedFlights: eligibleFlights,
    removedFlightIds: _.map(flightsToRemove, 'id'),
    attachedFlights: getPrevAttachedFlights(attachedFlights, eligibleFlights),
    reactivatedFlightIds: _.map(reactivatedFlights, 'id'), // specific to cross-platform strategies
  };

  const modifiedStratConfigFormValues = modifyFormValues(strategyConfigurationValues, goalSelectionStep, strategyType);
  const configToSave = getConfigToSave(strategyTypeId, modifiedStratConfigFormValues, member, advertiser, brand, defaultCurrency, attachedFlights, updateAdminConfig, hasRevenueType);
  const strategyToSave = createStrategy(wizardForm, configToSave);

  if (!strategyId && _.includes([STRATEGY_TYPE.helios.id, STRATEGY_TYPE.heliosSegmentRecency.id], strategyTypeId)) {
    strategyToSave.flightExternalType = !_.isEmpty(attachedFlights) && _.head(attachedFlights).externalType;
  }

  const saveStrategyRes = await saveStrategy(strategyToSave, flightsToSave, strategyId);

  // update the store with name / id so they can be shown on the list page, then redirect
  dispatch(saveStrategyRes.action());
  navigate('/');
};

export const isBudgetOptimization = (strategyTypeId: number) => {
  // does not include cross-platform on purpose
  switch (strategyTypeId) {
    case STRATEGY_TYPE.ttdBudgetOptimization.id:
    case STRATEGY_TYPE.apnBudgetOptimization.id:
    case STRATEGY_TYPE.dbmBudgetOptimization.id:
    case STRATEGY_TYPE.amznBudgetOptimization.id:
    case STRATEGY_TYPE.wmtBudgetOptimization.id:
      return true;
    default:
      return false;
  }
};

export const displayDescription = (sectionLength: number, sectionDescription: string) => {
  const pluralize = pluralizer(sectionDescription, `${sectionDescription}s`);
  return `${sectionLength} Budget ${pluralize(sectionLength)}`;
};

export const getFlightDescriptionAndColor = (flights: Array<Flight>, flightCategory: string, dspCount: number = 0) => {
  const flightsLen = flights.length;
  const pluralizeObject = pluralizer('object', 'objects')(flightsLen);
  const pluralizePlatform = pluralizer('platform', 'platforms')(dspCount);
  const pluralizeIs = pluralizer('is', 'are')(flightsLen);

  const flightCountDesc = `${flightsLen} ${pluralizeObject}`;
  const activeDesc = `${pluralizeIs} active and will be optimized by Copilot.`;

  const attachedToThisStrategyDesc = dspCount > 1
    ? `${flightCountDesc} across ${dspCount} ${pluralizePlatform} ${activeDesc}`
    : `${flightCountDesc} ${activeDesc}`;

  switch (flightCategory) {
    case (FlightCategory.deactivatedFlights):
      return {
        color: NEUTRALS.N150_GALLERY,
        desc: `${flightCountDesc} ${pluralizeIs} inactive and will not be optimized by Copilot.`,
      };
    case (FlightCategory.ineligibleFlights):
      return {
        color: NEUTRALS.N150_GALLERY,
        desc: `${flightCountDesc} ${pluralizeIs} expired as there are no active budget intervals.`,
      };
    case (FlightCategory.toBeDeactivated):
      return {
        color: REDS.R50_VISAGE,
        desc: `${flightCountDesc} will be deactivated after the strategy is saved.`,
      };
    case (FlightCategory.toBeDetached):
      return {
        color: REDS.R50_VISAGE,
        desc: `${flightCountDesc} will be detached after the strategy is saved.`,
      };
    case (FlightCategory.reactivatedFlights):
      return {
        color: GREENS.G50_CANE,
        desc: `${flightCountDesc} will be activated after the strategy is saved.`,
      };
    case (FlightCategory.eligibleFlights):
      return {
        color: GREENS.G50_CANE,
        desc: `${flightCountDesc} will be attached and optimized by Copilot.`,
      };
    case (FlightCategory.attachedToThisStrategy):
    default:
      return {
        color: NEUTRALS.N0_WHITE,
        desc: attachedToThisStrategyDesc,
      };
  }
};

export const decimalFormatting = (decimalPlaces: number) => {
  switch (true) {
    case decimalPlaces > 3:
      return MetricsFormattingConstants.ROUNDED_FOUR_DECIMALS;
    case decimalPlaces === 3:
      return MetricsFormattingConstants.THREE_DECIMALS;
    case decimalPlaces === 2:
      return MetricsFormattingConstants.TWO_DECIMALS;
    case decimalPlaces === 1:
      return MetricsFormattingConstants.ONE_DECIMAL;
    default:
      return MetricsFormattingConstants.NO_DECIMALS;
  }
};

export const revenueGoalStratInfo = (val: string | number, goalType: string, currencyCode: string, isRevenueType: boolean = false, isCYODGoalType: boolean = false, revenueType: string = null, isCustomGoal: boolean = false) => {
  const cyodGoalTypePrefix = isCYODGoalType ? 'Upload External Measurement: ' : '';
  let outputGoalInfo = `${cyodGoalTypePrefix}${isRevenueType ? _.toString(goalType).replace('Net ', '') : goalType}`;
  if (_.endsWith(_.toString(val), '%')) {
    return `${outputGoalInfo} ${numeral(val).format(MetricsFormattingConstants.PERCENTAGE_TWO_DECIMALS)}`;
  }
  const textVal = _.toString(val);
  const index = textVal.indexOf('.');
  const decPlaces = index !== -1 ? textVal.length - index - 1 : 0;

  if (isCustomGoal) {
    if (isRevenueType) outputGoalInfo = revenueType;
    return `${_.toUpper(outputGoalInfo)} ${numeral(val).format(decimalFormatting(decPlaces))}`;
  }
  return `${outputGoalInfo} ${numeral(val).format(decimalFormatting(decPlaces))} ${currencyCode}`;
};

export const cyodIsEnabledCheck = (goalTypeVal: string, formValues: { cyodEnabled?: boolean }) => {
  // goalTypeVal is snake cased when sending configuration to backend
  const goalTypeIsCyodDBGoal = _.includes(CYOD_GOAL_TYPES, _.camelCase(goalTypeVal));
  return isCYODGoalTypeValue(goalTypeVal) || (goalTypeIsCyodDBGoal && _.get(formValues, 'cyodEnabled'));
};

export const hasRevenueTypeCheck = (goalType: string, formValues: StrategyConfigurationStep | StrategyConfig, hasRevenueType: boolean) => (
  (_.startsWith(goalType, 'net') || _.isEqual(goalType, GOAL_TYPES.margin.value))
  || (_.isEqual(_.get(formValues, 'hasCustomRevenueType'), 'Yes')) || _.isBoolean(_.get(formValues, 'hasCustomRevenueType'))
  || hasRevenueType);

export const shouldAddTargetingPlusIds = (intelligentChildObjects: boolean, formValues: StrategyConfigurationStep | StrategyConfig) => (
  _.isBoolean(intelligentChildObjects) && (!_.isEqual(_.get(formValues, 'revenueOutcomeType'), RevenueTypeOutcomeOptions.multiple))
);
