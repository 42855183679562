import _ from 'lodash';
import React from 'react';
import { WppInlineMessage, WppTable, WppTableBodyCell, WppTableBodyRow, WppTableHeader, WppTableHeaderCell, WppTableHeaderRow, WppTypography } from 'buildingBlocks';
import DspIcon from 'components/DspIcon';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { AdvertiserMemberInfo } from 'containers/StrategyWizard/types';
import { FlightCategory } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { STRATEGY_CONFIRMATION_STYLES } from 'containers/StrategyWizard/steps/StrategyConfirmation/styles';
import { truncate } from 'utils/formattingUtils';
import { Flight } from 'utils/types';
import { getFlightDescriptionAndColor } from '../utils';

const { column } = strategyWizardStyles;

type SingleDSPObjectTableProps = {
  flights: Array<Flight>
  advertiserMemberInfo: Array<AdvertiserMemberInfo>
  flightCategory: string
  dspCount?: number
};

const FlightTableHeader = () => (
  <WppTableHeader>
    <WppTableHeaderRow key={column.value}>
      <WppTableHeaderCell style={STRATEGY_CONFIRMATION_STYLES.dspObjectTableHeaderObjectNameStyle}>
        <WppTypography type="s-strong" tag="p">Object Name</WppTypography>
      </WppTableHeaderCell>
      <WppTableHeaderCell style={STRATEGY_CONFIRMATION_STYLES.dspObjectTableHeaderNameStyle}>
        <WppTypography type="s-strong" tag="p">Name</WppTypography>
      </WppTableHeaderCell>
      <WppTableHeaderCell style={STRATEGY_CONFIRMATION_STYLES.dspObjectTableHeaderMemberStyle}>
        <WppTypography type="s-strong" tag="p">Member</WppTypography>
      </WppTableHeaderCell>
      <WppTableHeaderCell style={STRATEGY_CONFIRMATION_STYLES.dspObjectTableHeaderAdvertiserStyle}>
        <WppTypography type="s-strong" tag="p">Advertiser</WppTypography>
      </WppTableHeaderCell>
    </WppTableHeaderRow>
  </WppTableHeader>
);

type FlightRowProps = {
  flight: Flight
  advertiser: AdvertiserMemberInfo
};

const FlightRow = ({ flight, advertiser }: FlightRowProps) => (
  <WppTableBodyRow style={STRATEGY_CONFIRMATION_STYLES.confirmStepTableStyle}>
    <WppTableBodyCell>
      {truncate(flight.name, 140)}
    </WppTableBodyCell>
    <WppTableBodyCell>
      <DspIcon dspId={flight.dsp} />
    </WppTableBodyCell>
    <WppTableBodyCell>
      {advertiser.member.displayName}
    </WppTableBodyCell>
    <WppTableBodyCell>
      {advertiser.name}
    </WppTableBodyCell>
  </WppTableBodyRow>
);

const SingleDSPObjectTable = ({
  flights,
  advertiserMemberInfo,
  flightCategory,
  dspCount,
}: SingleDSPObjectTableProps) => {
  const {
    desc: flightDesc,
  } = getFlightDescriptionAndColor(flights, flightCategory, dspCount);

  return (
    <>
      <WppTable className="confirm-wpp-table" style={STRATEGY_CONFIRMATION_STYLES.budgetTableSectionStyle}>
        {(flightCategory === FlightCategory.attachedToThisStrategy) && (<FlightTableHeader />)}
        {_.map(flights, (flight: Flight, i: number) => {
          const advertiser = _.head(_.filter(advertiserMemberInfo, ['id', flight.advertiser]));
          return advertiser ? <FlightRow key={i + 1} flight={flight} advertiser={advertiser} /> : null;
        })}
      </WppTable>
      <WppInlineMessage
        size="s"
        message={flightDesc}
        type="information"
        style={STRATEGY_CONFIRMATION_STYLES.confirmStepInlineMessage}
      />
    </>
  );
};

export default SingleDSPObjectTable;
