/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Immutable, { Map as ImmutableMap, fromJS } from 'immutable';
import { useSelector } from 'react-redux';
import { useAsyncEffect } from 'utils/functionHelpers';
import { Dimmer, Loader, WppGrid, WppTable, WppTableHeader, WppTableHeaderCell, WppTableHeaderRow, WppTypography } from 'buildingBlocks';
import { AWG_WEIGHTS_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { GlobalState } from 'reducers';
import { AWGDimensions } from 'constantsBase';
import { WizardFormValues, WeightConfig, WeightObj, MetricConfig, PixelsConfig } from 'containers/StrategyWizard/types';
import { getFlightStates } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/GroupSettings/utils';
import { getDimensionText, getModalOptions, isWeighted } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import ErrorMessage from 'components/ErrorComponent/ErrorMessage';
import { PIXEL_PICKER_STYLES } from 'containers/StrategyWizard/components/PixelPicker/styles';
import DspAccordion from './DspAccordion';
import { useWeightsSectionContext } from '../contexts/WeightsSectionProvider';

const DSP_HEADER_TOP = 30;
const ERROR_MSG = 'Error loading pixels. Please try again later.';

const { dimensionWeightContGrid, dimensionWeightGrid, dimensionWeightText, dimensionWeightDsp, dimensionWeightsHead } = PIXEL_PICKER_STYLES;

const DimensionWeights = () => {
  const {
    attachFlightsStep,
    budgetAllocationState,
  } = useSelector<GlobalState>((state) => state.strategyWizard) as WizardFormValues;
  const { sessionMetrics, setSessionMetrics, selectedMetric, selectedDimension } = useWeightsSectionContext();

  const budgetData = _.get(budgetAllocationState, 'data');
  const flightStates = getFlightStates(attachFlightsStep);
  const { validFlights, invalidFlights } = flightStates;
  const flightsToPopulate = [...validFlights, ...invalidFlights];
  const [flightsToDisplay, setFlightsToDisplay] = useState<ImmutableMap<string, WeightObj>>(ImmutableMap());
  const [loading, setLoading] = useState<boolean>(false);
  const [availablePixelsData, setAvailablePixelsData] = useState<PixelsConfig>({});
  const [hasPixelError, setHasPixelError] = useState<boolean>(false);

  useAsyncEffect(async () => {
    if (selectedDimension && !_.isEmpty(budgetData) && _.size(flightsToPopulate)) {
      // Check if weighting is an Immutable.js Map, and convert to a plain JavaScript object if it is
      const weighting = sessionMetrics.getIn([selectedMetric, 'weighting'], {});
      const normalizedWeighting = Immutable.Map.isMap(weighting) ? weighting.toJS() : weighting;

      const flights = await getModalOptions(
        selectedDimension,
        budgetData,
        flightsToPopulate,
        normalizedWeighting,
        availablePixelsData,
        setAvailablePixelsData,
        setLoading,
        setHasPixelError,
      );
      setFlightsToDisplay(fromJS(flights));
    }
  }, [selectedDimension, budgetData, selectedMetric, sessionMetrics.getIn([selectedMetric, 'weighting'])]);

  useEffect(() => {
    if (flightsToDisplay.size) {
      setSessionMetrics(sessionMetrics.mergeDeep({
        [selectedMetric]: {
          isWeighted: isWeighted(flightsToDisplay.toJS()),
        },
      } as MetricConfig));
    }
  }, [flightsToDisplay]);

  const getDspHeaderStyles = (key) => {
    const index = Object.keys(flightsToDisplay).indexOf(key);
    const top = DSP_HEADER_TOP * index;
    return { top: `${top}px` };
  };

  return (
    <WppGrid container fullWidth style={dimensionWeightContGrid}>
      <WppGrid direction="row" style={dimensionWeightGrid}>
        <WppTable>
          <WppTableHeader>
            <WppTableHeaderRow className="wpp-table-header-row">
              <WppTableHeaderCell style={dimensionWeightText}>
                <WppTypography type="s-strong" tag="p">{getDimensionText(selectedDimension)}s</WppTypography>
              </WppTableHeaderCell>
              <WppTableHeaderCell style={dimensionWeightDsp}>
                <WppTypography type="s-strong" tag="p">DSP</WppTypography>
              </WppTableHeaderCell>
              <WppTableHeaderCell style={dimensionWeightsHead}>
                <WppTypography type="s-strong" tag="p">Weights</WppTypography>
              </WppTableHeaderCell>
            </WppTableHeaderRow>
          </WppTableHeader>
          <>
            {(selectedDimension === AWGDimensions.pixel && hasPixelError) && (
              <WppGrid style={AWG_WEIGHTS_MODAL_STYLES.errorContainer}>
                <ErrorMessage
                  errorText={ERROR_MSG}
                  style={AWG_WEIGHTS_MODAL_STYLES.errorMessage}
                />
              </WppGrid>
            )}
            {!hasPixelError && (loading
              ? (
                <Dimmer active inverted>
                  <Loader active inverted inline="centered" />
                </Dimmer>
              ) : _.map(flightsToDisplay.toJS() as WeightConfig, (flights: WeightObj, dspId: string) => (
                <DspAccordion
                  key={dspId}
                  dspId={dspId}
                  flights={flights}
                  headerStyles={getDspHeaderStyles(dspId)}
                  flightsToDisplay={flightsToDisplay}
                  setFlightsToDisplay={setFlightsToDisplay}
                />
              ))
            )}
          </>
        </WppTable>
      </WppGrid>
    </WppGrid>
  );
};

export default DimensionWeights;
