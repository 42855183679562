import React from 'react';
import { WppGrid } from 'buildingBlocks';
import { SegmentGroupsType } from 'utils/types';
import Goal from './Goal';

type HeliosSegmentRecencyConfirmDetailsProps = {
  strategyConfig: {
    segmentGroups: SegmentGroupsType
  }
  goal: {}
  currencyCode: string
  viewability: {
    enabled: boolean
    target?: number
  }
};

export const HeliosSegmentRecencyConfirmDetails = ({
  goal,
  viewability,
}: HeliosSegmentRecencyConfirmDetailsProps) => (
  <div>
    <WppGrid item all={24}>
      <Goal {...goal} viewability={viewability} />
    </WppGrid>
  </div>
);

export default HeliosSegmentRecencyConfirmDetails;
