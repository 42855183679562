import React from 'react';
import { WppGrid } from 'buildingBlocks';
import { AWG_WEIGHTS_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { AWGDimensions } from 'constantsBase';
import MetricTabs from './MetricTabs';
import DimensionsDropdown from './DimensionsDropdown';
import PlatformWeightsList from './PlatformWeightsList';
import DimensionWeights from './DimensionWeights';
import { useWeightsSectionContext } from '../contexts/WeightsSectionProvider';

type WeightsModalContentProps = {
  metrics: Array<string>
};

const WeightsModalContent = ({ metrics }: WeightsModalContentProps) => {
  const { selectedDimension } = useWeightsSectionContext();
  return (
    <WppGrid style={{ height: '100%' }}>
      <MetricTabs metrics={metrics} />
      <div style={AWG_WEIGHTS_MODAL_STYLES.modalRightCard}>
        <DimensionsDropdown />
        {
          selectedDimension === AWGDimensions.dsp
            ? <PlatformWeightsList />
            : <DimensionWeights />
        }
      </div>
    </WppGrid>
  );
};

export default WeightsModalContent;
