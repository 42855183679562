// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CodeMirror {
  min-height: 0;
};`, "",{"version":3,"sources":["webpack://./src/containers/StrategyWizard/steps/StrategyConfirmation/confirmComponents/adminConfig.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf,CAAA","sourcesContent":[".CodeMirror {\n  min-height: 0;\n};"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
