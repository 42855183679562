import React from 'react';
import CustomTreeKoalaBoxContent, { CUSTOM_TREE_MODAL_HEADER } from 'containers/StrategyWizard/ConfigurationByStrategyType/CustomTree/components/ChickenBox';
import { WppButton, WppModal, WppTypography } from 'buildingBlocks';
import { KoalaBoxData } from 'containers/StrategyWizard/types';
import strategyWizardStyles from 'containers/StrategyWizard/styles';

type Props = {
  koalaBoxData: KoalaBoxData
  toggleModal: (prev: boolean) => void
  modalOpen: boolean
  onClick: () => void
};

const ModuleKoalaBox = ({ koalaBoxData, toggleModal, modalOpen, onClick }: Props) => (
  <WppModal size="s" open={modalOpen}>
    <WppTypography type="xl-heading" slot="header">{CUSTOM_TREE_MODAL_HEADER}</WppTypography>
    <p slot="body">
      <CustomTreeKoalaBoxContent {...koalaBoxData} />
    </p>
    <div slot="actions" style={strategyWizardStyles.customTreeModelActionBtnContainer}>
      <WppButton
        variant="secondary"
        onClick={() => toggleModal(modalOpen)}
      >
        No
      </WppButton>
      <WppButton
        onClick={onClick}
      >
        Yes
      </WppButton>
    </div>
  </WppModal>
);

export default ModuleKoalaBox;
