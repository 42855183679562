import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { DSP } from 'constantsBase';
import { useStrategyWizardContext } from 'containers/StrategyWizard/contexts/StrategyWizardProvider';
import { multipleParentFlightsCheck, QSParams } from 'containers/StrategyWizard/utils';
import { PossibleStates } from 'utils/hooks/useFetcher';
import useStrategyTypeFetcher from 'utils/hooks/useStrategyTypeFetcher';
import { Flight, Member, User } from 'utils/types';
import StrategyType from './StrategyType';
import { getStrategyTypeRadioItems } from './utils';
import { EXTERNAL_TYPE_TO_DSP_AND_DEFAULT_OPT_LEVEL } from '../AttachFlights/constants';
import { configuringCrossPlatformStratCheck } from '../AttachFlights/utils';

const setDefaultStrategyType = (populatedStrategyTypes, strategyTypeId, setSelectedStrategyType) => (
  setSelectedStrategyType(_.find(populatedStrategyTypes, { id: Number(strategyTypeId) }))
);

const getDspId = (member: Member, isCrossPlatformOptimization: boolean, attachedFlights: Array<Flight>) => {
  if (isCrossPlatformOptimization) {
    return DSP.MULTIPLE.id;
  }
  if (member) {
    return member.dsp;
  }
  const firstFlightsExtType = _.get(_.head(attachedFlights), 'externalType');
  return _.get(EXTERNAL_TYPE_TO_DSP_AND_DEFAULT_OPT_LEVEL, `${firstFlightsExtType}.dsp`);
};

const StrategyTypeSelection = ({ qsParams }: { qsParams: QSParams }) => {
  const user = useSelector<GlobalState>((state) => state.login.user) as User;
  const {
    wizardFormValues: {
      attachFlightsStep,
      goalSelectionStep: { goal },
      strategyTypeSelectionStep: { strategyType },
    },
  } = useStrategyWizardContext();
  const { member, optimizationLevel, attachedFlights, selectedOptType } = attachFlightsStep;
  const isCrossPlatformOptimization = multipleParentFlightsCheck(attachedFlights) || configuringCrossPlatformStratCheck(selectedOptType);
  const dspId = getDspId(member, isCrossPlatformOptimization, attachedFlights) as number;

  const [selectedStrategyType, setSelectedStrategyType] = useState(strategyType);
  const strategyTypeState = useStrategyTypeFetcher(attachedFlights, dspId, selectedStrategyType, setDefaultStrategyType, optimizationLevel, goal.type, setSelectedStrategyType, goal.impValueFilters, isCrossPlatformOptimization);

  const disabled = !selectedStrategyType;
  const commonProps = { dspId, selectedStrategyType, optimizationLevel, disabled, setSelectedStrategyType, qsParams };

  useEffect(() => {
    if (!selectedStrategyType && strategyTypeState.kind === PossibleStates.hasData) {
      if (_.size(strategyTypeState.data) === 1) {
        setSelectedStrategyType(_.head(strategyTypeState.data));
      } else {
        setDefaultStrategyType(strategyTypeState.data, optimizationLevel?.defaultStrat, setSelectedStrategyType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategyTypeState]);

  switch (strategyTypeState.kind) {
    case PossibleStates.hasData:
    {
      const strategyTypeItems = getStrategyTypeRadioItems(strategyTypeState.data, member, user, optimizationLevel, isCrossPlatformOptimization);
      const strategyTypeIds = strategyTypeItems.map((a) => a.id);

      if (!_.includes(strategyTypeIds, selectedStrategyType?.id)) {
        commonProps.disabled = true;
      }

      return (
        <StrategyType
          items={strategyTypeItems}
          loading={false}
          strategyTypesToDisable={_.get(strategyTypeState, 'restrictions')}
          defaultValue={strategyType}
          {...commonProps}
        />
      );
    }
    case PossibleStates.error:
      return (
        <StrategyType
          loading={false}
          error={_.toString(strategyTypeState.errorObject)}
          {...commonProps}
        />
      );
    case PossibleStates.loading:
    default:
      return (
        <StrategyType
          loading
          {...commonProps}
        />
      );
  }
};

export default StrategyTypeSelection;
