import _ from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Form, Message, WppDivider, WppGrid, WppTypography } from 'buildingBlocks';
import { wppBodyContainer } from 'components/PageTemplate/style';
import NavFooter from 'containers/StrategyWizard/components/NavFooter';
import { SELECT_STRATEGY_TYPE, WizardSteps, WIZARD_STEPS, WIZARD_BASE } from 'containers/StrategyWizard/constants';
import { QSParams, updateQueryString } from 'containers/StrategyWizard/utils';
import { useStrategyWizardContext } from 'containers/StrategyWizard/contexts/StrategyWizardProvider';
import { rowStyles, startegyTypeContainer, startegyTypeHeader, dspIconStyle, startegyDividerStyle } from 'containers/StrategyWizard/steps/StrategyTypeSelection/styles';
import { WizardFormValues } from 'containers/StrategyWizard/types';
import { COPILOT_LAYOUT } from 'globalStyles';
import { createLinkWithQS } from 'utils/functionHelpers';
import { usePrevious } from 'utils/hooks/usePrevious';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { StrategyType as StrategyTypeProp } from 'utils/types';
import Legend from './components/Legend';
import StrategyTypeRadio from './components/StrategyTypeRadio';

const { SPACING } = COPILOT_LAYOUT;

export type StrategyTypeStatus = {
  unsupportedAdTypes: Array<string>,
  unsupportedFlightTypes: Array<number>,
  noFlightSupported: boolean,
  multipleFlightTypesSupported: boolean,
};

type Props = {
  selectedStrategyType: StrategyTypeProp
  loading: boolean
  disabled: boolean
  dspId: number
  setSelectedStrategyType: (x: StrategyTypeProp) => void
  qsParams: QSParams
  defaultValue?: StrategyTypeProp
  strategyTypesToDisable?: Array<StrategyTypeStatus>
  items?: Array<StrategyTypeProp>
  error?: string
};

const StrategyType = ({
  selectedStrategyType,
  disabled,
  items,
  error,
  loading,
  dspId,
  strategyTypesToDisable,
  defaultValue,
  setSelectedStrategyType,
  qsParams,
}: Props) => {
  const { wizardFormValues, dispatch } = useStrategyWizardContext();
  const {
    strategyTypeSelectionStep: { strategyType: contextStrategyType },
    strategyConfigurationStep: contextConfigurationValues,
  } = wizardFormValues;
  const navigate = useNavigate();
  const prevFormValues = usePrevious(wizardFormValues);
  const formMethods = useForm({ defaultValues: { strategyType: defaultValue } });
  const { reset, control } = formMethods;
  const prefixLink = '/strategies/wizard';

  useMount(() => {
    reset({ strategyType: defaultValue });
  });

  const onNextClick = () => {
    const resetConfigValues = _.get(contextStrategyType, 'id') !== _.get(selectedStrategyType, 'id');
    dispatch({ type: SELECT_STRATEGY_TYPE, payload: { strategyType: selectedStrategyType } });
    const values = {
      ...wizardFormValues,
      strategyTypeSelectionStep: { strategyType: selectedStrategyType },
      strategyConfigurationStep: resetConfigValues ? {} : contextConfigurationValues,
    } as WizardFormValues;
    const pathname = `${WIZARD_BASE}${WIZARD_STEPS[WizardSteps.strategyConfigurationStep].id}`;
    updateQueryString(values, qsParams, navigate, prevFormValues, pathname);
  };

  const onBackClick = () => {
    const strategyTypeID = WIZARD_STEPS[WizardSteps.goalSelectionStep].id;
    navigate(createLinkWithQS(`${prefixLink}/${strategyTypeID}`));
  };

  return (
    <Form
      loading={loading}
      error={!!error}
    >
      <div style={{ ...wppBodyContainer, ...startegyTypeContainer }}>
        <WppGrid container fullWidth item all={24}>
          <WppGrid container item all={24} style={rowStyles}>
            {!!error && (
              <Message
                style={{ margin: '0 auto' }}
                error
                header="Failed to load strategy types"
                content={error}
              />
            )}
          </WppGrid>
          <WppGrid fullWidth item all={24}>
            <WppTypography style={startegyTypeHeader} type="xl-heading">Strategy Type</WppTypography>
            <Controller
              name="strategyType"
              control={control}
              defaultValue={selectedStrategyType}
              render={() => (
                <StrategyTypeRadio
                  items={items}
                  onChange={setSelectedStrategyType}
                  value={selectedStrategyType}
                  strategyTypesToDisable={strategyTypesToDisable}
                />
              )}
            />
          </WppGrid>
          <WppGrid item all={24} fullWidth style={{ padding: 0, marginTop: SPACING[24] }}>
            <WppDivider style={startegyDividerStyle} />
            <div style={dspIconStyle}>
              <div>
                <Legend dspId={dspId} />
              </div>
            </div>
          </WppGrid>
        </WppGrid>
      </div>
      <NavFooter
        onBackClick={onBackClick}
        onNextClick={onNextClick}
        nextButtonDisabled={disabled}
        showBackButton
      />
    </Form>
  );
};

export default StrategyType;
