import React from 'react';
import { RevenueType, STRATEGY_TYPE } from 'constantsBase';
import { RevenueTypeConfig, FormulaType } from 'containers/StrategyWizard/types';
import { StrategyGoals, SegmentGroupsType, StrategyType } from 'utils/types';
import ConnectedHeliosSegmentRecencyConfirmDetails from './HeliosSegmentRecencyConfirmDetails';
import Goal from './Goal';
import BudgetOptimization from './BudgetOptimization';
import { isBudgetOptimization } from '../utils';

type StrategySpecificConfirmProps = {
  strategyType: StrategyType
  strategyConfig: {
    intelligentChildObjects?: boolean
    hasCustomRevenueType?: boolean
  }
  currencyCode: string
};

export type ConfirmDetailsStrategyConfig = {
  strategyGoals: StrategyGoals
  segmentGroups?: SegmentGroupsType
  intelligentChildObjects?: boolean
  hasCustomRevenueType?: boolean
};

type ConfirmDetailsProps = {
  strategyId: number
  strategyType: StrategyType
  strategyConfig: ConfirmDetailsStrategyConfig
  currencyCode: string
  goal: {
    type: string
    target?: number
  }
  viewability: {
    enabled: boolean
    target?: number
  }
  budget?: {
    clientEventRevenueValue?: number,
    revenueType?: RevenueType,
  }
  customGoal?: Partial<FormulaType>
};

type GenericConfirmDetailsProps = {
  strategyType: StrategyType
  strategyConfig: {
    intelligentChildObjects?: boolean
    hasCustomRevenueType?: boolean
    lifetimeEventBudget?: number
  }
  goal: {}
  currencyCode: string
  lifetimeEventBudget?: number
  viewability: {
    enabled: boolean
    target?: number
  }
  budget?: RevenueTypeConfig
  customGoal?: Partial<FormulaType>
};

export const StrategySpecificConfirm = ({
  strategyType, strategyConfig, currencyCode,
}: StrategySpecificConfirmProps) => {
  const config = { ...strategyConfig, currencyCode };
  const crossPlatformStrategy = strategyType.id === STRATEGY_TYPE.crossPlatformOptimization.id;

  if ((isBudgetOptimization(strategyType.id) || crossPlatformStrategy) && config.intelligentChildObjects) {
    return (
      <BudgetOptimization dsp={strategyType.dsp} intelligentChildObjects={config.intelligentChildObjects} />
    );
  }
  return null;
};

export const GenericConfirmDetails = ({
  strategyType,
  strategyConfig = {},
  goal,
  currencyCode,
  viewability,
  budget,
}: GenericConfirmDetailsProps) => {
  const { lifetimeEventBudget, hasCustomRevenueType } = strategyConfig;
  return (
    <>
      <Goal
        hasCustomRevenueType={hasCustomRevenueType}
        lifetimeBudget={lifetimeEventBudget}
        viewability={viewability}
        {...goal}
        budget={budget}
      />
      <StrategySpecificConfirm
        strategyType={strategyType}
        strategyConfig={strategyConfig}
        currencyCode={currencyCode}
      />
    </>
  );
};

/* eslint-disable react/prop-types */
const DisplayConfirmDetailsComponent = (props) => {
  const {
    currencyCode,
    strategyType,
    goal,
    customGoal,
  } = props;
  const formattedGoal = {
    type: goal.type,
    target: goal.target,
    currencyCode,
    customGoal,
  };
  return strategyType.id === STRATEGY_TYPE.heliosSegmentRecency.id
    // @ts-ignore modules
    ? <ConnectedHeliosSegmentRecencyConfirmDetails {...props} goal={formattedGoal} />
    : <GenericConfirmDetails {...props} goal={formattedGoal} />;
};

const ConfirmDetails = (props: ConfirmDetailsProps) => (
  <div>
    <DisplayConfirmDetailsComponent {...props} />
  </div>
);

export default ConfirmDetails;
