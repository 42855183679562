import _ from 'lodash';
import { EXCLUDE_STRATEGY_TYPES, STRATEGY_TYPE } from 'constantsBase';
import { Member, StrategyType, User } from 'utils/types';
import { OptimizationLevelType } from 'containers/StrategyWizard/utils';
import { featureEnabled, getPermissionFromStrategyType } from 'utils/featureFlags';
import { STRATEGY_TYPE_TO_OPTIMIZATION_LEVEL } from './constants';

export const replaceShouldWithCan = (str) => str.replace('should', 'can');

const filterStratType = (
  user: User,
  selectedMember: Member,
  st: StrategyType,
  oL: OptimizationLevelType,
  isCrossPlatformOptimization: boolean,
) => (
  (
    st.dsp === _.get(selectedMember, 'dsp')
    && !_.includes(EXCLUDE_STRATEGY_TYPES, st.id)
    && featureEnabled(user, getPermissionFromStrategyType(st), selectedMember)
    && !isCrossPlatformOptimization
    && STRATEGY_TYPE_TO_OPTIMIZATION_LEVEL[st.id]?.name === oL.name
  )
  || (isCrossPlatformOptimization && st.id === STRATEGY_TYPE.crossPlatformOptimization.id)
);

export const getStrategyTypeRadioItems = (
  strategyTypes: Array<StrategyType>,
  member: Member,
  user: User,
  oL: OptimizationLevelType,
  isCrossPlatformOptimization: boolean,
) => _.chain(strategyTypes)
  .filter((st) => filterStratType(user, member, st, oL, isCrossPlatformOptimization))
  .sort((st1, st2) => st1.displayPosition - st2.displayPosition)
  .value();
