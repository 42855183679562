import React, { Dispatch, SetStateAction } from 'react';
import { STRATEGY_TYPE } from 'constantsBase';
import SegmentRecency from 'containers/StrategyWizard/ConfigurationByStrategyType/SegmentRecency';
import CustomTree from 'containers/StrategyWizard/ConfigurationByStrategyType/CustomTree/CustomTree';
import Helios from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios';
import CustomBidList from 'containers/StrategyWizard/ConfigurationByStrategyType/CustomBidList/CustomBidList';
import { StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import DBMBudgetOptimization from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/DBMBudgetOptimization';
import APNBudgetOptimization from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/ApnBudgetOptimization';
import TTDBudgetOptimization from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/TTDBudgetOptimization';
import WMTBudgetOptimization from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/WMTBudgetOptimization';
import AMZNBudgetOptimization from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/AMZNBudgetOptimization';
import DBMCustomSDF from 'containers/StrategyWizard/ConfigurationByStrategyType/CustomSDF';
import CrossPlatform from 'containers/StrategyWizard/ConfigurationByStrategyType/CrossPlatform';

const MODULE_BY_STRATEGY_TYPE = {
  [STRATEGY_TYPE.heliosSegmentRecency.id]: SegmentRecency,
  [STRATEGY_TYPE.customTree.id]: CustomTree,
  [STRATEGY_TYPE.helios.id]: Helios,
  [STRATEGY_TYPE.customBidList.id]: CustomBidList,
  [STRATEGY_TYPE.apnBudgetOptimization.id]: APNBudgetOptimization,
  [STRATEGY_TYPE.ttdBudgetOptimization.id]: TTDBudgetOptimization,
  [STRATEGY_TYPE.wmtBudgetOptimization.id]: WMTBudgetOptimization,
  [STRATEGY_TYPE.dbmBudgetOptimization.id]: DBMBudgetOptimization,
  [STRATEGY_TYPE.amznBudgetOptimization.id]: AMZNBudgetOptimization,
  [STRATEGY_TYPE.dbmCustomSDF.id]: DBMCustomSDF,
  [STRATEGY_TYPE.crossPlatformOptimization.id]: CrossPlatform,
};

export type ModuleProps = {
  initialValues: any
  strategyId: number
  formValues?: StrategyConfigurationStep
  strategyTypeId?: number
  setShouldRedirect?: Dispatch<SetStateAction<boolean>>
  setKoalaBoxValues?: (x: any) => void
  goToConfirmStep?: (x: number) => void
};

const ModuleLoader = ({ strategyTypeId, ...rest }: ModuleProps) => {
  const Component = MODULE_BY_STRATEGY_TYPE[strategyTypeId];
  return <Component {...rest} />;
};

export default ModuleLoader;
