import _ from 'lodash';
import React from 'react';
import { WppGrid, WppTypography, WppDivider, WppActionButton } from 'buildingBlocks';
import { STRATEGY_TYPE } from 'constantsBase';
import { StrategyType } from 'utils/types';
import { STRATEGY_CONFIGURATION_STYLES } from '../styles';

export const ModuleHeader = ({ displayName, id }: StrategyType) => {
  const stratType = STRATEGY_TYPE.getById(id as number);
  return (
    <WppGrid container fullWidth item all={12} style={STRATEGY_CONFIGURATION_STYLES.configureContainer}>
      <WppTypography className={`strategy-type-${id}`} style={STRATEGY_CONFIGURATION_STYLES.header} type="xl-heading">{displayName}</WppTypography>
      <WppTypography tag="p" type="s-body" style={STRATEGY_CONFIGURATION_STYLES.subHeader}>
        {_.get(stratType, 'description')}
        <WppTypography tag="span" type="s-body">
          For more information on configurable options please see the Copilot
          <a href={_.get(stratType, 'helpCenterLink')} target="_blank" rel="noopener noreferrer"><WppActionButton style={STRATEGY_CONFIGURATION_STYLES.moduleHeaderHelpStyle}>Help Center.</WppActionButton></a>
        </WppTypography>
      </WppTypography>
      <WppDivider style={STRATEGY_CONFIGURATION_STYLES.moduleHeaderDividerpStyle} />
    </WppGrid>
  );
};

export default ModuleHeader;
