import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Form } from 'buildingBlocks';
import { DSP } from 'constantsBase';
import NavFooter from 'containers/StrategyWizard/components/NavFooter';
import { supportsPixelSelect } from 'containers/StrategyWizard/ConfigurationByStrategyType/utils';
import { Status } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { SELECT_GOALS, WizardSteps, WIZARD_STEPS } from 'containers/StrategyWizard/constants';
import { isNumeric, createLinkWithQS } from 'utils/functionHelpers';
import { QSParams } from 'containers/StrategyWizard/hooks';
import { updateQueryString } from 'containers/StrategyWizard/StrategyWizard';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { canManageBrands, canManageFlights, isHigherOrderExternalType } from 'containers/StrategyWizard/utils';
import { usePrevious } from 'utils/hooks/usePrevious';
import { wppBodyContainer } from 'components/PageTemplate/style';
import GoalSection from './GoalSection';
import BudgetSection from './BudgetSection';
import { invalidCrossPlatformBudgetSettings, CrossPlatformBudgetSettingsValidationType, isAWGGoalType, getBudgetKeyWithPrefix } from './utils';
import { GOAL_SECTION_STYLES } from './styles';
import { AWG_FIELDS_TO_EXCLUDE } from './constants';
import { useGoalSectionContext } from './contexts/GoalSectionProvider';

type Props = {
  qsParams: QSParams
  strategyId?: number
};

const GoalSelection = ({ strategyId, qsParams }: Props) => {
  const {
    setRevenueTypeEnabled, revTypeSearchStr, user, dsp,
    setRevTypeSearchStr, isCrossPlatformOptimization,
    reduxWizardFormValues, disableRevType, hasRevTypePermission,
  } = useGoalSectionContext();
  const [crossPlatformBudgetSettingsValidation, setCrossPlatformBudgetSettingsValidation] = useState<CrossPlatformBudgetSettingsValidationType>({ rangeOverlap: false, missingBudgetValue: false, missingBudgetSettings: false, budgetLessThanDelivery: false, budgetEqualToZero: false });
  const {
    attachFlightsStep: { optimizationLevel, member, brand, attachedFlights, selectedOptType },
    goalSelectionStep,
    budgetAllocationState,
  } = reduxWizardFormValues;
  const navigate = useNavigate();
  const awgRef = useRef<boolean | null>(null);

  const { goal, budget, revenueOutcomeType } = goalSelectionStep;
  const budgetSettings = _.get(goalSelectionStep, 'budgetSettings', []);
  const customGoal = _.get(goalSelectionStep, 'customGoal');
  const metricsConfig = _.get(goalSelectionStep, 'metricsConfig');
  const prefixLink = `/strategies/wizard${isNumeric(strategyId) ? `/${strategyId}` : ''}`;

  const initialBudgetSettings = _.map(budgetSettings, (interval) => ({
    ...interval,
    // only add newlyAdded key as false when intializing the wizard
    // if the user toggles back to step 2, newlyAdded flag will exist for all intervals
    ...(_.isNil(interval.newlyAdded) && { newlyAdded: false }),
    originalValue: interval.budget,
  }));

  const getSavedBudget = () => {
    // check if extIds in budgetConfig match with externalIds of attachedFlights
    // handles syncing budget on goalSelectionStep when user goes back to step 1 to attach new flight
    const flightExtIds = _.map(attachedFlights, ({ externalId }) => getBudgetKeyWithPrefix(externalId));
    const updatedConfig = _.reduce(budget, (acc, config, key) => {
      if (_.includes(flightExtIds, key)) {
        acc[key] = config;
      }
      return acc;
    }, {});
    return updatedConfig;
  };

  const initialFormValues = {
    goal: {
      // user goes past step 3 in create flow but attaches a flight that has access_revenue_type permission blacklisted
      type: (!hasRevTypePermission && budget) ? null : _.get(goal, 'type'),
      target: _.get(goal, 'target'),
      impValueFilters: _.get(goal, 'impValueFilters'),
    },
    budget: (!disableRevType && budget) ? getSavedBudget() : null,
    revenueOutcomeType: (!disableRevType && budget) ? revenueOutcomeType : null,
    customGoal,
    metricsConfig,
    ...(isCrossPlatformOptimization && { budgetSettings: initialBudgetSettings }),
  };

  const [confirmedGoal, setConfirmedGoal] = useState<string>(initialFormValues.goal.type);

  const formMethods = useForm<WizardFormGoalSelection>({
    defaultValues: initialFormValues,
    mode: 'onSubmit',
  });

  const { formState, getValues, reset, control, trigger, handleSubmit } = formMethods;
  const goalTarget = _.toNumber(useWatch({ name: 'goal.target', control }));
  const goalType = useWatch({ name: 'goal.type', control });
  const pixels = useWatch({ name: 'goal.impValueFilters', control });
  const dispatch = useDispatch();

  useEffect(() => {
    reset(initialFormValues);
    if (initialFormValues.budget) {
      // in edit flow, if user attaches an amzn flight or flight with imps budget type, disable revenue type option and nuke budget on form
      setRevenueTypeEnabled(!disableRevType);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormValues.goal.type]);

  const resetConfirmedGoal = () => setConfirmedGoal(initialFormValues.goal.type);
  const prevFormValues = usePrevious(reduxWizardFormValues);
  const showBackButton = (member && canManageFlights(user, member)) || (brand && canManageBrands(user));

  const isHigherLevelOptimizationLevel = isHigherOrderExternalType(_.head(_.get(optimizationLevel, 'externalTypeIds')));

  const showPixelPicker = supportsPixelSelect(goalType, selectedOptType, isCrossPlatformOptimization ? DSP.MULTIPLE.id : dsp);

  // invalid if size of pixels is not equal to num of uniq dsps attached or if each key in pixels does not have a value
  const numOfUniqDsps = _.size(_.uniqBy(attachedFlights, 'dsp'));
  const invalidPixelPicker = showPixelPicker && (!_.isEqual(numOfUniqDsps, _.size(pixels)) || (_.isEmpty(pixels) || !_.every(_.keys(pixels), (dspId: string) => (pixels[dspId] && (_.size(pixels[dspId]) >= 1)))));

  const disabled = !_.isEmpty(formState.errors) || !goalTarget || !goalType || invalidPixelPicker
    || invalidCrossPlatformBudgetSettings(crossPlatformBudgetSettingsValidation) || (!customGoal && isAWGGoalType(goalType));

  const onSubmit = () => {
    if (!disabled) {
      dispatch({
        type: SELECT_GOALS,
        payload: _.omit(getValues(), AWG_FIELDS_TO_EXCLUDE),
      });
      const values = {
        ...reduxWizardFormValues,
        goalSelectionStep: getValues(),
      };
      const pathname = `/strategies/wizard/${strategyId ? `${strategyId}/${WIZARD_STEPS[WizardSteps.strategyConfigurationStep].id}` : WIZARD_STEPS[WizardSteps.strategyTypeSelectionStep].id}`;
      updateQueryString(values, qsParams, navigate, prevFormValues, pathname);
    }
  };

  const handleFormSubmit = async () => {
    if (revTypeSearchStr) {
      setRevTypeSearchStr('');
    }
    // Trigger validation for all fields
    const isValid = await trigger();
    if (isValid) {
      // If all fields are valid, submit the form
      handleSubmit(onSubmit)();
    }
  };

  const onBackClick = () => {
    const getBackIDOne = WIZARD_STEPS[WizardSteps.attachFlightsStep].subSteps.objects.id;
    setConfirmedGoal(null);
    navigate(createLinkWithQS(`${prefixLink}/${getBackIDOne}`));
  };

  return (
    <FormProvider {...formMethods}>
      <Form>
        <div style={GOAL_SECTION_STYLES.goalBudgSectionStyle}>
          {(isHigherLevelOptimizationLevel && (budgetAllocationState.kind === Status.hasData || isCrossPlatformOptimization)) && (
            <div style={wppBodyContainer}>
              <BudgetSection
                crossPlatformBudgetSettingsValidation={crossPlatformBudgetSettingsValidation}
                setCrossPlatformBudgetSettingsValidation={setCrossPlatformBudgetSettingsValidation}
                initialFormValues={initialFormValues}
                resetConfirmedGoal={resetConfirmedGoal}
              />
            </div>
          )}
          <div style={wppBodyContainer}>
            <GoalSection
              initialValues={initialFormValues}
              isHigherLevelOptimizationLevel={isHigherLevelOptimizationLevel}
              awgRef={awgRef}
              confirmedGoal={confirmedGoal}
              setConfirmedGoal={setConfirmedGoal}
            />
          </div>
        </div>
        <NavFooter
          strategyId={strategyId}
          onBackClick={onBackClick}
          showBackButton={showBackButton}
          onNextClick={handleFormSubmit}
          nextButtonDisabled={disabled}
        />
      </Form>
    </FormProvider>
  );
};

export default GoalSelection;
