import React from 'react';
import { WppTag } from 'buildingBlocks';
import { GoalSuccessEvent } from 'constantsBase';
import './style.scss';

type Props = {
  tag: GoalSuccessEvent
};

const GoalTag: React.FC<Props> = ({ tag }: Props): React.ReactElement => (
  <WppTag
    label={tag}
    className={Object.keys(GoalSuccessEvent)[Object.values(GoalSuccessEvent).indexOf(tag)]}
  />
);

export default GoalTag;
