import _ from 'lodash';
import React from 'react';
import { WppButton, WppTypography } from 'buildingBlocks';
import { AWG_GOAL_PAGE_STYLES, AWG_WEIGHTS_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import WeightedMetrics from './WeightedMetrics';
import WeightsModal from './WeightsModal';
import { useWeightsSectionContext } from '../contexts/WeightsSectionProvider';
import { useAWGContext } from '../contexts/AWGProvider';

const WeightsSection = () => {
  const { formulaMetrics, selectedFormula } = useAWGContext();
  const { handleOpenModal } = useWeightsSectionContext();
  const weightedMetrics = _.pickBy(formulaMetrics, (metric) => _.size(metric.weighting));

  return (
    <div style={AWG_WEIGHTS_MODAL_STYLES.formulaButtons}>
      <WppTypography type="m-strong" className="AWGWeightsHeader">Weights (Optional)</WppTypography>
      <div style={{ float: 'left' }}>
        {
          !_.size(weightedMetrics)
            ? (<WppTypography type="s-body" style={AWG_GOAL_PAGE_STYLES.assignWeightObjStyle}>Assign weights to objects in the buying platform for each metric.</WppTypography>
            )
            : (<WeightedMetrics weightedMetrics={weightedMetrics} />)
        }
        <WeightsModal
          metrics={_.sortBy(selectedFormula?.metrics)}
        />
      </div>
      <WppButton
        variant="secondary"
        style={AWG_GOAL_PAGE_STYLES.addWeightsButtonStyle}
        size="s"
        onClick={handleOpenModal}
        disabled={_.isEmpty(formulaMetrics)}
      >
        Assign Weights
      </WppButton>
    </div>
  );
};

export default WeightsSection;
