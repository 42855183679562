import { GoalSectionAction, GoalSectionState, GoalSectionActionTypes } from './types';

export const goalSectionReducer = (state: GoalSectionState, action: GoalSectionAction) => {
  switch (action.type) {
    case GoalSectionActionTypes.UPDATE_REVENUE_TYPE_ENABLED:
      return { ...state, revenueTypeEnabled: action.payload };
    case GoalSectionActionTypes.UPDATE_REV_TYPE_SEARCH_STR:
      return { ...state, revTypeSearchStr: action.payload };
    case GoalSectionActionTypes.UPDATE_SUCCESS_EVENT_FILTER:
      return { ...state, successEventFilter: action.payload };
    default:
      return state;
  }
};
